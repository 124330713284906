export const formatDateRange = (startDate, endDate) => {
  const date_start = startDate.toISOString().split("T")[0];
  const date_end = endDate.toISOString().split("T")[0];
  return { date_start, date_end };
};

export const getMinMaxValues = (data, key, maxLimit = Infinity) => {
  const values = data.map((d) => d[key]);
  const min = Math.min(...values);
  const max = Math.min(Math.max(...values), maxLimit);
  return { min, max };
};

export const getTicks = (min, max, step) => {
  return Array.from(
    { length: Math.ceil((max - min) / step) + 1 },
    (_, i) => min + i * step,
  );
};

export const getYAxisTicks = (max) => {
  const step = 5000;
  const ticks = [];
  for (let i = 0; i <= max; i += step) {
    ticks.push(i);
  }
  return ticks;
};

export const yAxisTickFormatter = (value) =>
  `$${value >= 1000 ? value / 1000 + "k" : value}`;

export const transformAndSortData = (data) => {
  const result = [];
  const colorCounters = {};

  let currentPart = null;

  data.forEach(({ x: category, y: value, color }, index) => {
    const roundedValue = Math.round(value);

    if (!currentPart || currentPart.color !== color) {
      if (currentPart) {
        result.push(currentPart);

        currentPart = {
          name: `${color.charAt(0).toUpperCase() + color.slice(1)} Part${(colorCounters[color] = (colorCounters[color] || 0) + 1)}`,
          color,
          data: [
            {
              category: currentPart.data.at(-1).category,
              value: currentPart.data.at(-1).value,
            },
          ],
        };
      } else {
        currentPart = {
          name: `${color.charAt(0).toUpperCase() + color.slice(1)} Part${(colorCounters[color] = (colorCounters[color] || 0) + 1)}`,
          color,
          data: [],
        };
      }
    }

    currentPart.data.push({ category, value: roundedValue });
    if (index === data.length - 1) result.push(currentPart);
  });

  return result;
};
