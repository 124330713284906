import { useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { slideDown, slideUp } from "./utils";
import { Template } from "../../types";

interface TemplateProps {
  template: Template;
  onSelect: (prompt: string) => void;
  userGuide?: boolean;
  setActiveItem?: (item: number | null) => void;
  index: number;
  activeItem: number | null;
}

const TemplateItem = ({
  template,
  onSelect,
  userGuide,
  setActiveItem,
  index,
  activeItem,
}: TemplateProps) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const animationStartRef = useRef(false);

  const handleClick = () => {
    if (animationStartRef.current === true) {
      return;
    }

    const target = contentRef.current;

    if (target === null) {
      return;
    }

    setActive((p) => !p);
    setActiveItem && setActiveItem(activeItem === index ? null : index);

    const DURATION = 500;

    animationStartRef.current = true;

    active ? slideUp(target, DURATION) : slideDown(target, DURATION);

    setTimeout(() => {
      animationStartRef.current = false;
    }, DURATION);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (userGuide) return;
    if (e.target.checked) {
      onSelect(e.target.value);
    }
  };

  return (
    <div
      className={classNames(
        styles.accordion__item,
        active && styles["accordion__item--active"],
      )}
    >
      <div className={styles.accordion__header}>
        <span className={styles.checkbox__label}>
          {template.name || template.label}
        </span>
        <div className={styles.accordion__button} onClick={handleClick}></div>
      </div>
      <div className={styles.accordion__content} ref={contentRef} hidden>
        {userGuide ? (
          <div className={styles.accCol}>
            {template.list.map((item, i) => (
              <li className={styles.list__item} key={i}>
                <label className={styles.checkbox}>
                  <input
                    type="checkbox"
                    name="template"
                    value={item.label}
                    className={styles.checkbox__input}
                    onChange={handleCheckboxChange}
                  />
                  <span className={styles.checkbox__label}>{item.label}</span>
                </label>
              </li>
            ))}
          </div>
        ) : (
          <ul className={styles.list}>
            {template.list.map((label, i) => (
              <li className={styles.list__item} key={i}>
                <label className={styles.checkbox}>
                  <input
                    type="checkbox"
                    name="template"
                    value={label}
                    className={styles.checkbox__input}
                    onChange={handleCheckboxChange}
                  />
                  <span className={styles.checkbox__label}>{label}</span>
                </label>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TemplateItem;
