import { Select } from "@components/Select";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
} from "recharts";
import {
  CanvasApi,
  ISaturationCurveData,
} from "../../services/canvas/canvas.api";
import {
  formatDateRange,
  getMinMaxValues,
  getTicks,
  getYAxisTicks,
  yAxisTickFormatter,
  transformAndSortData,
} from "./utils";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";

export const SaturationCurve = () => {
  const [choosedCurve, setChoosedCurve] = useState("Ad Sales");
  const [curveData, setCurveData] = useState<ISaturationCurveData[]>([]);
  const { dateRange } = useDashboardStore();
  const { startDate, endDate } = dateRange;

  useEffect(() => {
    const fetchSaturationCurve = async () => {
      try {
        const { date_start, date_end } = formatDateRange(startDate, endDate);
        const data = await new CanvasApi().getSaturationCurve(
          date_start,
          date_end,
        );
        const transformedData = transformAndSortData(data);
        setCurveData(transformedData);
      } catch (error) {
        console.error("Error fetching saturation curve data:", error);
      }
    };

    fetchSaturationCurve();
  }, [startDate, endDate]);

  const { min: xMin, max: xMax } = getMinMaxValues(
    curveData.flatMap((d) => d.data),
    "category",
  );
  const { min: yMin, max: yMax } = getMinMaxValues(
    curveData.flatMap((d) => d.data),
    "value",
  );

  const xTicks = getTicks(xMin, xMax, 30);
  const yTicks = getYAxisTicks(yMax);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Saturation Curve</h3>
        <div className={styles.select}>
          <Select
            options={[
              "Ad Sales",
              "Ad Sales Contribution Profit",
              "Organic Sales",
              "Organic Sales Contribution Profit",
              "Total Sales",
              "Total Sales Contribution Profit",
            ]}
            value={choosedCurve}
            onChange={(value) => setChoosedCurve(value)}
            placeholder="Select a curve"
            className={styles.select__dropdown}
          />
        </div>
      </div>
      <div className={styles.chart}>
        <LineChart
          width={1160}
          height={428}
          data={curveData}
          margin={{ top: 0, right: 0, bottom: 40, left: 0 }}
        >
          <CartesianGrid stroke="#1F1F20" />
          <XAxis
            dataKey="category"
            type="number"
            domain={[xMin, xMax]}
            ticks={xTicks}
          >
            <Label
              value="Spend"
              offset={-10}
              position="insideBottom"
              style={{ textAnchor: "middle", fill: "#fff", fontSize: 14 }}
            />
          </XAxis>
          <YAxis
            dataKey="value"
            domain={[yMin, yMax]}
            ticks={yTicks}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip />
          {curveData.map((s) => (
            <Line
              key={s.name}
              dataKey="value"
              data={s.data}
              name={s.name}
              stroke={s.color}
              type="monotone"
              dot={false}
              connectNulls
              strokeWidth={3}
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};
