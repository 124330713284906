export default () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1656 8.00007C16.1656 8.4419 15.8075 8.80007 15.3656 8.80007L3.36565 8.80007C2.92382 8.80008 2.56565 8.4419 2.56565 8.00008C2.56565 7.55825 2.92382 7.20007 3.36565 7.20007L15.3656 7.20007C15.8075 7.20007 16.1656 7.55825 16.1656 8.00007Z"
        fill="#5295E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.36557 2.79994C5.67799 2.48752 6.18452 2.48752 6.49694 2.79994C6.80936 3.11236 6.80936 3.61889 6.49694 3.93131L2.99399 7.43425C2.68157 7.74667 2.68157 8.2532 2.99399 8.56562L6.49694 12.0686C6.80936 12.381 6.80936 12.8875 6.49694 13.1999C6.18452 13.5124 5.67799 13.5124 5.36557 13.1999L0.731252 8.56562C0.418832 8.25321 0.418832 7.74667 0.731251 7.43425L5.36557 2.79994Z"
        fill="#5295E0"
      />
    </svg>
  );
};
