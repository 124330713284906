export const mockData = [
  {
    id: 1,
    po: {
      number: "738291",
      amount: "$XXX",
      dlDate: "01.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "928391",
      amount: "$XXX",
      dlDate: "01.01.2024",
      tFee: "$XXX",
    },
  },
  {
    id: 2,
    po: {
      number: "728492",
      amount: "$XXX",
      dlDate: "02.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "190273",
      amount: "$XXX",
      dlDate: "05.01.2024",
      tFee: "$XXX",
    },
  },
  {
    id: 3,
    po: {
      number: "367190",
      amount: "$XXX",
      dlDate: "03.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "888192",
      amount: "$XXX",
      dlDate: "06.01.2024",
      tFee: "$XXX",
    },
  },
  {
    id: 4,
    po: {
      number: "738450",
      amount: "$XXX",
      dlDate: "04.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "716252",
      amount: "$XXX",
      dlDate: "07.01.2024",
      tFee: "$XXX",
    },
  },
  {
    id: 5,
    po: {
      number: "236139",
      amount: "$XXX",
      dlDate: "05.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "116287",
      amount: "$XXX",
      dlDate: "08.01.2024",
      tFee: "$XXX",
    },
  },
  {
    id: 6,
    po: {
      number: "381724",
      amount: "$XXX",
      dlDate: "06.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "772634",
      amount: "$XXX",
      dlDate: "08.01.2024",
      tFee: "$XXX",
    },
  },
  {
    id: 7,
    po: {
      number: "993716",
      amount: "$XXX",
      dlDate: "07.01.2024",
      cogs: "$XXX",
    },
    so: {
      number: "819283",
      amount: "$XXX",
      dlDate: "10.01.2024",
      tFee: "$XXX",
    },
  },
];
