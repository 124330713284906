import { TaskType, AppNode } from "src/types/workflow.types";

export function CreateFlowNode(
  nodetype: TaskType,
  position?: { x: number; y: number },
): AppNode {
  return {
    id: crypto.randomUUID(),
    type: "FlowScrapeNode",
    dragHandle: ".drag-handle",
    data: {
      type: nodetype,
      inputs: {},
    },
    position: position || { x: 0, y: 0 },
  };
}
