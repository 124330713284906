import React, { useState } from "react";
import { Modal, Form, Select, Button, DatePicker, Space } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import "./ModalStyles.css";
import CalendarModal from "@assets/icons/calendarModal";
import Dolar from "@assets/icons/dolar";
import ModalClose from "@assets/icons/modalClose";
import { Input } from "@components/Input";
import { useForm } from "antd/lib/form/Form";
import UploadModal from "@components/UploadModal";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import { ADDITIONAL_METRICS_OPTIONS, AMAZON_FEES_OPTIONS } from "./utils";

const { Option } = Select;

interface AddDataEntryModalProps {
  visible: boolean;
  onCancel: () => void;
  onSwitch?: () => void;
}

const AddDataEntryModal: React.FC<AddDataEntryModalProps> = ({
  onCancel,
  onSwitch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [form] = useForm();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleAddEntry = async () => {
    try {
      const values = await form.validateFields();

      const { dateStart, dateEnd, amazon_fees, other, price, ...rest } = values;
      const date_start = dateStart ? dateStart.format("YYYY-MM-DD") : null;
      const date_end = dateEnd ? dateEnd.format("YYYY-MM-DD") : null;

      let amazonFeesObj: Record<string, { value: number }> | undefined;
      if (Array.isArray(amazon_fees) && amazon_fees.length > 0) {
        const tempObj: Record<string, { value: number }> = {};
        amazon_fees.forEach((feeItem: any) => {
          if (feeItem?.type) {
            tempObj[feeItem.type] = {
              value: feeItem.value ? Number(feeItem.value) : 0,
            };
          }
        });
        if (Object.keys(tempObj).length > 0) {
          amazonFeesObj = tempObj;
        }
      }

      let additionalMetricsObj: Record<string, { value: number }> | undefined;

      if (Array.isArray(other) && other.length > 0) {
        const tempObj: Record<string, { value: number }> = {};
        other.forEach((metricItem: any) => {
          if (metricItem?.type) {
            const val = metricItem?.value ? Number(metricItem.value) : 0;
            tempObj[metricItem.type] = { value: val };
          }
        });
        if (Object.keys(tempObj).length > 0) {
          additionalMetricsObj = tempObj;
        }
      }

      const finalPayload: any = {
        asin: rest.asin || "",
        date_start,
        date_end,
        cogs: Number(rest.cogs) || 0,
        price: price ? Number(price) : 0,
        units_sold_items: Number(rest.unitsSold) || 0,
        units_returned_items: Number(rest.units_returned_items) || 0,
      };

      if (amazonFeesObj) {
        finalPayload.amazon_fees = amazonFeesObj;
      }
      if (additionalMetricsObj) {
        finalPayload.Additionalmetrics = additionalMetricsObj;
      }

      const dataToPost = [finalPayload];
      const cogsService = new CategoriesService();
      await cogsService.sendCogsData(dataToPost);

      setIsModalOpen(false);
      onSwitch?.();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleValuesChange = () => {};

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
    setIsModalOpen(false);
  };
  const handleCancelUploadModal = () => {
    setIsUploadModalOpen(false);
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        footer={null}
        width={750}
        className="custom-modal"
        closeIcon={
          <span
            style={{
              width: "14px",
              height: "14px",
              fontSize: "20px",
              color: "#FFFFFF",
            }}
          >
            ×
          </span>
        }
      >
        <div className="modal-header">
          <h2 className="modal-title">Add Data Entry</h2>
          <Button
            icon={<UploadOutlined />}
            className="btn-upload"
            type="default"
            onClick={handleOpenUploadModal}
          >
            Upload CSV
          </Button>
        </div>

        <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
          <Form.Item label="ASIN" name="asin" className="asin">
            <Input placeholder="Enter ASIN" size="large" />
          </Form.Item>

          <div className="modal-content">
            <div className="left-section">
              <Form.Item
                label="DATE START"
                name="dateStart"
                className="custom-date-picker"
              >
                <DatePicker
                  suffixIcon={null}
                  style={{ width: "100%" }}
                  inputRender={(inputProps, inputRef) => (
                    <div className="date-picker-input-container">
                      <CalendarModal />
                      <input {...inputProps} ref={inputRef} />
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item
                label="COGS, $"
                name="cogs"
                className="custom-date-picker"
              >
                <Input
                  size="large"
                  className="input"
                  paddingSize="dense"
                  icon={<Dolar />}
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label="Price, $"
                name="price"
                className="custom-date-picker"
              >
                <Input
                  size="large"
                  className="input"
                  paddingSize="dense"
                  icon={<Dolar />}
                  type="number"
                />
              </Form.Item>

              <Form.Item label="AMAZON FEE" className="custom-date-picker">
                <Form.List
                  name="amazon_fees"
                  initialValue={[
                    { type: "Aged Inventory Surcharge", value: 0 },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          {/* Поле Select (type) */}
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            style={{ flex: 1, marginRight: 8 }}
                            rules={[
                              {
                                required: true,
                                message: "Please select a fee type!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select or enter Fee Type"
                              dropdownClassName="custom-dropdown"
                              style={{ height: "50px" }}
                            >
                              {AMAZON_FEES_OPTIONS.map((opt) => (
                                <Option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "value"]}
                            style={{ width: "298px", marginBottom: "10px" }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a value!",
                              },
                            ]}
                          >
                            <Input placeholder="$" size="large" type="number" />
                          </Form.Item>

                          {index > 0 && (
                            <Button
                              type="link"
                              onClick={() => remove(name)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ModalClose />
                            </Button>
                          )}
                        </Space>
                      ))}

                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        className="add-button"
                      >
                        ADD ANOTHER AMAZON FEE
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>

              <Form.Item
                label="ADDITIONAL METRICS"
                className="custom-date-picker-metrics"
                style={{ width: "297px" }}
              >
                <Form.List
                  name="other"
                  initialValue={[{ type: "Net Sales", value: 0 }]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            style={{ flex: 1, marginRight: 8 }}
                            rules={[
                              {
                                required: true,
                                message: "Please select a metric type!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select or enter Metric"
                              dropdownClassName="custom-dropdown"
                              style={{ height: "50px" }}
                            >
                              {ADDITIONAL_METRICS_OPTIONS.map((opt) => (
                                <Option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "value"]}
                            style={{
                              width: "298px",
                              marginBottom: "0 !important",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a value!",
                              },
                            ]}
                          >
                            <Input placeholder="$" size="large" type="number" />
                          </Form.Item>

                          {index > 0 && (
                            <Button
                              type="link"
                              onClick={() => remove(name)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ModalClose />
                            </Button>
                          )}
                        </Space>
                      ))}

                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        className="add-button"
                      >
                        ADD ANOTHER ADDITIONAL METRIC
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>

            <div className="right-section">
              <Form.Item
                label="DATE END"
                name="dateEnd"
                className="custom-date-picker"
              >
                <DatePicker
                  suffixIcon={null}
                  inputRender={(inputProps, inputRef) => (
                    <div className="date-picker-input-container">
                      <CalendarModal />
                      <input {...inputProps} ref={inputRef} />
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item
                label="UNITS SOLD, ITEMS"
                name="unitsSold"
                className="custom-date-picker"
              >
                <Input
                  placeholder="Enter Units Sold"
                  size="large"
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label="UNITS RETURNED, ITEMS"
                name="units_returned_items"
                className="custom-date-picker"
              >
                <Input
                  placeholder="Enter RETURNED"
                  size="large"
                  type="number"
                />
              </Form.Item>
            </div>
          </div>

          <Button
            type="primary"
            block
            style={{
              marginTop: 34,
              backgroundColor: "#5295E0",
              borderColor: "#5295E0",
            }}
            onClick={handleAddEntry}
          >
            ADD DATA ENTRY
          </Button>
        </Form>
      </Modal>

      <UploadModal
        isModalOpen={isUploadModalOpen}
        onCancel={handleCancelUploadModal}
      />
    </>
  );
};

export default AddDataEntryModal;
