import { WidgetMetricKey } from "./math.service";

export const WIDGET_METRIC_KEYS = [
  // ----- TOTAL -----
  "totalSales",
  "totalSpend",
  "totalImpressions",
  "totalClicks",
  "totalUnitsSold",
  "totalCtr",
  "totalCvr",
  "totalOrders",
  "totalNtbOrders",
  "tacos",
  "mer",

  // ----- AD -----
  "adSales",
  "adSpend",
  "adImpressions",
  "adClicks",
  "adUnitsSold",
  "adCtr",
  "adCvr",
  "adOrders",
  "adAcos",
  "adRoas",
  "adCpc",

  // ----- ORGANIC -----
  "organicSales",
  "organicSpend",
  "organicImpressions",
  "organicClicks",
  "organicUnitsSold",
  "organicCtr",
  "organicCvr",
  "organicOrders",

  // ----- CFO -----
  "availableCapital",
  "frozenCapital",
  "borrowedCapital",
  "lostSales",
  "grossMargin",
  "opex",
  "contribution",
  "profitEbitda",

  // ----- COO -----
  "fbaInStockRate",
  "fbtInStockRate",
  "3plInStockRate",
  "storageCosts",
  "transportCost",
  "safetyStock",
  "shippingCosts",
  "forecastingAccuracy",
  "contributionMargin",
  "inventoryTurnover",
  "lostSalesCfo",

  //--finance--
  "availableCapital",
  "borrowedCapital",
  "lostSales",
  "grossMargin",
  "opex",
  "contributionProfit",
  "profitEbitda",
  //----supply---
  "Transport Cost ",
  "Safety Stock",
  "Shipping Costs ",
  "Storage Costs",
  "Forecasting Accuracy",
  "Inventory Turnover",
  "Lost Sales",
  "Contribution Margin",

  //----market---
  "MarketTotalSales",
  "BrandMarketShare",
  "MarketAveragePrice",
  "MarketUnitsSold",
  "MarketASINcount",
  "MarketPromotionValue",
  "MarketPromotionCount",
  "MarketReviewScore",
  "MarketPOS",
  "MarketAdSpend",

  //----Sales ---
  "GrossRevenue",
  "Expenses",
  "NetProfit",
  "UnitsSold",

  //--salesCongig--
  "Total Sales",
  "Total Spend",
  "Total Contribution Profit",
  "Total Units Sold",
];

type WidgetConfig = Record<
  (typeof WIDGET_METRIC_KEYS)[number],
  { label: string; format: string }
>;

export const WIDGET_METRICS_CONFIG: WidgetConfig = {
  // ----- TOTAL -----
  totalSales: {
    label: "Total Sales",
    format: "currency",
  },
  totalSpend: {
    label: "Total Spend",
    format: "currency",
  },
  totalImpressions: {
    label: "Total Impressions",
    format: "number",
  },
  totalClicks: {
    label: "Total Clicks",
    format: "number",
  },
  totalUnitsSold: {
    label: "Total Units Sold",
    format: "number",
  },
  totalCtr: {
    label: "Total CTR",
    format: "percentage",
  },
  totalCvr: {
    label: "Total CVR",
    format: "percentage",
  },
  totalOrders: {
    label: "Total Orders",
    format: "number",
  },
  totalNtbOrders: {
    label: "Total NTB Orders",
    format: "number",
  },
  tacos: {
    label: "Total Tacos",
    format: "number",
  },
  mer: {
    label: "Total Mer",
    format: "number",
  },

  // ----- AD -----
  adSales: {
    label: "Ad Sales",
    format: "currency",
  },
  adSpend: {
    label: "Ad Spend",
    format: "currency",
  },
  adImpressions: {
    label: "Ad Impressions",
    format: "number",
  },
  adClicks: {
    label: "Ad Clicks",
    format: "number",
  },
  adUnitsSold: {
    label: "Ad Units Sold",
    format: "number",
  },
  adCtr: {
    label: "Ad CTR",
    format: "percentage",
  },
  adCvr: {
    label: "Ad CVR",
    format: "percentage",
  },
  adOrders: {
    label: "Ad Orders",
    format: "number",
  },
  adAcos: {
    label: "ACOS",
    format: "percentage",
  },
  adRoas: {
    label: "ROAS",
    format: "number",
  },
  adCpc: {
    label: "CPC",
    format: "currency",
  },

  // ----- ORGANIC -----
  organicSales: {
    label: "Organic Sales",
    format: "currency",
  },
  organicSpend: {
    label: "Organic Spend",
    format: "currency",
  },
  organicImpressions: {
    label: "Organic Impressions",
    format: "number",
  },
  organicClicks: {
    label: "Organic Clicks",
    format: "number",
  },
  organicCartAdds: {
    label: "Organic Cart Adds",
    format: "number",
  },
  organicUnitsSold: {
    label: "Organic Units Sold",
    format: "number",
  },
  organicCtr: {
    label: "Organic CTR",
    format: "percentage",
  },
  organicCvr: {
    label: "Organic CVR",
    format: "percentage",
  },
  organicOrders: {
    label: "Organic Orders",
    format: "number",
  },

  // ----- CFO -----
  availableCapital: {
    label: "Available Capital",
    format: "currency",
  },
  frozenCapital: {
    label: "Frozen Capital",
    format: "currency",
  },
  borrowedCapital: {
    label: "Borrowed Capital",
    format: "currency",
  },
  lostSales: {
    label: "Lost Sales",
    format: "currency",
  },
  grossMargin: {
    label: "Gross Margin",
    format: "currency",
  },
  opex: {
    label: "OPEX",
    format: "currency",
  },
  contribution: {
    label: "Contribution Profit",
    format: "currency",
  },
  profitEbitda: {
    label: "EBITDA",
    format: "currency",
  },

  // ----- COO -----
  fbaInStockRate: {
    label: "FBA In Stock Rate",
    format: "percentage",
  },
  fbtInStockRate: {
    label: "FBT In Stock Rate",
    format: "percentage",
  },
  "3plInStockRate": {
    label: "3PL In Stock Rate",
    format: "percentage",
  },
  shippingCosts: {
    label: "Shipping Costs",
    format: "currency",
  },
  transportCost: {
    label: "Transport Cost",
    format: "currency",
  },
  safetyStock: {
    label: "Safety Stock",
    format: "number",
  },
  lostSalesCfo: {
    label: "Lost Sales (CFO)",
    format: "currency",
  },
  storageCosts: {
    label: "Storage Costs",
    format: "currency",
  },
  forecastingAccuracy: {
    label: "Forecasting Accuracy",
    format: "percentage",
  },
  contributionMargin: {
    label: "Contribution Margin",
    format: "percentage",
  },
  inventoryTurnover: {
    label: "Inventory Turnover",
    format: "number",
  },
  "Available Capital": {
    label: "Available Capital",
    format: "currency",
  },
  "Frozen Capital": {
    label: "Frozen Capital",
    format: "currency",
  },
  "Lead Time": {
    label: "Lead Time",
    format: "number",
  },
  DOI: {
    label: "DOI",
    format: "number",
  },

  // --- market ---
  MarketTotalSales: {
    label: "Market Total Sales",
    format: "currency",
  },
  BrandMarketShare: {
    label: "Brand Market Share",
    format: "percentage",
  },
  MarketAveragePrice: {
    label: "Market Average Price",
    format: "currency",
  },
  MarketUnitsSold: {
    label: "Market Units Sold",
    format: "number",
  },
  MarketASINcount: {
    label: "Market ASIN Count",
    format: "number",
  },
  MarketPromotionValue: {
    label: "Market Promotion Value  ",
    format: "currency",
  },
  MarketPromotionCount: {
    label: "Market Promotion Count",
    format: "number",
  },
  MarketReviewScore: {
    label: "Market Review Score",
    format: "number",
  },
  MarketPOS: {
    label: "Market POS",
    format: "number",
  },
  MarketAdSpend: {
    label: "Market  Ad Spend",
    format: "currency",
  },

  //---Sales ----
  GrossRevenue: {
    label: "Gross Revenue",
    format: "currency",
  },
  Expenses: {
    label: "Expenses",
    format: "currency",
  },
  NetProfit: {
    label: "Net Profit",
    format: "currency",
  },
  UnitsSold: {
    label: "Units Sold",
    format: "currency",
  },
  TotalSales: {
    label: "Total Sales",
    format: "currency",
  },
  TotalSpend: {
    label: "Total Spend",
    format: "currency",
  },
  contributionProfit: {
    label: "Contribution Profit",
    format: "currency",
  },
  TotalUnitsSold: {
    label: "Total Units Sold",
    format: "currency",
  },
};

export const WIDGET_METRIC_GROUPS = [
  {
    title: "Total",
    keys: [
      "totalSales",
      "totalSpend",
      "totalImpressions",
      "totalClicks",
      "totalUnitsSold",
      "totalCtr",
      "totalCvr",
      "totalOrders",
      "totalNtbOrders",
      "tacos",
      "mer",
    ] as WidgetMetricKey[],
  },
  {
    title: "Ad",
    keys: [
      "adSales",
      "adSpend",
      "adImpressions",
      "adClicks",
      "adUnitsSold",
      "adCtr",
      "adCvr",
      "adOrders",
      "adAcos",
      "adRoas",
      "adCpc",
    ] as WidgetMetricKey[],
  },
  {
    title: "Organic",
    keys: [
      "organicSales",
      "organicSpend",
      "organicImpressions",
      "organicClicks",
      "organicUnitsSold",
      "organicCtr",
      "organicCvr",
      "organicOrders",
      "organicCartAdds",
    ] as WidgetMetricKey[],
  },
  {
    title: "COO",
    keys: [
      "fbaInStockRate",
      "shippingCosts",
      "safetyStock",
      "Available Capital",
      "Frozen Capital",
      "Lead Time",
      "forecastingAccuracy",
      "contributionMargin",
      "DOI",
      "storageCosts",
      "lostSalesCfo",
      "fbtInStockRate",
      "3plInStockRate",
    ] as WidgetMetricKey[],
  },
  {
    title: "Market",
    keys: [
      "MarketTotalSales",
      "BrandMarketShare",
      "MarketAveragePrice",
      "MarketUnitsSold",
      "MarketASINcount",
      "MarketPromotionValue",
      "MarketPromotionCount",
      "MarketReviewScore",
      "MarketPOS",
      "MarketAdSpend",
    ] as WidgetMetricKey[],
  },
  {
    title: "Sales",
    keys: [
      "GrossRevenue",
      "Expenses",
      "NetProfit",
      "UnitsSold",
    ] as WidgetMetricKey[],
  },
  {
    title: "SalesConfig",
    keys: [
      "totalSales",
      "totalSpend",
      "contributionProfit",
      "totalUnitsSold",
    ] as WidgetMetricKey[],
  },
  {
    title: "CFO",
    keys: [
      "availableCapital",
      "frozenCapital",
      "borrowedCapital",
      "lostSales",
      "grossMargin",
      "opex",
      "contribution",
      "profitEbitda",
    ] as WidgetMetricKey[],
  },
];
