import { HorizontalChart } from "@components/HorizontalChart/ui";
import {
  horizontalAdTypeData,
  horizontalCampaignsData,
  horizontalFunnelData,
  horizontalTargetData,
  horizontalTargetingData,
  verticalAdTypeData,
  verticalCampaignsData,
  verticalFunnelData,
  verticalTargetData,
  verticalTargetingData,
  matchData,
} from "./chart.mock";
import styles from "./styles.module.scss";
import { TreeChart } from "@components/TreeChart";
import { useEffect, useState } from "react";
import BackgroundGrid from "@assets/img/chart-bg.png";

export type ViewTab = "Sankey" | "Tree";
export type TypeTab =
  | "Campaigns"
  | "Ad Type"
  | "Funnel"
  | "Target Type"
  | "Targeting"
  | "Match type"
  | "Performance";

interface ChartProps {
  type: TypeTab;
  view: ViewTab;
  asin?: string;
}

const getTreeDataByType = (type: TypeTab) => {
  switch (type) {
    case "Campaigns":
      return horizontalCampaignsData;
    case "Ad Type":
      return horizontalAdTypeData;
    case "Funnel":
      return horizontalFunnelData;
    case "Target Type":
      return horizontalTargetData;
    case "Targeting":
      return horizontalTargetingData;
    default:
      return horizontalCampaignsData;
  }
};

const getSanKeyDataByType = (type: TypeTab) => {
  switch (type) {
    case "Campaigns":
      return verticalCampaignsData;
    case "Ad Type":
      return verticalAdTypeData;
    case "Funnel":
      return verticalFunnelData;
    case "Target Type":
      return verticalTargetData;
    case "Targeting":
      return verticalTargetingData;
    case "Match type":
      return matchData;
    default:
      return verticalFunnelData;
  }
};

export const Chart = ({ type, view, asin }: ChartProps) => {
  const [treeData, setTreeData] = useState(getTreeDataByType(type));
  const [sanKeyData, setSanKeyData] = useState(getSanKeyDataByType(type));

  useEffect(() => {
    if (view === "Tree") {
      setTreeData(getTreeDataByType(type));
    } else {
      setSanKeyData(getSanKeyDataByType(type));
    }
  }, [type, view]);

  return (
    <div
      className={styles.container}
      style={{
        background: `url(${BackgroundGrid})`,
        backgroundSize: "cover",
      }}
    >
      {view === "Tree" ? (
        <TreeChart data={treeData} isAdType={type === "Ad Type"} asin={asin} />
      ) : (
        <HorizontalChart data={sanKeyData} isAdType={type === "Ad Type"} />
      )}
    </div>
  );
};
