import useFlowValidation from "@hooks/useFlowValidation";
import { useReactFlow } from "@xyflow/react";
import cn from "classnames";
import styles from "./nodeCard.module.scss";

interface NodeCardProps {
  nodeId: string;
  children: React.ReactNode;
  isSelected: boolean;
}

export function NodeCard({ nodeId, children, isSelected }: NodeCardProps) {
  const { getNode, setCenter } = useReactFlow();
  const { invalidInputs } = useFlowValidation();
  const hasInvalidInputs = invalidInputs.some((node) => node.nodeId === nodeId);

  const handleDoubleClick = () => {
    const node = getNode(nodeId);
    if (!node?.position || !node?.measured) return;

    const { width = 0, height = 0 } = node.measured;
    const x = node.position.x + width / 2;
    const y = node.position.y + height / 2;

    setCenter(x, y, { zoom: 1, duration: 500 });
  };

  return (
    <div
      onDoubleClick={handleDoubleClick}
      className={cn(styles.nodeCard, {
        [styles["nodeCard--selected"]]: isSelected,
        [styles["nodeCard--invalid"]]: hasInvalidInputs,
      })}
    >
      {children}
      <div className={styles.nodeCard__id}>{nodeId}</div>
    </div>
  );
}
