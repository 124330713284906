import { create } from "zustand";

interface Stats {
  [key: string]: number | string;
}

interface IScoreAndStatsState {
  score: { [key: string]: number | null };
  stats: Stats;
  setScore: (
    updater:
      | { [key: string]: number | null }
      | ((prevScores: { [key: string]: number | null }) => {
          [key: string]: number | null;
        }),
  ) => void;
  setStats: (stats: Partial<Stats>) => void;
}

export const useScoreAndStatsStore = create<IScoreAndStatsState>((set) => ({
  score: {},
  stats: {},

  setScore: (updater) =>
    set((state) => ({
      score: typeof updater === "function" ? updater(state.score) : updater,
    })),

  setStats: (stats) => set({ stats }),
}));
