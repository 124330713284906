import { toDataURL } from "src/utils/toDataURL";
import { dataURLtoFile } from "./utils";
import removeBgJson from "./remove-background.json";
import getPlatformsJson from "./get-platforms.json";
import { Template } from "./types";

const endpointIds = {
  mask: "jxi6y75pihtxqq",
  generateImageByPrompt: "o7gj77xm50qmc1",
  generateImageByPromptAndAsset: "o7gj77xm50qmc1",
  default: `${import.meta.env.BUILD_API_BASE_URL}`,
} as const;

const userToken = localStorage.getItem("refresh_token");

const checkTaskStatus = async <T>(
  taskId: string,
  id: keyof typeof endpointIds,
  resultType: "result" | "images",
): Promise<T> => {
  return new Promise((res, rej) => {
    const timeoutId = setInterval(async () => {
      try {
        const endpoint_id = endpointIds[id];

        const response = await fetch(
          `https://api.runpod.ai/v2/${endpoint_id}/status/${taskId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
            },
          },
        );
        const data = await response.json();

        if (data.status === "COMPLETED") {
          clearTimeout(timeoutId);
          if (resultType === "result") {
            res(data.output.result as T);
          } else if (resultType === "images") {
            res(data.output.images[0] as T);
          }
        } else if (
          data.status === "IN_QUEUE" ||
          data.status === "IN_PROGRESS"
        ) {
          // noop
        } else {
          clearTimeout(timeoutId);
          console.error(`Task ${id} failed with status: ${data.status}`);
        }
      } catch (error) {
        clearTimeout(timeoutId);
        console.error(
          "An error occurred while checking the task status:",
          error,
        );
        rej(error);
      }
    }, 2000);
  });
};

export const getMaskAndPromptSuggestions = async (base64DataURI: string) => {
  const base64Image = base64DataURI.split(",")[1];

  try {
    const maskResponse = fetch(
      `https://api.runpod.ai/v2/${endpointIds.mask}/run`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
        },
        body: JSON.stringify({
          input: { img: base64Image },
        }),
      },
    );

    const promptResponse = fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer sk-proj-RJeZQynbcUvd1gzLcjKBT3BlbkFJEdz0mAFJlVTpWZYtTywI",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-4o-2024-08-06",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Provide 3 prompts for Stable Diffusion model that will generate background for advertising photo of main object of provided image. For example, if provided image has sport nutrition package on it, provided prompts could be backgrounds with kitchen environment with some healthy food on kitchen table, fitness club surroundings, nature surroundings, or just some minimalistic surroundings with just product pack.",
              },
              {
                type: "image_url",
                image_url: { url: base64DataURI },
              },
            ],
          },
        ],
        response_format: {
          type: "json_schema",
          json_schema: {
            name: "prompt_suggestions",
            schema: {
              type: "object",
              properties: {
                suggestions: {
                  type: "array",
                  items: { type: "string" },
                },
              },
              required: ["suggestions"],
              additionalProperties: false,
            },
            strict: true,
          },
        },
      }),
    });

    const [maskData, promptData] = await Promise.all([
      maskResponse,
      promptResponse,
    ]);
    const promptJson = await promptData.json();
    const maskJson = await maskData.json();

    const suggestions1 = JSON.parse(
      promptJson.choices[0].message.content,
    ).suggestions;

    const taskId = maskJson.id;
    const masks = await checkTaskStatus<string[]>(taskId, "mask", "result");

    return [masks, suggestions1];
  } catch (error) {
    console.error("An error occurred while processing input image.", error);
  }
  return [];
};

export const generateImageByPrompt = async (
  img_base64: string,
  img_mask_base64: string,
  prompt: string,
): Promise<string> => {
  try {
    const response = await fetch(
      `https://api.runpod.ai/v2/${endpointIds.generateImageByPrompt}/run`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
        },
        body: JSON.stringify({
          input: {
            img_base64: img_base64,
            img_mask_base64: img_mask_base64,
            prompt: prompt,
          },
        }),
      },
    );

    const generateIMG = await response.json();

    if (generateIMG.id) {
      const result = await checkTaskStatus<string | string[]>(
        generateIMG.id,
        "generateImageByPrompt",
        "images",
      );

      const image = Array.isArray(result) ? result[0] : result;

      return typeof image === "string" ? image : "";
    } else {
      console.error("Failed to get task ID from response:", generateIMG);
    }
  } catch (error) {
    console.error("An error occurred while generating the image:", error);
  }
  return "";
};

export const generateImageByPromptAndAsset = async (
  img_base64: string,
  img_mask_base64: string,
  prompt: string,
  props_img_base64: string,
  props_mask_base64: string,
): Promise<string> => {
  const payload = {
    input: {
      img_base64,
      img_mask_base64,
      prompt,
      props_img_base64,
      props_mask_base64,
    },
  };

  try {
    const response = await fetch(
      `https://api.runpod.ai/v2/${endpointIds.generateImageByPromptAndAsset}/run`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
        },
        body: JSON.stringify(payload),
      },
    );

    const generateIMG = await response.json();

    if (generateIMG.id) {
      const result = await checkTaskStatus<string>(
        generateIMG.id,
        "generateImageByPromptAndAsset",
        "images",
      );

      return result;
    } else {
      console.error("Failed to get task ID from response:", generateIMG);
    }
  } catch (error) {
    console.error("An error occurred while generating the image:", error);
  }
  return "";
};

export const removeBackground = (() => {
  const cache = new Map<string, Array<{ mask: string; image: string }>>();

  return async (
    image: string,
  ): Promise<Array<{ mask: string; image: string }>> => {
    if (cache.has(image)) {
      return cache.get(image)!;
    }

    try {
      const formData = new FormData();
      const imageDataUrl = await toDataURL(image);
      const file = dataURLtoFile(imageDataUrl, "file.png");

      formData.set("file", file);

      const response = await fetch(
        `${endpointIds.default}/ai-images/remove-background`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formData,
        },
      );

      const data = await response.json();
      const result = Array.isArray(data) ? data : [data];

      cache.set(image, result);

      return result;
    } catch (error) {
      console.error("Error in removeBackground:", error);
      return [];
    }
  };
})();

export const getTemplates = async (): Promise<Template[]> => {
  try {
    const response = await fetch(
      `${endpointIds.default}/ai-images/get-templates/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    const data = await response.json();

    return Array.isArray(data.templates) ? data.templates : [];
  } catch {
    return [];
  }
};

export const getPlatforms = async (): Promise<
  { original: string; mask: string }[]
> => {
  return getPlatformsJson;

  try {
    const response = await fetch(
      `${endpointIds.default}/ai-images/get-platforms/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken})}`,
        },
      },
    );

    const data = await response.json();

    return data;
  } catch {
    return [];
  }
};

export const getImageProductDescription = async (
  image: string,
): Promise<string> => {
  const formData = new FormData();
  const imageDataUrl = await toDataURL(image);
  const file = dataURLtoFile(imageDataUrl, "file.png");

  formData.set("file", file);

  try {
    const response = await fetch(
      `${endpointIds.default}/ai-images/image-product-description/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
      },
    );

    const { description } = await response.json();

    const removeDotInTheEnd = (value: string) => value.replace(/\.$/, "");

    return removeDotInTheEnd(description);
  } catch {
    return "";
  }
};

export const getPromptByImage = async (image: string): Promise<string> => {
  const formData = new FormData();
  const imageDataUrl = await toDataURL(image);
  const file = dataURLtoFile(imageDataUrl, "file.png");

  formData.set("file", file);

  try {
    const response = await fetch(
      `${endpointIds.default}/ai-images/image-analog-prompt`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
      },
    );

    const { prompt } = await response.json();
    return prompt;
  } catch {
    return "";
  }
};
