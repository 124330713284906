import { useEffect, useState } from "react";
import "./index.css";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Tab } from "../Widgets/Tab";
import { useSuplyStore } from "@pages/Dashboard/store/suplyChain.state";
import { Spin } from "antd";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WidgetClass } from "@services/widget.service";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { Widget } from "../Widgets/Widget";
import MarketplaceTable from "../MarketSalesTable";

export const SalesChannel = () => {
  const [widgets, setWidgets] = useState<IWidget[]>();
  const { customSalesWidgets } = useSuplyStore((state) => ({
    customSalesWidgets: state.customSalesWidgets,
  }));

  const { compareWith } = useAdsAnaliticsStore();
  const { dateRange, compareType } = useDashboardStore();
  const isRestrictedRoute = location.pathname.includes(" ");
  const [isLoading, setIsLoading] = useState(true);
  const { startDate, endDate } = dateRange;
  const { selectedWidgetsSales } = useDashboardStore();

  const formatDifference = (difference: string): string => {
    if (difference.startsWith("+") || difference.startsWith("-")) {
      return difference;
    }
    return `+${difference}`;
  };

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgetsSales,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
      );

      let updatedWidgets = response;

      updatedWidgets = selectedWidgetsSales
        .map((key) => {
          const widget = response.find((w) => w.id === key);

          if (!widget) return null;
          const label = WIDGET_METRICS_CONFIG[key]?.label || key;
          return {
            ...widget,
            title: label.trim(),
            name: label.trim(),
            difference: formatDifference(widget.difference),
          };
        })
        .filter(Boolean)
        .slice(0, 10);

      setWidgets(updatedWidgets);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [selectedWidgetsSales, dateRange, compareWith, compareType]);

  return (
    <>
      <div className="SalesChannels">
        <h1 className="tabContainer">All Sales Channels</h1>
        <Tab
          customSelectedWidgets={customSalesWidgets}
          isRestrictedRoute={true}
          hideCalendar={true}
        />
      </div>

      <div className="containerMetric">
        {isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          <div className="widgets">
            {widgets.map((widget, index) => (
              <Widget key={index} widget={widget} additionalClass="widget" />
            ))}
          </div>
        )}
      </div>
      <MarketplaceTable />
    </>
  );
};
