export default () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6531 9.47539C14.6531 9.32289 14.7137 9.17664 14.8215 9.0688C14.9294 8.96097 15.0756 8.90039 15.2281 8.90039H16.3781C16.5306 8.90039 16.6769 8.96097 16.7847 9.0688C16.8925 9.17664 16.9531 9.32289 16.9531 9.47539V10.6254C16.9531 10.7779 16.8925 10.9241 16.7847 11.032C16.6769 11.1398 16.5306 11.2004 16.3781 11.2004H15.2281C15.0756 11.2004 14.9294 11.1398 14.8215 11.032C14.7137 10.9241 14.6531 10.7779 14.6531 10.6254V9.47539ZM11.2031 9.47539C11.2031 9.32289 11.2637 9.17664 11.3715 9.0688C11.4794 8.96097 11.6256 8.90039 11.7781 8.90039H12.9281C13.0806 8.90039 13.2269 8.96097 13.3347 9.0688C13.4425 9.17664 13.5031 9.32289 13.5031 9.47539V10.6254C13.5031 10.7779 13.4425 10.9241 13.3347 11.032C13.2269 11.1398 13.0806 11.2004 12.9281 11.2004H11.7781C11.6256 11.2004 11.4794 11.1398 11.3715 11.032C11.2637 10.9241 11.2031 10.7779 11.2031 10.6254V9.47539ZM5.45312 12.9254C5.45312 12.7729 5.51371 12.6266 5.62154 12.5188C5.72937 12.411 5.87563 12.3504 6.02812 12.3504H7.17812C7.33062 12.3504 7.47688 12.411 7.58471 12.5188C7.69254 12.6266 7.75312 12.7729 7.75312 12.9254V14.0754C7.75312 14.2279 7.69254 14.3741 7.58471 14.482C7.47688 14.5898 7.33062 14.6504 7.17812 14.6504H6.02812C5.87563 14.6504 5.72937 14.5898 5.62154 14.482C5.51371 14.3741 5.45312 14.2279 5.45312 14.0754V12.9254ZM8.90312 12.9254C8.90312 12.7729 8.96371 12.6266 9.07154 12.5188C9.17937 12.411 9.32563 12.3504 9.47813 12.3504H10.6281C10.7806 12.3504 10.9269 12.411 11.0347 12.5188C11.1425 12.6266 11.2031 12.7729 11.2031 12.9254V14.0754C11.2031 14.2279 11.1425 14.3741 11.0347 14.482C10.9269 14.5898 10.7806 14.6504 10.6281 14.6504H9.47813C9.32563 14.6504 9.17937 14.5898 9.07154 14.482C8.96371 14.3741 8.90312 14.2279 8.90312 14.0754V12.9254Z"
        fill="#7C7C78"
      />
      <path
        d="M6.025 2C6.1775 2 6.32375 2.06058 6.43159 2.16841C6.53942 2.27625 6.6 2.4225 6.6 2.575V3.15H15.8V2.575C15.8 2.4225 15.8606 2.27625 15.9684 2.16841C16.0762 2.06058 16.2225 2 16.375 2C16.5275 2 16.6738 2.06058 16.7816 2.16841C16.8894 2.27625 16.95 2.4225 16.95 2.575V3.15H18.1C18.71 3.15 19.295 3.39232 19.7263 3.82365C20.1577 4.25499 20.4 4.84 20.4 5.45V18.1C20.4 18.71 20.1577 19.295 19.7263 19.7263C19.295 20.1577 18.71 20.4 18.1 20.4H4.3C3.69 20.4 3.10499 20.1577 2.67365 19.7263C2.24232 19.295 2 18.71 2 18.1V5.45C2 4.84 2.24232 4.25499 2.67365 3.82365C3.10499 3.39232 3.69 3.15 4.3 3.15H5.45V2.575C5.45 2.4225 5.51058 2.27625 5.61841 2.16841C5.72625 2.06058 5.8725 2 6.025 2V2ZM3.15 6.6V18.1C3.15 18.405 3.27116 18.6975 3.48683 18.9132C3.70249 19.1288 3.995 19.25 4.3 19.25H18.1C18.405 19.25 18.6975 19.1288 18.9132 18.9132C19.1288 18.6975 19.25 18.405 19.25 18.1V6.6H3.15Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
