export const handleStepChange = (
  index: number,
  setCurrentStep: (index: number) => void,
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>,
  playVideo = true,
) => {
  setCurrentStep(index);
  videoRefs.current.forEach((video, i) => {
    if (video) {
      if (i === index && playVideo) {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    }
  });
};

export const handleVideoEnd = (
  index: number,
  stepsLength: number,
  handleStepChange: (index: number, playVideo?: boolean) => void,
) => {
  if (index < stepsLength - 1) {
    handleStepChange(index + 1);
  } else {
    handleStepChange(0, false);
  }
};

export const setupVideoEndListeners = (
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>,
  stepsLength: number,
  handleStepChange: (index: number, playVideo?: boolean) => void,
) => {
  const handleEnd = (index: number) => () =>
    handleVideoEnd(index, stepsLength, handleStepChange);

  videoRefs.current.forEach((video, index) => {
    if (video) {
      video.addEventListener("ended", handleEnd(index));
    }
  });

  return () => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.removeEventListener("ended", handleEnd(index));
      }
    });
  };
};
