import React, { useState } from "react";
import { Table, Button, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./TableDataEntry.css";

const formatText = (text: string): string => {
  return text.replace(/(\$\d+,\d+)/g, "$1\n").replace(/\s*\/\s*/g, "\n");
};

const TableDataEntry = () => {
  const [data, setData] = useState([
    {
      key: "1",
      asin: "B07K4QM4Y2",
      dateStart: "01.01.2024",
      dateEnd: "07.01.2024",
      cogs: "$9.50",
      price: formatText("$11,000 Net Processed / $4,200 Contribution Profit"),
      unitsSold: "372",
      unitsReturned: formatText(
        "$2,450 Net Sales / $3,000 Net Proceeds / $150.25 Net Margin",
      ),
      amazonFee: formatText(
        "$2,450 Base Fulfillment Fee / $3,000 FBA Fulfillment Fees / $150.25 Refund Administration Fee",
      ),
    },
    {
      key: "2",
      asin: "B07K4QM4Y2",
      dateStart: "01.01.2024",
      dateEnd: "07.01.2024",
      cogs: "$9.50",
      price: formatText("$11,000 Net Processed / $4,200 Contribution Profit"),
      unitsSold: "372",
      unitsReturned: formatText(
        "$2,450 Net Sales / $3,000 Net Proceeds / $150.25 Net Margin",
      ),
      amazonFee: formatText(
        "$2,450 Referral Fee / $3,000 Monthly Inventory Storage Fee",
      ),
    },
    {
      key: "3",
      asin: "B07K4QM4Y2",
      dateStart: "01.01.2024",
      dateEnd: "07.01.2024",
      cogs: "$9.50",
      price: formatText("$11,000 Net Processed / $4,200 Contribution Profit"),
      unitsSold: "372",
      unitsReturned: formatText(
        "$2,450 Net Sales / $3,000 Net Proceeds / $150.25 Net Margin",
      ),
      amazonFee: formatText(
        "$2,450 Base Fulfillment Fee / $3,000 FBA Fulfillment Fees / $150.25 Refund Administration Fee",
      ),
    },
    {
      key: "4",
      asin: "B07K4QM4Y2",
      dateStart: "01.01.2024",
      dateEnd: "07.01.2024",
      cogs: "$9.50",
      price: formatText("$11,000 Net Processed / $4,200 Contribution Profit"),
      unitsSold: "372",
      unitsReturned: formatText(
        "$2,450 Net Sales / $3,000 Net Proceeds / $150.25 Net Margin",
      ),
      amazonFee: formatText(
        "$2,450 Base Fulfillment Fee / $3,000 FBA Fulfillment Fees / $150.25 Refund Administration Fee",
      ),
    },
  ]);

  const handleDelete = (key: string) => {
    setData((prev) => prev.filter((item) => item.key !== key));
  };

  const handleEdit = (key: string) => {
    alert(`Редактирование строки с ключом: ${key}`);
  };

  const columns = [
    { title: "ASIN", dataIndex: "asin", key: "asin" },
    { title: "Date Start", dataIndex: "dateStart", key: "dateStart" },
    { title: "Date End", dataIndex: "dateEnd", key: "dateEnd" },
    { title: "COGS", dataIndex: "cogs", key: "cogs" },
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Units Sold, Items", dataIndex: "unitsSold", key: "unitsSold" },
    {
      title: "Units Returned",
      dataIndex: "unitsReturned",
      key: "unitsReturned",
    },
    { title: "Amazon Fee", dataIndex: "amazonFee", key: "amazonFee" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="actions">
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record.key)}
          />
          <Popconfirm
            title="Are you sure to delete this row?"
            onConfirm={() => handleDelete(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      bordered
      className="custom-table"
    />
  );
};

export default TableDataEntry;
