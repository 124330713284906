export const columns = [
  {
    title: "Placement",
    dataIndex: "placement",
    key: "placement",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Ad Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: 140,
  },
  {
    title: "Share",
    dataIndex: "share",
    key: "share",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: 140,
  },
  {
    title: "Ad Unit Sold",
    dataIndex: "unitSold",
    key: "unitSold",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "cvr",
    key: "cvr",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CAC",
    dataIndex: "cac",
    key: "cac",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "sales",
    key: "sales",
    width: 140,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
];

export const data = [
  {
    placement: "First page Top of Search on Amazon",
    impressions: "14,931",
    share: "4.40%",
    clicks: "729",
    unitSold: "35",
    ctr: "4.84%",
    cvr: "4.80%",
    cpc: "$3.85",
    cac: "$80",
    spend: "$2,809",
    sales: "$4,790",
    acos: "59%",
    roas: "1.71",
  },
  {
    placement: "Product pages on Amazon",
    impressions: "283,590",
    share: "83.58%",
    clicks: "284",
    unitSold: "12",
    ctr: "0.10%",
    cvr: "4.23%",
    cpc: "$3.83",
    cac: "$91",
    spend: "$1,048",
    sales: "$2,310",
    acos: "47%",
    roas: "2.13",
  },
  {
    placement: "Rest of search on Amazon",
    impressions: "40,759",
    share: "12.02%",
    clicks: "189",
    unitSold: "1",
    ctr: "0.46%",
    cvr: "0.53%",
    cpc: "$2.93",
    cac: "$554",
    spend: "$554",
    sales: "$105",
    acos: "528%",
    roas: "0.19",
  },
  {
    placement: "Grand Total",
    impressions: "339,280",
    share: "100%",
    clicks: "1,202",
    unitSold: "48",
    ctr: "0.35%",
    cvr: "4.00%",
    cpc: "$3.57",
    cac: "$58",
    spend: "$4,411",
    sales: "$7,205",
    acos: "61%",
    roas: "1.63",
  },
];
