import { useState } from "react";
import styles from "./styles.module.scss";
import { Navigation } from "./navigation";
import { CalendarModal } from "@components/calendar-modal";
import { formatDateRange } from "src/utils/formatDate";
import CalendarImage from "@assets/icons/calendar.svg";
import classNames from "classnames";
import CustomSelect from "./CustomSelect";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";

interface HeaderProps {
  setIsOpenMenu: (isOpen: boolean) => void;
}

export const Header = ({ setIsOpenMenu }: HeaderProps) => {
  const { dateRange, setDateRange, compareType, setCompareType } =
    useDashboardStore((state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      compareType: state.compareType,
      setCompareType: state.setCompareType,
    }));
  const { isWhatIfEnabled, setIsWhatIfEnabled, compareWith, setCompareWith } =
    useAdsAnaliticsStore((state) => ({
      isWhatIfEnabled: state.isWhatIfEnabled,
      setIsWhatIfEnabled: state.setIsWhatIfEnabled,
      compareWith: state.compareWith,
      setCompareWith: state.setCompareWith,
    }));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title__container}>
        <Navigation setIsOpenMenu={setIsOpenMenu} />
        <h1 className={styles.title}>Ad Campaign Manager</h1>
      </div>
      <div className={styles.buttons}>
        <div className={styles.calendar}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsCalendarOpen(!isCalendarOpen);
            }}
            className={classNames(
              styles.button,
              isCalendarOpen && styles.active,
            )}
          >
            <img src={CalendarImage} alt="Calendar" />
            <span>{selectedDateRange}</span>
            <div className={styles.decor}></div>
          </button>
          {isCalendarOpen && (
            <div className={styles.modal}>
              <CalendarModal
                buttonWidth={148}
                closeModal={() => setIsCalendarOpen(false)}
                onDateChange={handleDateChange}
                initialRange={dateRange}
              />
            </div>
          )}
        </div>
        <CustomSelect
          dateRange={dateRange}
          compareWith={compareWith}
          setCompareWith={setCompareWith}
          compareType={compareType}
          setCompareType={setCompareType}
        />
        <button
          className={styles.whatIf}
          onClick={() => {
            setIsWhatIfEnabled(!isWhatIfEnabled);
          }}
        >
          {!isWhatIfEnabled ? "switch to What IF" : "Save Changes"}
        </button>
      </div>
    </div>
  );
};
