import React, { useState } from "react";
import { Button, Card, Switch } from "antd";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Customized,
} from "recharts";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";
import {
  legendColorMapping,
  legendOrder,
  nameMapping,
  transformedData,
} from "./utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { CustomBar } from "@components/BarChart";
import { Wave } from "@components/Wave";

const FinanceFlow = () => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [showForecast, setShowForecast] = useState<boolean>(false);

  const chartMargins = { top: 20, right: 30, left: 50, bottom: 5 };

  return (
    <Card className={styles.card}>
      <div className={styles.headerBetween}>
        <h1 className={styles.title}>Finance Flow</h1>
      </div>

      <div className={styles.rowLegend}>
        <div className={styles.legendContainer}>
          {legendOrder.map((key) => (
            <div className={styles.legendItem} key={key}>
              <span
                className={styles.colorDot}
                style={{ backgroundColor: legendColorMapping[key] }}
              />
              <span className={styles.legendText}>{nameMapping[key]}</span>
            </div>
          ))}

          <div className={styles.legendItem}>
            <span className={styles.colorDot} />
            <span className={styles.legendText}>Cumulative SF</span>
          </div>
        </div>
        <div className={styles.switchRow}>
          <div className={styles.switchContainer}>
            <Switch
              checked={showForecast}
              onChange={(checked) => setShowForecast(checked)}
            />
            <span className={styles.switchLabel}>Show Forecast</span>
          </div>

          <div className={styles.switchContainer}>
            <Switch />
            <span className={styles.switchLabel}>Show Past Data</span>
          </div>
          <Button
            type="link"
            className={styles.infoButton}
            icon={
              <img
                src={InfoIcon}
                alt="Info"
                className={styles.infoIcon}
                width={14}
                height={14}
              />
            }
          />
        </div>
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            data={transformedData}
            margin={chartMargins}
            stackOffset="sign"
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setActiveLabel(state.activeLabel as string);
              } else {
                setActiveLabel(null);
              }
            }}
            onMouseLeave={() => setActiveLabel(null)}
          >
            <CartesianGrid stroke="#1F1F20" vertical={false} />
            <XAxis
              dataKey="date"
              tick={{ fill: "white" }}
              axisLine={{ stroke: "#1F1F20" }}
            />
            <YAxis
              tick={{ fill: "white" }}
              axisLine={{ stroke: "#1F1F20" }}
              domain={[-10000, 10000]}
              tickFormatter={(value) => `$${value / 1000}K`}
            />
            <Tooltip
              content={({ active, payload, label }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  colorMetric={legendColorMapping}
                  label={label}
                  disableMapping={true}
                />
              )}
            />

            {legendOrder.map((key) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={legendColorMapping[key]}
                name={nameMapping[key]}
                shape={(barProps) => (
                  <CustomBar
                    {...barProps}
                    activeLabel={activeLabel}
                    showForecast={showForecast}
                  />
                )}
              />
            ))}

            <Line
              type="monotone"
              dataKey="CumulativeSF"
              stroke="#5295E0"
              strokeWidth={2}
              dot={{ r: 5, fill: "#5295E0" }}
              name="Cumulative SF"
              connectNulls
            />

            <Customized
              component={({ width, height }) => {
                const leftMargin = chartMargins.left;
                const rightMargin = chartMargins.right;
                const plotWidth = width - leftMargin - rightMargin;

                return (
                  <svg width={width} height={height}>
                    <Wave
                      width={plotWidth}
                      height={height - chartMargins.top - chartMargins.bottom}
                      numberOfBars={7}
                      shiftPx={110}
                      cumulativeStart={-5000}
                      yDomain={[-10000, 10000]}
                    />
                  </svg>
                );
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default FinanceFlow;
