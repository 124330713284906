import { Checkbox, Button } from "antd";
import InfoIcon from "@assets/icons/i.svg";
import { EChartsOption } from "echarts";

export const createDropdownContent = (columns, toggleColumn, handleApply) => {
  return (
    <div
      style={{
        width: "258px",
        height: "346px",
        background: "#1F1F20",
        padding: "16px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <h4
        style={{
          color: "#7C7C78",
          margin: "0 0 8px",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "14px",
          letterSpacing: "-0.2px",
        }}
      >
        Columns
      </h4>
      {columns.map((column) => (
        <div
          key={column.name}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Checkbox
            style={{
              color: "#FFF",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "-0.2px",
              textTransform: "none",
            }}
            checked={column.checked}
            onChange={() => toggleColumn(column.name)}
          >
            <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {column.name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}{" "}
              <img
                src={InfoIcon}
                alt="Info"
                style={{ width: "16px", height: "16px" }}
              />
            </span>
          </Checkbox>
        </div>
      ))}

      <Button
        type="primary"
        style={{
          marginTop: "auto",
          width: "100%",
          background: "#5295E033",
          borderColor: "transparent",
          boxShadow: "none",
          borderRadius: "8px",
          color: "#5295E0",
        }}
        onClick={handleApply}
      >
        Apply
      </Button>
    </div>
  );
};

export const mockData = [
  { name: "Contribution Profit", value: 795300, color: "#255FA0" },
  { name: "COGS", value: -446700, color: "#FE5858" },
  { name: "Amazon Fees", value: 533100, color: "#FE5858" },
  { name: "Spend", value: -133100, color: "#FE5858" },
  { name: "Ad Sales", value: 1171100, color: "#80C67A" },
  { name: "Organic Sales", value: -269400, color: "#80C67A" },
  { name: "What if Contribution Profit", value: 1127300, color: "#255FA0" },
];

export const getOption = (
  mockData: Array<{ name: string; value: number; color: string }>,
  columns: Array<{ name: string; checked: boolean }>,
  isSummarize: boolean,
): EChartsOption => {
  const filteredData = mockData.filter(
    (item) => columns.find((col) => col.name === item.name)?.checked,
  );

  const chartData = isSummarize
    ? [
        filteredData.find((item) => item.name === "Contribution Profit")
          ? {
              name: "Contribution Profit",
              value:
                filteredData.find((item) => item.name === "Contribution Profit")
                  ?.value || 0,
              color: "#255FA0",
            }
          : null,
        filteredData.some((item) =>
          ["COGS", "Amazon Fees", "Spend"].includes(item.name),
        )
          ? {
              name: "Total spend",
              value: filteredData
                .filter((item) =>
                  ["COGS", "Amazon Fees", "Spend"].includes(item.name),
                )
                .reduce((sum, item) => sum + item.value, 0),
              color: "#FE5858",
            }
          : null,
        filteredData.some((item) =>
          ["Ad Sales", "Organic Sales"].includes(item.name),
        )
          ? {
              name: "Total sales",
              value: filteredData
                .filter((item) =>
                  ["Ad Sales", "Organic Sales"].includes(item.name),
                )
                .reduce((sum, item) => sum + item.value, 0),
              color: "#80C67A",
            }
          : null,
        filteredData.find((item) => item.name === "What if Contribution Profit")
          ? {
              name: "What if Contribution Profit",
              value:
                filteredData.find(
                  (item) => item.name === "What if Contribution Profit",
                )?.value || 0,
              color: "#255FA0",
            }
          : null,
      ].filter(Boolean)
    : filteredData;

  let cumulative = 0;
  const dataForChart = chartData.map((item) => {
    const start = cumulative;
    const end = start + item.value;
    cumulative = end;
    return { ...item, start, end };
  });

  return {
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: (params: any) => {
        const idx = params[0]?.dataIndex;
        const item = dataForChart[idx];
        return `${item.name}: $${(item.value / 1000).toFixed(1)}K`;
      },
    },
    xAxis: {
      type: "category",
      data: dataForChart.map((d) => d.name),
      axisLabel: {
        color: "#FFF",
        fontSize: 10,
        interval: 0,
        rich: { align: "center" },
        formatter: (value: string) => {
          if (value === "What if Contribution Profit") {
            return "{align|What if}\n{align|Contribution}\n{align|Profit}";
          }
          return value.split(" ").join("\n");
        },
      },
    },
    yAxis: {
      type: "value",
      max: 2000000,
      min: -1500000,
      interval: 500000,
      axisLabel: {
        color: "#FFF",
        fontSize: 12,
        formatter: (val: number) => {
          const fv = (val / 1000000).toFixed(1).replace(".", ",");
          return fv.endsWith(",0") ? `$${fv.slice(0, -2)}M` : `$${fv}M`;
        },
      },
    },
    grid: { left: "10%", right: "10%", top: "5%", bottom: "15%" },
    series: [
      {
        type: "custom",
        barWidth: "100%",
        name: "Waterfall",
        renderItem: (params, api) => {
          const idx = params.dataIndex;
          const { start, end, color } = dataForChart[idx];
          const startCoord = api.coord([idx, start]);
          const endCoord = api.coord([idx, end]);
          const size = api.size([1, end - start]);
          const rectY = Math.min(startCoord[1], endCoord[1]);
          const rectHeight = Math.abs(endCoord[1] - startCoord[1]);
          const barWidth = size[0] * 1;
          const barX = startCoord[0] - barWidth / 2;

          return {
            type: "rect",
            shape: {
              x: barX,
              y: rectY,
              width: barWidth,
              height: rectHeight,
            },
            style: {
              fill: color,
            },
          };
        },
        data: dataForChart.map((d) => d.value),
      },
    ],
  };
};
