import React, { useMemo, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classNames from "classnames";
import { formatThousands } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { Checks } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { Legend } from "@pages/Dashboard/features/SalesPerformance/Legend";
import MetricModal from "@pages/Dashboard/features/SalesPerformance/MetricModal";
import { initialChecks } from "@pages/Dashboard/features/SalesPerformance/types";
import {
  AvgData,
  AVGDataCHART,
  AVGDataPriceCHART,
  AvgPriceData,
  customMapKeyToMetric,
  metricsColors,
  mockedData,
  titleData,
} from "./utils";

import "./index.scss";
import { CustomAxisLine } from "@components/AxisLine";

interface ChartProps {
  checks: Checks;
  metricsColors: { [key: string]: string };
  data: any[];
  isMobile?: any;
  setChecks: React.Dispatch<React.SetStateAction<typeof initialChecks>>;
  titles?: boolean;
  avg?: boolean;
  avgPrice?: boolean;
}

export const Chart: React.FC<ChartProps> = ({
  checks,
  data,
  isMobile,
  setChecks,
  titles,
  avg,
  avgPrice,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedMetrics = useMemo(
    () => Object.keys(checks).filter((key) => checks[key]),
    [checks],
  );
  const mockD = useMemo(() => {
    switch (true) {
      case avgPrice:
        return AVGDataPriceCHART;
      case avg:
        return AVGDataCHART;
      default:
        return mockedData;
    }
  }, [avg, avgPrice]);

  const filteredData = titles
    ? mockD
    : data.map(
        ({ date, sales, adUnitsSold, totalForecast, ASIN, ASINForecast }) => ({
          date,
          sales,
          adUnitsSold,
          totalForecast,
          ASIN,
          ASINForecast,
        }),
      );

  const yAxisIdMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    selectedMetrics.forEach((metric, index) => {
      map[metric] = `yAxis${index + 1}`;
    });
    return map;
  }, [selectedMetrics]);

  const chartData = useMemo(() => {
    if (!titles) {
      return filteredData;
    }
    const forecastStartIndex = 4;
    return filteredData.map((point, index) => {
      let salesSolid = null,
        salesDashed = null,
        ASINSolid = null,
        ASINDashed = null;
      let marketShareSolid = null,
        marketShareDashed = null;

      if (index < forecastStartIndex) {
        salesSolid = point.sales;
        ASINSolid = point.ASIN;
        marketShareSolid = point.MarketShare;
      } else if (index === forecastStartIndex) {
        salesSolid = point.sales;
        salesDashed = point.sales;
        ASINSolid = point.ASIN;
        ASINDashed = point.ASIN;
        marketShareSolid = point.MarketShare;
        marketShareDashed = point.MarketShare;
      } else {
        salesDashed = point.totalForecast ?? point.sales;
        ASINDashed = point.ASINForecast ?? point.ASIN;
        marketShareDashed = point.MarketShareForecast ?? point.MarketShare;
      }

      return {
        ...point,
        salesSolid,
        salesDashed,
        ASINSolid,
        ASINDashed,
        marketShareSolid,
        marketShareDashed,
      };
    });
  }, [filteredData, titles, avg]);

  const yAxisIdSales = "yAxis_sales";
  const yAxisIdASIN = "yAxis_ASIN";

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const allowedMetrics = ["Total Sales", "Ad Units Sold"];

  const dataToRender = useMemo(() => {
    switch (true) {
      case avgPrice:
        return AvgPriceData;
      case avg:
        return AvgData;
      default:
        return titleData;
    }
  }, [avg, avgPrice]);

  const gradientMetric = useMemo(() => {
    if (!Array.isArray(chartData) || chartData.length === 0) return null;
    if (!Array.isArray(selectedMetrics) || selectedMetrics.length === 0)
      return null;

    const firstDataPoint = chartData[0];
    let maxMetricKey = selectedMetrics[0];

    selectedMetrics.forEach((metricKey) => {
      const metricVal = firstDataPoint[metricKey];
      if (
        typeof metricVal === "number" &&
        metricVal > firstDataPoint[maxMetricKey]
      ) {
        maxMetricKey = metricKey;
      }
    });

    return maxMetricKey;
  }, [selectedMetrics, chartData]);

  const ticks = useMemo(() => {
    if (avgPrice) {
      return [0, 2, 4, 6, 8];
    }
    return avg ? [0, 500, 1000] : [500, 1000, 1500, 2000, 2500];
  }, [avg, avgPrice]);

  const tickFormatter = useMemo(() => {
    if (avgPrice) {
      return (tick: number) => `${tick}%`;
    }
    return avg
      ? (tick: number) => `$${tick}`
      : (tick: number) => `${(tick / 1000).toFixed(1)}K`;
  }, [avg, avgPrice]);

  return (
    <div
      className={classNames({
        "sales-performance__chartTitle": titles,
        "sales-performance__chart": !titles,
      })}
    >
      {titles ? (
        <div className="sales-BetweenTitle">
          {titles && (
            <div className="sales-title-wrapper">
              {dataToRender.map((item) => (
                <div key={item.title} className="sales-title-item">
                  <div
                    className="sales-title-box"
                    style={item.squareStyle}
                  ></div>
                  <span className="sales-title">{item.title}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="sales-Between">
          <Legend
            handleOpenModal={handleOpenModal}
            checks={checks}
            metricsColors={metricsColors}
            allowedMetrics={allowedMetrics}
          />
          <MetricModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            setChecks={setChecks}
            checks={checks}
            allowedMetrics={allowedMetrics}
          />
        </div>
      )}

      <div
        style={{
          width: "100%",
          height: "350px",
          marginLeft: "0",
          padding: "0",
        }}
      >
        <defs>
          {gradientMetric && (
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor={metricsColors[gradientMetric]}
                stopOpacity={0.3}
              />
              <stop
                offset="100%"
                stopColor={metricsColors[gradientMetric]}
                stopOpacity={0}
              />
            </linearGradient>
          )}
        </defs>

        <div
          style={{
            position: "relative",
            width: "100%",
            height: "350px",
            overflow: "visible",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData}
              margin={{
                top: 10,
                right: titles ? 5 : -10,
                left: titles ? 10 : -40,
                bottom: titles ? 60 : 0,
              }}
            >
              <CartesianGrid stroke="#1F1F20" />
              <XAxis
                dataKey="date"
                interval="preserveStartEnd"
                stroke="#7C7C78"
                padding={{ left: 0, right: 0 }}
              />
              <Tooltip
                content={({ active, payload, label }) => (
                  <CustomTooltip
                    active={active}
                    payload={payload}
                    colorMetric={metricsColors}
                    label={label}
                    customMapKeyToMetric={customMapKeyToMetric}
                  />
                )}
                cursor={{
                  fill: "transparent",
                  stroke: "white",
                  strokeDasharray: 4,
                }}
              />

              {!titles &&
                selectedMetrics.map((metricKey, index) => {
                  const yAxisId = yAxisIdMap[metricKey];
                  const orientation = index % 2 === 0 ? "left" : "right";
                  const tickColor = metricsColors[metricKey];
                  let tickFormatter;
                  if (
                    metricKey.toLowerCase().includes("cvr") ||
                    metricKey.toLowerCase().includes("ctr")
                  ) {
                    tickFormatter = (tick) => `${tick}%`;
                  } else if (
                    metricKey.toLowerCase().includes("sales") ||
                    metricKey.toLowerCase().includes("spend")
                  ) {
                    tickFormatter = (tick) => `$${formatThousands(tick)}`;
                  } else {
                    tickFormatter = formatThousands;
                  }
                  return (
                    <YAxis
                      key={yAxisId}
                      yAxisId={yAxisId}
                      orientation={orientation}
                      stroke={tickColor}
                      tickFormatter={tickFormatter}
                      axisLine={false}
                      tickLine={false}
                      tick={false}
                      domain={[
                        0,
                        (dataMax: number) => {
                          const max = Math.ceil(dataMax / 10) * 10;
                          return max < 10 ? 100 : max;
                        },
                      ]}
                      tickCount={11}
                    />
                  );
                })}

              {titles && (
                <>
                  <YAxis
                    yAxisId={yAxisIdSales}
                    orientation="left"
                    domain={avgPrice ? [0, 8] : avg ? [0, 1000] : [300, 2500]}
                    ticks={ticks}
                    tickFormatter={tickFormatter}
                    axisLine={true}
                    tickLine={true}
                  />
                  <YAxis
                    yAxisId={yAxisIdASIN}
                    orientation="right"
                    stroke={metricsColors.ASIN}
                    tickFormatter={formatThousands}
                    axisLine={false}
                    tickLine={false}
                    tick={false}
                  />
                </>
              )}

              {titles ? (
                <>
                  <Line
                    type="natural"
                    dataKey="salesSolid"
                    yAxisId={yAxisIdSales}
                    stroke={metricsColors.sales}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />

                  <Line
                    type="natural"
                    dataKey="marketShareSolid"
                    yAxisId={yAxisIdSales}
                    stroke={metricsColors.sales}
                    strokeWidth={2}
                    fill="url(#colorGradient)"
                    dot={false}
                    connectNulls
                  />

                  <Line
                    type="natural"
                    dataKey="marketShareDashed"
                    yAxisId={yAxisIdSales}
                    stroke={metricsColors.totalForecast}
                    strokeDasharray="5 5"
                    strokeWidth={2}
                    fill="url(#colorGradient)"
                    dot={false}
                    connectNulls
                  />

                  {!avg && (
                    <Line
                      type="natural"
                      dataKey="salesDashed"
                      yAxisId={yAxisIdSales}
                      stroke={metricsColors.totalForecast}
                      strokeDasharray="5 5"
                      strokeWidth={2}
                      dot={false}
                      connectNulls
                    />
                  )}
                  <Line
                    type="monotone"
                    dataKey="ASINSolid"
                    yAxisId={yAxisIdASIN}
                    stroke={metricsColors.ASIN}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />
                  {!avg && (
                    <Line
                      type="monotone"
                      dataKey="ASINDashed"
                      yAxisId={yAxisIdASIN}
                      stroke={metricsColors.ASINForecast}
                      strokeDasharray="5 5"
                      strokeWidth={2}
                      dot={false}
                      connectNulls
                    />
                  )}
                  <Line
                    type="monotone"
                    dataKey="AVGMarketPrice"
                    yAxisId={yAxisIdSales}
                    stroke={metricsColors.sales}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />
                  <Line
                    type="monotone"
                    dataKey="AVGASINPrice"
                    yAxisId={yAxisIdASIN}
                    stroke={metricsColors.ASIN}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />
                </>
              ) : (
                selectedMetrics.map((metricKey) => {
                  const yAxisId = yAxisIdMap[metricKey];
                  if (
                    metricKey === "totalForecast" ||
                    metricKey === "ASINForecast"
                  ) {
                    return (
                      <Line
                        key={metricKey}
                        type="monotone"
                        dataKey={metricKey}
                        yAxisId={yAxisId}
                        stroke={metricsColors[metricKey]}
                        strokeWidth={2}
                        dot={false}
                        strokeDasharray="5 5"
                        connectNulls
                      />
                    );
                  }
                  return (
                    <Line
                      key={metricKey}
                      type="monotone"
                      dataKey={metricKey}
                      yAxisId={yAxisId}
                      stroke={metricsColors[metricKey]}
                      strokeWidth={2}
                      dot={false}
                      connectNulls
                    />
                  );
                })
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
