import {
  FlowToExecutionPlanValidationError,
  FlowToExecutionPlan,
} from "@lib/executionPlan";
import { useReactFlow } from "@xyflow/react";
import { useCallback } from "react";
import { AppNodeMissingInputs, AppNode } from "src/types/workflow.types";
import useFlowValidation from "./useFlowValidation";

export const useExecutionPlan = () => {
  const { toObject } = useReactFlow();
  const { setInvalidInputs, clearErrors } = useFlowValidation();

  const handleError = useCallback(
    (error: {
      type: FlowToExecutionPlanValidationError;
      invalidElements?: AppNodeMissingInputs[];
    }) => {
      switch (error.type) {
        case FlowToExecutionPlanValidationError.NO_ENTRY_POINT:
          setInvalidInputs([]);
          // TODO: ADD TOAST
          break;
        case FlowToExecutionPlanValidationError.INVALID_INPUTS:
          setInvalidInputs(error.invalidElements || []);
          break;
        default:
          // TODO: ADD TOAST
          break;
      }
    },
    [setInvalidInputs],
  );

  const generateExecutionPlan = useCallback(() => {
    const { nodes, edges } = toObject();
    const { executionPlan, error } = FlowToExecutionPlan(
      nodes as AppNode[],
      edges,
    );
    if (error) {
      handleError(error);
      return null;
    }
    clearErrors();
    return executionPlan;
  }, [toObject, handleError, clearErrors]);

  return generateExecutionPlan;
};
