import { useState } from "react";
import styles from "./styles.module.scss";
import Tabs from "../../../Tabs";
import AdjuctIcon from "../../images/adjuct.svg?react";
import PresetsIcon from "../../images/presets.svg?react";
import PlusRoundIcon from "../../images/plus-round.svg?react";
import StarIcon from "../../images/star.svg?react";
import image from "../../images/image.jpg";
import bg1 from "./images/bg-1.jpg";
import bg2 from "./images/bg-2.jpg";
import bg3 from "./images/bg-3.jpg";
import bg4 from "./images/bg-4.jpg";
import bg5 from "./images/bg-5.jpg";

const bgList = [bg1, bg2, bg3, bg4, bg5];
const bestOption = bgList[0];

const ReplaceBackground = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [uploadedImages, setUploadedImage] = useState<string[]>([]);
  const [activeCheckboxIndex, setActiveCheckboxIndex] = useState(0);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files;

    const hasImage = Boolean(file);

    if (!hasImage) {
      return;
    }

    setUploadedImage([URL.createObjectURL(file), ...uploadedImages]);
    handleCheckboxChange(0);
  };

  const handleCheckboxChange = (index: number) => {
    setActiveCheckboxIndex(index);
  };

  return (
    <>
      <Tabs
        list={[
          <>
            <AdjuctIcon />
            Adjust
          </>,
          <>
            <PresetsIcon />
            Presets
          </>,
        ]}
        onChange={setActiveIndex}
        size="sm"
      />
      <div className={styles.workspace}>
        <div
          className={styles.workspaceImage}
          style={{ backgroundImage: `url(${image})` }}
        ></div>

        {activeIndex === 0 && (
          <>
            <div className={styles.checkboxBgs}>
              <label className={styles.checkboxBg}>
                <input
                  type="file"
                  className={styles.checkboxBgInput}
                  onChange={handleChange}
                  accept="image/png, image/gif, image/jpeg"
                  hidden
                />
                <div className={styles.checkboxUpload}>
                  <PlusRoundIcon />
                  Upload
                </div>
              </label>
              {[...uploadedImages, ...bgList].map((image, i) => {
                return (
                  <label className={styles.checkboxBg} key={image}>
                    <input
                      type="radio"
                      name="bg"
                      className={styles.checkboxBgInput}
                      checked={i === activeCheckboxIndex}
                      onChange={() => setActiveCheckboxIndex(i)}
                    />
                    <div className={styles.checkboxBgChecked}></div>
                    {bestOption === image && (
                      <div className={styles.checkboxBgLabel}>
                        <StarIcon />
                        Best option
                      </div>
                    )}
                    <div
                      className={styles.checkboxBgImage}
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                  </label>
                );
              })}
            </div>
          </>
        )}
      </div>
      <button className={"button button--sm button--m-0"}>Apply</button>
    </>
  );
};

export default ReplaceBackground;
