import React, { useState } from "react";
import classNames from "classnames";
import styles from "./DropdownSelect.module.scss";
import ChevronDown from "@assets/icons/chevron-down.tsx";

const defaultOptions = [
  "Age Group",
  "Education",
  "Gender",
  "Household Income",
  "Marital Status",
];

interface DropdownSelectProps {
  label?: string;
  options?: string[];
  defaultOption?: string;
  onSelect?: (value: string) => void;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  label = "Demographic Type",
  options = defaultOptions,
  defaultOption = "Age Group",
  onSelect = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownWrapper}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
      </div>
      <div
        className={classNames(styles.selectBox, { [styles.open]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption}</span>
        <div className={classNames(styles.arrow, { [styles.active]: isOpen })}>
          {" "}
          <ChevronDown />
        </div>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {options.map((option, index) => (
            <div
              key={index}
              className={classNames(styles.dropdownItem, {
                [styles.selected]: option === selectedOption,
              })}
              onClick={() => handleSelect(option)}
            >
              <span>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;
