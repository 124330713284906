import { useEffect, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Legend } from "@pages/Dashboard/features/SalesPerformance/Legend";
import { Chart } from "@pages/Dashboard/features/SalesPerformance/Chart";
import MetricModal from "./MetricModal";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { initialChecks } from "./types";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { Spin } from "antd";
import { useIsMobile } from "src/utils/isMobile";

export const COLORS = [
  "#80C67A",
  "#1BB08D",
  "#fc6762",
  "#3a3b7c",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
];

export const SalesPerformance = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const [metricsColors, setMetricsColors] = useState<{
    [key: string]: string;
  }>({});
  const [checks, setChecks] = useState(initialChecks);
  const [data, setData] = useState<any>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      const data = await getData(dateRange, checks);
      const transformedData = transformDataToObject(data);
      setData(transformedData);
      setIsDataLoading(false);
    };

    if (Object.values(checks).some((value) => value)) {
      fetchData();
    }
  }, [dateRange, checks]);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const colorMapping: { [key: string]: string } = {};
    Object.keys(checks).forEach((check, index) => {
      colorMapping[check] = COLORS[index % COLORS.length];
    });
    setMetricsColors(colorMapping);
  }, [checks]);

  return (
    <div className="sales-performance">
      <header className="sales-performance__header">
        <h3 className="sales-performance__title heading-2">
          {t("label.sales_performance")}
        </h3>
      </header>

      <div className="sales-performance__body">
        {!isMobile && (
          <Legend
            handleOpenModal={handleOpenModal}
            checks={checks}
            metricsColors={metricsColors}
          />
        )}
        {!isDataLoading ? (
          <Chart
            checks={checks}
            metricsColors={metricsColors}
            data={data}
            isMobile={isMobile}
          />
        ) : (
          <div className={"sales-performance__loader"}>
            <Spin size="large" />
          </div>
        )}
        {isMobile && (
          <Legend
            handleOpenModal={handleOpenModal}
            checks={checks}
            metricsColors={metricsColors}
          />
        )}
        <MetricModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setChecks={setChecks}
          checks={checks}
        />
      </div>
    </div>
  );
};
