import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import bg from "src/assets/img/canvas_bg.png";
import { useCanvaStore } from "src/store/canva/canva.state";
import { GridStack, GridStackNode } from "gridstack";
import "./gridstack-custom.scss";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import classNames from "classnames";
import ScrollContainer from "react-indiana-drag-scroll";
import { renderElement } from "./utils";
import Dots from "@assets/icons/Dots";
import CanvasEmpty from "@assets/icons/canvasEmpty";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { useSectionStore } from "src/store/croModule.state";
import { ELEMENT_ID } from "src/store/canva/element-id";

export const changeElementSize = (
  id: string,
  size: { width?: number; height?: number },
) => {
  const element = document.querySelector(`[data-id='${id}']`);
  if (element) {
    if (size.width) element.setAttribute("gs-w", size.width.toString());
    if (size.height) element.setAttribute("gs-h", size.height.toString());
  }
};

export const Canva: React.FC = () => {
  const { elements, asin, updateElementPosition, isWhatIf, setIsWhatIf } =
    useCanvaStore();
  const { setIsWhatIfEnabled: setAnaliticsWhatIf } = useAdsAnaliticsStore();
  const { changeWhatIf: setCroWhatIf } = useSectionStore();
  const gridRef = useRef<GridStack | null>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [zoom, setZoom] = useState(0.2);

  useEffect(() => {
    if (gridContainerRef.current) {
      const grid = GridStack.init(
        {
          float: true,
          cellHeight: "10px",
          column: 48,
          draggable: {
            handle: ".drag-handle",
            appendTo: "body",
            scroll: true,
          },
          margin: 0,
          disableResize: true,
        },
        gridContainerRef.current,
      );

      grid.on("change", function (_: Event, items: GridStackNode[]) {
        items.forEach((item) => {
          if (item.el) {
            // Keep original position if move was triggered by new item
            const id = item.el.getAttribute("data-id") || "";
            const x = item.x || 0;
            const y = item.y || 0;
            updateElementPosition(id, x, y);
          }
        });
      });

      gridRef.current = grid;

      return () => {
        grid.destroy();
        gridRef.current = null;
      };
    }
  }, []);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        setZoom((prevZoom) => {
          const delta = -e.deltaY * 0.0005;
          const newZoom = prevZoom + delta;
          return Math.min(Math.max(newZoom, 0.2), 2);
        });
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useLayoutEffect(() => {
    const grid = gridRef.current;
    if (!grid) return;
    setTimeout(() => {
      const gridItems = grid.engine.nodes.map((node) =>
        node.el?.getAttribute("data-id"),
      );

      elements.forEach((element) => {
        if (!gridItems.includes(element.id)) {
          const el = document.getElementById(`grid-item-${element.id}`);
          if (el) {
            grid.makeWidget(el);
          }
        }
      });
    }, 0);
  }, [elements]);

  const handleZoomIn = () =>
    setZoom((prevZoom) => Math.min(prevZoom + 0.05, 2));
  const handleZoomOut = () =>
    setZoom((prevZoom) => Math.max(prevZoom - 0.05, 0.2));

  const handleDoubleClick = (elementId: string) => {
    const element = document.getElementById(`grid-item-${elementId}`);
    const container = document.querySelector(".canva");
    const gridContainer = document.querySelector(".grid-stack");

    if (element && container && gridContainer) {
      const zoomLevel = 0.7;
      setZoom(zoomLevel);

      setTimeout(() => {
        const containerRect = container.getBoundingClientRect();

        const elementOffsetLeft = element.offsetLeft;
        const elementOffsetTop = element.offsetTop;

        const elementCenterX =
          (elementOffsetLeft + element.offsetWidth / 2) * zoomLevel;
        const elementCenterY =
          (elementOffsetTop + element.offsetHeight / 2) * zoomLevel;

        const scrollX = elementCenterX - containerRect.width / 2;
        const scrollY = elementCenterY - containerRect.height / 2;

        container.scrollTo({
          left: scrollX,
          top: scrollY,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  const handleWhatIf = (whatIf: boolean) => {
    [setCroWhatIf, setAnaliticsWhatIf, setIsWhatIf].forEach((fn) => fn(whatIf));
  };

  return (
    <div className={styles.container}>
      <div className={styles.zoom}>
        <button className={styles.zoom__button} onClick={handleZoomOut}>
          -
        </button>
        <span>{Math.round(zoom * 100)}%</span>
        <button className={styles.zoom__button} onClick={handleZoomIn}>
          +
        </button>
      </div>
      <div className={styles.whatIf}>
        <button
          className={classNames(styles.whatIf__button, {
            [styles.whatIf__button_active]: isWhatIf,
          })}
          onClick={() => handleWhatIf(true)}
        >
          What If
        </button>
        <button
          className={classNames(styles.whatIf__button, {
            [styles.whatIf__button_active]: !isWhatIf,
          })}
          onClick={() => handleWhatIf(false)}
        >
          As Is
        </button>
      </div>
      {elements.length === 0 && (
        <div className={styles.empty}>
          <CanvasEmpty />
          <span>Add elements by clicking “+”</span>
        </div>
      )}
      <ScrollContainer
        className={classNames(styles.area, "canva")}
        style={{
          backgroundImage: `url(${bg})`,
        }}
        ignoreElements=".grid-stack-item"
        vertical
        horizontal
        hideScrollbars={false}
      >
        <div
          className={classNames(styles.canva__container, "grid-stack")}
          ref={gridContainerRef}
          style={{
            transform: `scale3D(${zoom}, ${zoom}, 1)`,
            transformOrigin: "0 0 0",
            transition: "transform 0.1s",
          }}
        >
          {elements.length > 0 &&
            elements.map((element) => {
              return (
                <div
                  key={element.id}
                  id={`grid-item-${element.id}`}
                  className={classNames(styles.gridItem, "grid-stack-item")}
                  data-id={element.id}
                  data-gs-id={element.id}
                  gs-y={element.y}
                  gs-x={element.x}
                  gs-w={element.size?.width}
                  gs-h={element.size?.height}
                  onDoubleClick={() => handleDoubleClick(element.id)}
                >
                  <div
                    className={classNames(
                      styles.item,
                      "grid-stack-item-content",
                    )}
                    style={
                      element.elementId === ELEMENT_ID.EXECUTIVE_SUMMARY
                        ? {
                            overflowY: "visible",
                            overflowX: "visible",
                          }
                        : {}
                    }
                  >
                    <div className={classNames(styles.icon, "drag-handle")}>
                      <Dots />
                    </div>
                    {renderElement(
                      element.elementId,
                      asin,
                      isWhatIf,
                      element.id,
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </ScrollContainer>
    </div>
  );
};
