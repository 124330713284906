import { Card } from "antd";
import styles from "./Scenario.module.scss";
import { data } from "./utils";

const ScenarioTable = () => {
  return (
    <div className={styles.scenarioContainer}>
      {data.map((scenario, index) => (
        <Card key={index} className={styles.card} bordered={false}>
          <h1 className={styles.cardTitle}>{scenario.title}</h1>
          {scenario.rows.map((row, rowIndex) => (
            <div key={rowIndex} className={styles.row}>
              <div className={styles.labelContainerRow}>
                <div className={styles.labelContainer}>
                  <h2 className={styles.label}>{row.label}</h2>
                </div>
                <div className={styles.valueContainer}>
                  <p className={row.red ? styles.redValue : styles.value}>
                    {row.value}
                  </p>
                </div>
              </div>
              {row.children && (
                <div className={styles.childrenContainer}>
                  {row.children.map((child, childIndex) => (
                    <div key={childIndex} className={styles.rowChild}>
                      <div className={styles.labelContainer}>
                        <h2 className={styles.childLabel}>{child.label}</h2>
                      </div>
                      <div className={styles.valueContainer}>
                        <p
                          className={
                            child.red ? styles.redValueChild : styles.valueChild
                          }
                        >
                          {child.value}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Card>
      ))}
    </div>
  );
};

export default ScenarioTable;
