export const data = [
  {
    title: "Total",
    color: "#FF5A5A",
    metrics: [
      { label: "Impressions", value: "3,713,068" },
      { label: "Clicks", value: "3,713,068" },
      { label: "Add to cart", value: "561,311", blockColor: "#FF5A5A" },
      { label: "Units Sold", value: "81,367", blockColor: "#FF5A5A" },
      { label: "", valueEnd: "2.2%" },
    ],
  },
  {
    title: "ASIN",
    color: "#5295E0",
    metrics: [
      { label: "Impressions", value: "10,398" },
      { label: "Clicks", value: "10,398" },
      {
        label: "Add to cart",
        value: "1,159",
        blockColor: "#5295E0",
        leftValue: "1,159",
      },
      {
        label: "Units Sold",
        value: "202",
        blockColor: "#5295E0",
        leftValue: "202",
      },
      { label: "", valueEnd: "1.9%" },
    ],
  },
];
