import { Typography } from "antd";
const { Text } = Typography;

export const columns = [
  {
    title: "BRAND",
    dataIndex: "brand",
    key: "brand",
    width: 150,
  },
  {
    title: "SALES",
    dataIndex: "sales",
    key: "sales",
    width: 150,
  },
  {
    title: "BUDGET",
    dataIndex: "budget",
    key: "budget",
    width: 150,
  },
  {
    title: "DELTA",
    dataIndex: "delta",
    key: "delta",
    width: 150,
  },
  {
    title: "DELTA,%",
    dataIndex: "deltaPercent",
    key: "deltaPercent",
    className: "delta-header",
    width: 150,
    render: (text) => <span className="delta-cell">{text}</span>,
  },
  {
    title: "BUYBOX",
    dataIndex: "buybox",
    key: "buybox",
    width: 150,
    render: (text) => (
      <div>
        <Text style={{ color: "#8AFF6C" }}>{text}</Text>
      </div>
    ),
  },
  {
    title: "SALES PREVIOUS PERIOD",
    dataIndex: "salesPrev",
    key: "salesPrev",
    width: 200,
  },
  {
    title: "BUDGET PREVIOUS PERIOD",
    dataIndex: "budgetPrev",
    key: "budgetPrev",
    width: 200,
  },
  {
    title: "BUYBOX PREVIOUS PERIOD",
    dataIndex: "buyboxPrev",
    key: "buyboxPrev",
    width: 200,
    render: (text) => <Text style={{ color: "#8AFF6C" }}>{text}</Text>,
  },
];

export const totalData = [
  {
    key: "total",
    brand: "TOTAL:",
    sales: "$5,688,529",
    budget: "$1,488,060",
    delta: "$4,200,469",
    deltaPercent: "74%",
    buybox: "86%",
    salesPrev: "$365,795",
    budgetPrev: "$413,350",
    buyboxPrev: "86%",
  },
];
