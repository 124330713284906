import React from "react";
import { Modal, Table, Row, Col } from "antd";
import "./OrdersModal.css";
import { columns, dataSource, totalOrders } from "./utils";

interface OrdersModalProps {
  isOpen: boolean;
  onClose: () => void;
  order: any;
}

const OrdersModal: React.FC<OrdersModalProps> = ({
  isOpen,
  onClose,
  order,
}) => {
  return (
    <Modal
      title="Orders"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={600}
      className="ordersModal"
    >
      <h1 className="orderHeader">Orders</h1>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="orderInfo">
            <p>
              PO: <strong>783821</strong>
            </p>
            <p>
              Amount: <strong>$XXX</strong>
            </p>
            <p>
              Deadline date: <strong>01.01.2024</strong>
            </p>
            <p>
              COGS: <strong>$XXX</strong>
            </p>
          </div>
        </Col>
        <Col span={12}>
          <div className="orderInfo">
            <p>
              SO: <strong>892013</strong>
            </p>
            <p>
              Amount: <strong>$XXX</strong>
            </p>
            <p>
              Deadline date: <strong>05.01.2024</strong>
            </p>
            <p>
              Transport Fee: <strong>$XXX</strong>
            </p>
          </div>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        footer={() => (
          <p className="footer">
            Total: <span>{totalOrders}</span>
          </p>
        )}
        className="ordersTable"
      />
    </Modal>
  );
};

export default OrdersModal;
