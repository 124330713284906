import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ExpandableTable } from "@components/ExpandebleTable";
import { columns, data } from "./mock";

export const ProductPlacementTable: React.FC = () => {
  const [selections, setSelections] = useState<any[]>([]);

  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  return (
    <div className={styles.container} id={styles.table}>
      <h3>Product Placement</h3>
      <ExpandableTable
        columns={columns}
        data={data}
        withCustomScroll
        rowSelection={rowSelections}
        className={styles.table}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};
