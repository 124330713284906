import { Node } from "@xyflow/react";
import { LucideProps } from "lucide-react";

export enum TaskType {
  LAUNCH_BROWSER = "LAUNCH_BROWSER",
  PAGE_TO_HTML = "PAGE_TO_HTML",
  EXTRACT_TEXT_FROM_ELEMENT = "EXTRACT_TEXT_FROM_ELEMENT",
}

export enum TaskParamType {
  STRING = "STRING",
  BROWSER_INSTANCE = "BROWSER_INSTANCE",
}
export interface ParamProps {
  param: TaskParam;
  value: string;
  updateNodeParamValue: (newValue: string) => void;
  disabled?: boolean;
}
export interface TaskParam {
  name: string;
  type: TaskParamType;
  helperText?: string;
  required?: boolean;
  hideHandle?: boolean;
  value?: string;
  [key: string]: any;
}

export interface AppNodeData {
  type: TaskType;
  inputs: Record<string, string>;
  [key: string]: any;
}

export interface AppNode extends Node {
  data: AppNodeData;
}

export type WorkflowTask = {
  label: string;
  icon: React.FC<LucideProps>;
  type: TaskType;
  isEntryPoint?: boolean;
  inputs: TaskParam[];
  outputs: TaskParam[];
  credits: number;
};

export type WorkflowExecutionPlanPhase = {
  phase: number;
  nodes: AppNode[];
};
export type WorkflowExecutionPlan = WorkflowExecutionPlanPhase[];

export type AppNodeMissingInputs = {
  nodeId: string;
  inputs: string[];
};

export type Environment = {
  // Phases with nodeId/taskId as key
  phases: Record<
    string, // key: nodeId/taskId
    {
      inputs: Record<string, string>;
      outputs: Record<string, string>;
    }
  >;
};

export type ExecutionEnvironment<T extends WorkflowTask> = {
  getInput(name: T["inputs"][number]["name"]): string;
  setOutput(name: T["outputs"][number]["name"], value: string): void;
};

export type ExecutionPhase = {
  id: string;
  userId: string;
  status: ExecutionPhaseStatus;
  number: number;
  node: string;
  name: string;
  startedAt: Date;
  completedAt: Date;
  inputs: string;
  outputs: string;
  creditsConsumed: number;
  workflowExecutionId: string;
};

export type ExecutionLog = {
  id: string;
  executionPhaseId: string;
  logLevel: string;
  message: string;
  timestamp: Date;
};
export enum WorkflowExecutionStatus {
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
  CANCELED = "CANCELED",
}

export enum ExecutionPhaseStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
  CANCELED = "CANCELED",
}
