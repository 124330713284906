import { useState } from "react";
import { Select, SelectProps } from "@components/Select";

const ControlledSelect = <T extends string | string[]>(
  props: Omit<SelectProps<T>, "onChange" | "value"> &
    Partial<Pick<SelectProps<T>, "onChange" | "value">>,
) => {
  const [value, setValue] = useState<string | string[]>(props.value);

  return <Select {...props} value={value} onChange={(val) => setValue(val)} />;
};

export default ControlledSelect;
