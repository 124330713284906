import classNames from "classnames";
import styles from "./styles.module.scss";

interface InputProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  icon?: React.ReactNode;
  label?: string;
  className?: string;
  size?: "small" | "default" | "large";
  paddingSize?: "normal" | "dense";
  type?: "text" | "number";
}

export const Input = ({
  value,
  placeholder,
  onChange,
  icon,
  label,
  className,
  size = "default",
  paddingSize = "normal",
  type = "text",
}: InputProps) => {
  return (
    <div
      className={classNames(
        styles.container,
        className,
        styles[size],
        styles[paddingSize],
      )}
    >
      {label && <label>{label}</label>}
      <div className={styles.input}>
        <span className={styles.icon}>{icon}</span>
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          className={type === "number" ? styles.noSpin : ""}
        />
      </div>
    </div>
  );
};
