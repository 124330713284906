import editorSvg from "./images/editor.svg";
import replaceSvg from "./images/replace.svg";
import eraserSvg from "./images/eraser.svg";
import upscaleSvg from "./images/upscale.svg";
import { useState } from "react";
import ControlledSelect from "../ControlledSelect";
import PhotoEditor from "./components/PhotoEditor";
import BackButton from "./components/BackButton";
import ReplaceBackground from "./components/ReplaceBackground";
import Nav from "../Nav";
import Eraser from "./components/Eraser";
import UpscalePhoto from "./components/UpscalePhoto";
import classNames from "classnames";
import styles from "./styles.module.scss";

const tabList = [
  {
    label: "Photo editor",
    icon: editorSvg,
  },
  {
    label: "Replace background",
    icon: replaceSvg,
  },
  {
    label: "AI eraser",
    icon: eraserSvg,
  },
  {
    label: "Upscale photo",
    icon: upscaleSvg,
  },
];

const SettingsTab = () => {
  const [activeIndex, setActiveIndex] = useState(3);

  return (
    <>
      {activeIndex === -1 && (
        <>
          <Nav items={tabList} onChange={setActiveIndex} />
          <div className="input input--sm">
            <div className="input__label">Download</div>
            <div className="input__row">
              <ControlledSelect
                placeholder="Select value"
                options={["1X", "2X"]}
                value={"1X"}
              />
              <ControlledSelect
                placeholder="Select value"
                options={["PNG", "JPG", "WEBP"]}
                value={"PNG"}
              />
            </div>
          </div>
          <button
            className={classNames(
              "button--sm button--m-0",
              styles.btnCro,
              styles.primary,
            )}
          >
            Download Image
          </button>
        </>
      )}
      {activeIndex !== -1 && (
        <BackButton
          onClick={() => setActiveIndex(-1)}
          label={tabList[activeIndex].label}
        />
      )}
      {activeIndex === 0 && <PhotoEditor />}
      {activeIndex === 1 && <ReplaceBackground />}
      {activeIndex === 2 && <Eraser />}
      {activeIndex === 3 && <UpscalePhoto />}
    </>
  );
};

export default SettingsTab;
