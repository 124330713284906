import { CoinsIcon, CopyIcon, GripVerticalIcon, TrashIcon } from "lucide-react";
import { useReactFlow } from "@xyflow/react";
import { Badge } from "../Badge";
import { AppNode, TaskType } from "src/types/workflow.types";
import { TaskRegistry } from "src/constants/workflow.constants";
import { CreateFlowNode } from "../CreateFlowNode";
import styles from "./nodeHeader.module.scss";

export const NodeHeader = ({
  taskType,
  nodeId,
}: {
  taskType: TaskType;
  nodeId: string;
}) => {
  const task = TaskRegistry[taskType];
  const { deleteElements, getNode, addNodes } = useReactFlow();
  return (
    <div className={styles.nodeHeader}>
      <task.icon size={16} />
      <div className={styles.headerContent}>
        <p className={styles.label}>{task.label}</p>
        <div className={styles.badgeContainer}>
          {task.isEntryPoint && <Badge>Entry Point</Badge>}
          <Badge className={styles.badge}>
            <CoinsIcon size={16} />
            {task.credits}
          </Badge>
          {!task.isEntryPoint && (
            <>
              <button
                onClick={() =>
                  deleteElements({
                    nodes: [{ id: nodeId }],
                  })
                }
              >
                <TrashIcon size={16} />
              </button>
              <button
                onClick={() => {
                  const node = getNode(nodeId) as AppNode;
                  const newX = node.position.x;
                  const newY =
                    node.position.y + (node.measured?.height ?? 0) + 20;

                  const newNode = CreateFlowNode(node.data.type, {
                    x: newX,
                    y: newY,
                  });
                  addNodes([newNode]);
                }}
              >
                <CopyIcon size={16} />
              </button>
            </>
          )}

          <button className="drag-handle cursor-grab">
            <GripVerticalIcon size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};
