import { Handle, Position } from "@xyflow/react";
import cn from "classnames";
import { TaskParam } from "src/types/workflow.types";
import { ColorForHandle } from "../common";
import styles from "./nodeOutputs.module.scss";
import { FC } from "react";

export interface NodeOutputsProps {
  children: React.ReactNode;
}

export interface NodeOutputProps {
  output: TaskParam;
}
export const NodeOutputs: FC<NodeOutputsProps> = ({ children }) => (
  <div className={styles["node-outputs"]}>{children}</div>
);
export const NodeOutput: FC<NodeOutputProps> = ({ output }) => (
  <div className={styles["node-output"]}>
    <p className={styles["node-output__name"]}>{output.name}</p>
    <Handle
      id={output.name}
      type="source"
      position={Position.Right}
      className={cn(styles["node-output__handle"], ColorForHandle[output.type])}
    />
  </div>
);
