import step1Video from "@assets/img/photoOptimisation/guide/Canvas-Step1.mp4";
import step2Video from "@assets/img/photoOptimisation/guide/Canvas-Step2.mp4";
import step3Video from "@assets/img/photoOptimisation/guide/Canvas-Step3.mp4";
import step4Video from "@assets/img/photoOptimisation/guide/Canvas-Step4.mp4";

export const steps = [
  {
    step: "Image selection",
    description: [
      "Select image or upload new to extract mask for editing.",
      "Drag, resize, and rotate your mask in the workspace.",
    ],
    video: step1Video,
  },
  {
    step: "AI Templates",
    description: [
      "Select image or upload new to extract mask for editing.",
      "Drag, resize, and rotate your mask in the workspace.",
    ],
    video: step2Video,
  },
  {
    step: "Prompt generator",
    description: [
      "Select image or upload new to extract mask for editing.",
      "Drag, resize, and rotate your mask in the workspace.",
    ],
    video: step3Video,
  },
  {
    step: "Adding assets",
    description: [
      "Select image or upload new to extract mask for editing.",
      "Drag, resize, and rotate your mask in the workspace.",
    ],
    video: step4Video,
  },
];
