import { Table } from "antd";
import "./HoverTable.css";
import { columns, dataSource } from "./utils";

const HoverTable = () => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      bordered={false}
      className="hover-table"
      summary={() => (
        <Table.Summary>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} className="total-cell">
              TOTAL
            </Table.Summary.Cell>
            <Table.Summary.Cell
              index={1}
              className="total-cell"
            ></Table.Summary.Cell>
            <Table.Summary.Cell index={2} className="total-cell">
              140
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} className="total-cell">
              120
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} className="total-cell">
              50
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    />
  );
};

export default HoverTable;
