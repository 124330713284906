import React, { useState } from "react";
import { Table, Popover } from "antd";
import "./PoSalesTable.css";
import { data } from "./utils";
import HoverTable from "@components/HoverTable";

const PoSalesTable = () => {
  const [activeButton, setActiveButton] = useState("Supplier");
  const [hoveredCol, setHoveredCol] = useState(null);

  const dynamicColumns = [
    {
      title: "Seller KPU",
      dataIndex: "sellerKPU",
      key: "sellerKPU",
      fixed: "left",
      width: 150,
      className: "seller-kpu-cell",

      onHeaderCell: (col) => ({
        onMouseEnter: () => setHoveredCol(col.key),
        onMouseLeave: () => setHoveredCol(null),
        style: { color: hoveredCol === col.key ? "#ffffff" : "#9b9da2" },
      }),
      render: (value) => (
        <div
          onMouseEnter={() => setHoveredCol("sellerKPU")}
          onMouseLeave={() => setHoveredCol(null)}
        >
          {value}
        </div>
      ),
    },
    ...Array.from({ length: 12 }, (_, i) => {
      const colKey = `month${i + 1}`;
      return {
        title: new Date(2021, i).toLocaleString("en-US", {
          month: "short",
          year: "numeric",
        }),
        dataIndex: colKey,
        key: colKey,
        onHeaderCell: (col) => ({
          onMouseEnter: () => setHoveredCol(col.key),
          onMouseLeave: () => setHoveredCol(null),

          style: { color: hoveredCol === col.key ? "#ffffff" : "#9b9da2" },
        }),
        render: (value) => {
          let style = {};
          let cellType = "";

          if (typeof value === "number") {
            if (value <= 31) {
              style = {
                background: "#E4595040",
                border: "1px solid #E45950",
                color: "#FFFFFF",
              };
              cellType = "red";
            } else if (value <= 120) {
              style = {
                background: "#78C36E40",
                border: "1px solid #78C36E",
                color: "#FFFFFF",
              };
              cellType = "green";
            } else {
              style = {
                background: "#F4CA1340",
                border: "1px solid #EAC008",
                color: "#FFFFFF",
              };
              cellType = "yellow";
            }
          } else {
            style = {
              background: "#E4595040",
              border: "1px solid #E45950",
              color: "#FFFFFF",
            };
            cellType = "red";
          }

          return (
            <Popover content={<HoverTable />} placement="right" trigger="hover">
              <div
                className={`cell ${cellType}`}
                style={style}
                onMouseEnter={() => setHoveredCol(colKey)}
                onMouseLeave={() => setHoveredCol(null)}
              >
                {value}
              </div>
            </Popover>
          );
        },
      };
    }),
  ];

  return (
    <div className="containerPoSales">
      <div className="header">
        <h2 className="PoTitle">PO Sales, Margin & ROI Dynamics</h2>
        <div className="buttons">
          <button
            className={`button ${activeButton === "Supplier" ? "active" : ""}`}
            onClick={() => setActiveButton("Supplier")}
          >
            Supplier
          </button>
          <button
            className={`button ${activeButton === "3PL" ? "active" : ""}`}
            onClick={() => setActiveButton("3PL")}
          >
            3PL
          </button>
          <button
            className={`button ${
              activeButton === "Fulfillment Center" ? "active" : ""
            }`}
            onClick={() => setActiveButton("Fulfillment Center")}
          >
            Fulfillment Center
          </button>
        </div>
      </div>
      <Table
        columns={dynamicColumns}
        dataSource={data}
        bordered={false}
        pagination={false}
        scroll={{ x: "100%" }}
      />
    </div>
  );
};

export default PoSalesTable;
