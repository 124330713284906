export const profiles = [
  {
    title: "The 1st best targeting profile",
    data: [
      { label: "Age Group", value: "45-54", number: "3,173" },
      { label: "Education", value: "Bachelors Degree", number: "4,657" },
      { label: "Gender", value: "Female", number: "9,547" },
      { label: "Household Income", value: "$250,00 or more", number: "2,839" },
      { label: "Marital Status", value: "Married", number: "8,442" },
    ],
  },
  {
    title: "The 2st best targeting profile",
    data: [
      { label: "Age Group", value: "55-64", number: "2,755" },
      { label: "Education", value: "Some College", number: "3,719" },
      { label: "Gender", value: "Male", number: "4,592" },
      {
        label: "Household Income",
        value: "$75,000 to $99,999",
        number: "1,762",
      },
      { label: "Marital Status", value: "Single", number: "1,294" },
    ],
  },
];
