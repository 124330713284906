import React from "react";
import { Popover, Button } from "antd";
import { steps } from "./mock";
import styles from "./styles.module.scss";
import { handleStepChange } from "../../utils";

const UserGuidePopover = ({
  placement,
  step,
  children,
  text,
  setCurrUserStepGuide,
  additionalHandlers,
  nextStep,
  currUserStepGuide,
  setUserGuide,
}: {
  placement:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  step: number;
  text: number;
  children: React.ReactNode;
  setCurrUserStepGuide?: (value: number) => void;
  additionalHandlers?: Record<string, (value: any) => void>;
  nextStep?: number;
  currUserStepGuide?: number;
  setUserGuide?: (value: boolean) => void;
}) => {
  const handleNext = () => {
    const targetStep = nextStep !== undefined ? nextStep : step + 1;
    handleStepChange(targetStep, setCurrUserStepGuide, additionalHandlers);
  };

  const handleClose = () => {
    if (setUserGuide) {
      setUserGuide(false);
    }
  };

  const content = (
    <div className={styles.popoverContent}>
      <h3>{steps[step]?.title || "Step not found"}</h3>
      <p>{steps[step]?.subparagraphs[text] || "Description not found"}</p>
      {currUserStepGuide === 4 ? (
        ""
      ) : currUserStepGuide === 7 ? (
        <Button type="primary" onClick={handleNext}>
          Start creating
        </Button>
      ) : (
        <div className="popover-row">
          <Button type="primary" className="close" onClick={handleClose}>
            Close guide
          </Button>
          <Button type="primary" onClick={handleNext}>
            Next
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Popover
      content={content}
      visible={true}
      placement={placement}
      overlayClassName={styles.customPopover}
      overlayInnerStyle={{
        padding: 0,
        backgroundColor: "#2e2e2e",
        borderRadius: 8,
      }}
    >
      {children}
    </Popover>
  );
};

export default UserGuidePopover;
