import styles from "./styles.module.scss";
import { FlowValidationContextProvider } from "@contexts/FlowContext";
import { ReactFlowProvider } from "@xyflow/react";
import { Topbar } from "./Topbar";
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from "@components/Resizable";
import { TaskMenu } from "./TaskMenu";
import { TooltipProvider } from "./Tooltip";
import { FlowEditor } from "./FlowEditor";

export const Workflows: React.FC = () => {
  return (
    <TooltipProvider delayDuration={0}>
      <FlowValidationContextProvider>
        <ReactFlowProvider>
          <div className={styles.flexCol}>
            <Topbar />
            <section className={styles.flexLayout}>
              <ResizablePanelGroup direction="horizontal">
                <ResizablePanel>
                  <TaskMenu />
                </ResizablePanel>
                <ResizableHandle withHandle />
                <ResizablePanel>
                  <FlowEditor />
                </ResizablePanel>
              </ResizablePanelGroup>
            </section>
          </div>
        </ReactFlowProvider>
      </FlowValidationContextProvider>
    </TooltipProvider>
  );
};
