import { Table } from "antd";
import "./CustomTableStyles.css";
import { columns, totalData } from "./utils";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import { useBrandStore } from "src/store/brand";
import { useEffect, useMemo, useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const Brand = () => {
  const { brands, setBrands } = useBrandStore();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [expandedAsinsMap, setExpandedAsinsMap] = useState<{
    [key: string]: string[];
  }>({});

  const fetchBrands = async () => {
    const spider = new CategoriesService();
    const spidersData = await spider.getBrands();
    setBrands(spidersData);
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const toggleRow = (key: string, asins: string[]) => {
    setExpandedRows((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key],
    );
    setExpandedAsinsMap((prev) => {
      if (prev[key]) {
        const newMap = { ...prev };
        delete newMap[key];
        return newMap;
      } else {
        return { ...prev, [key]: asins };
      }
    });
  };

  const dynamicData = useMemo(() => {
    return (brands || []).flatMap((brandItem, index) => {
      const key = String(index + 1);
      const isExpanded = expandedRows.includes(key);
      const currentAsins = expandedAsinsMap[key] || [];

      return [
        {
          key,
          brand: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              onClick={() => toggleRow(key, brandItem.asins)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  border: "2px solid #5295E0",
                  color: "#5295E0",
                  cursor: "pointer",
                }}
              >
                {isExpanded ? <MinusOutlined /> : <PlusOutlined />}
              </div>
              <span className="title-brand">{brandItem.name}</span>
            </div>
          ),
          sales: "$1,054,697",
          budget: "$1,488,060",
          delta: "$1,054,697",
          deltaPercent: "100%",
          buybox: "85%",
          salesPrev: "$57,585",
          budgetPrev: "-",
          buyboxPrev: "84%",
        },
        ...(isExpanded
          ? [
              {
                key: `${key}-expanded`,
                brand: (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "0",
                    }}
                  >
                    {currentAsins.map((asin) => (
                      <div
                        key={asin}
                        style={{
                          borderBottom: "1px solid #ddd",
                          margin: "4px",
                        }}
                      >
                        {asin}
                      </div>
                    ))}
                  </div>
                ),
                sales: "",
                budget: "",
                delta: "",
                deltaPercent: "",
                buybox: "",
                salesPrev: "",
                budgetPrev: "",
                buyboxPrev: "",
              },
            ]
          : []),
      ];
    });
  }, [brands, expandedRows, expandedAsinsMap]);

  return (
    <div className="brand-container">
      <Table
        columns={columns}
        dataSource={dynamicData}
        pagination={false}
        className="custom-table custom-delta-table"
      />

      <Table
        columns={columns}
        dataSource={totalData}
        pagination={false}
        showHeader={false}
        className="custom-table custom-delta-table total"
      />
    </div>
  );
};

export default Brand;
