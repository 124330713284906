import { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

const Tabs = ({
  list,
  onChange,
  size = "md",
}: {
  list: (React.ReactNode | string)[];
  onChange?: (index: number) => void;
  size?: "md" | "sm";
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index: number) => {
    onChange(index);
    setActiveIndex(index);
  };

  return (
    <div className={classNames(styles.tabs, styles["tabs" + size])}>
      {list.map((text, i) => (
        <button
          key={i}
          className={classNames(
            styles.tabs__button,
            i === activeIndex && styles.active,
          )}
          onClick={() => handleClick(i)}
        >
          {text}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
