import InputRange from "../../../InputRange";
import BackIcon from "../../images/back.svg?react";
import styles from "./styles.module.scss";

const Eraser = () => {
  return (
    <>
      <div className={styles.workspace}>
        <div className={styles.actions}>
          <button className={styles.action}>
            <BackIcon className={styles.actionBack} />
          </button>
          <button className={styles.action}>
            <BackIcon className={styles.actionFront} />
          </button>
        </div>
        <InputRange label={"Brush size"} min={1} />
      </div>
      <button className={"button button--sm button--m-0"}>Apply</button>
    </>
  );
};

export default Eraser;
