import { WhatIfTab } from "./Tabs";
import {
  adTypeColumns,
  campaignsColumns,
  funnelColumns,
  matchColumns,
  performanceColumns,
  targetingColumns,
  targetTypeColumns,
} from "../../mock";
import { IPerformanceTableData } from "@services/canvas/canvas.api";

export const getSelectData = (
  sankeyTab: WhatIfTab,
  performanceData: IPerformanceTableData[],
) => {
  let data;
  let colName;
  switch (sankeyTab) {
    case "Campaigns":
      data = performanceData;
      colName = "campaign";
      break;
    case "Ad Type":
      data = performanceData;
      break;
    case "Funnel":
      data = performanceData;
      break;
    case "Target Type":
      data = performanceData;
      break;
    case "Performance":
      data = performanceData;
      colName = "performance";
      break;
    case "Targeting":
      data = performanceData;
      colName = "targeting";
      break;
  }

  return { data, colName };
};

export const getColumns = (type: WhatIfTab) => {
  switch (type) {
    case "Campaigns":
      return campaignsColumns;
    case "Ad Type":
      return adTypeColumns;
    case "Funnel":
      return funnelColumns;
    case "Target Type":
      return targetTypeColumns;
    case "Performance":
      return performanceColumns;
    case "Targeting":
      return targetingColumns;
    case "Match type":
      return matchColumns;
    default:
      return [];
  }
};
