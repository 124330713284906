import React, { useState } from "react";
import classNames from "classnames";
import styles from "./SegmentedSelect.module.scss";

interface SegmentedSelectProps {
  options?: string[];
  onSelect?: (selected: string) => void;
  defaultSelected?: string;
}

const SegmentedSelect: React.FC<SegmentedSelectProps> = ({
  options = ["CUSTOMER", "UNITS SOLD"],
  onSelect = () => {},
  defaultSelected = "CUSTOMER",
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelected);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div className={styles.segmentedContainer}>
      <h2 className={styles.title}>Metrics</h2>
      <div className={styles.segmentedWrapper}>
        {options.map((option) => (
          <div
            key={option}
            className={classNames(styles.segmentedItem, {
              [styles.active]: selectedOption === option,
            })}
            onClick={() => handleSelect(option)}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedSelect;
