import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";

const InputRange = ({
  label,
  min = 0,
  max = 100,
}: {
  label: string;
  min?: number;
  max?: number;
}) => {
  const [value, setValue] = useState(50);
  const rangeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const rangeDom = rangeRef.current;

    if (rangeDom === null) {
      return;
    }

    const { min, max, value } = rangeDom;

    const val =
      ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100;
    const gradientValue = val > 50 ? val - 1 : val + 1;

    rangeDom.style.setProperty("--value", `${gradientValue}%`);
  }, [value]);

  return (
    <div className={styles.inputRange}>
      <div className={styles.inputRangeTop}>
        <div className={styles.inputRangeLabel}>{label}</div>
        <div className={styles.inputRangeValue}>{value}</div>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        step={1}
        onChange={(e) => setValue(Number(e.target.value))}
        ref={rangeRef}
        className={styles.inputRangeInput}
      />
    </div>
  );
};

export default InputRange;
