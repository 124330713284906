export default () => {
  return (
    <svg
      width="180"
      height="28"
      viewBox="0 0 180 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="28" stroke="#5C5C5A" />
      <line y1="13.5" x2="179" y2="13.5" stroke="#5C5C5A" />
      <line x1="179.5" y1="2.18557e-08" x2="179.5" y2="28" stroke="#5C5C5A" />
    </svg>
  );
};
