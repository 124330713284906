import React, { useState } from "react";
import classNames from "classnames";
import styles from "./Demographic.module.scss";
import ChevronDown from "@assets/icons/chevron-down.tsx";

interface DropdownSelectProps {
  label?: string;
  options?: string[];
  defaultOption?: string;
  onSelect?: (value: string) => void;
  data: string[];
}

const Demographic: React.FC<DropdownSelectProps> = ({
  label = "Demographic",
  defaultOption = "All",
  onSelect = () => {},
  data,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownWrapper}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
      </div>
      <div
        className={classNames(styles.selectBox, { [styles.open]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption}</span>
        <div className={classNames(styles.arrow, { [styles.active]: isOpen })}>
          {" "}
          <ChevronDown />
        </div>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {data.map((option, index) => (
            <div
              key={index}
              className={classNames(styles.dropdownItem, {
                [styles.selected]: option === selectedOption,
              })}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Demographic;
