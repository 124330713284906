import { useState, useEffect } from "react";
import { useProductStore } from "src/store/overviewProduct.state";
import TotalOptimization from "../../CROPage/components/Sections/TotalOptimization";
import { AspectOptimization } from "./components/DefaultOptimization/Aspect";
import { ListingOptimisation } from "./components/DefaultOptimization/Listing";
import { PriceOptimisation } from "./components/DefaultOptimization/Price";
import styles from "./styles.module.scss";
import { CROInfoType } from "@services/amazon/products/types";
import { OptimizationService } from "@services/optimization/optimization.services";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import { SpiderService } from "@services/spider/spider.services";
import { useBrandStore } from "src/store/brand";

const Optimization = ({ isWhatIf }) => {
  const [croInfo, setCroInfo] = useState<CROInfoType | undefined>(undefined);
  const optimizationService = new OptimizationService();
  const { selectedProduct } = useProductStore();
  const [brand, setBrand] = useState(null);
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [totalPOS, setTotalPOS] = useState<string | null>(null);
  const { brands, setBrands } = useBrandStore();

  useEffect(() => {
    const fetchScore = async () => {
      if (!selectedProduct?.asin) return;
      try {
        const res = await optimizationService.getCROInfo(selectedProduct.asin);
        setCroInfo(res);
      } catch (error) {
        console.error("Error fetching CRO Info:", error);
      }
    };

    fetchScore();
  }, [selectedProduct]);

  const fetchBrands = async () => {
    const spider = new CategoriesService();

    try {
      const spidersData = await spider.getBrands();

      if (spidersData.length > 0) {
        if (selectedProduct) {
          const matchingBrand = spidersData.find((brand) =>
            brand.asins.includes(selectedProduct.asin),
          );

          if (matchingBrand) {
            setCategoryName(matchingBrand.name);
            setBrands(matchingBrand.name);
          } else {
            console.warn("Selected product does not belong to any category.");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      fetchBrands();
    }
  }, [selectedProduct]);

  const fetchPOS = async () => {
    if (!categoryName) return;
    const spider = new SpiderService();

    try {
      const spiderDots = await spider.getPos(categoryName);
      if (spiderDots && typeof spiderDots.total_pos === "number") {
        const roundedPOS = Math.round(spiderDots.total_pos);
        setTotalPOS(roundedPOS);
      }
    } catch (error) {
      console.error("Error fetching POS:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct && categoryName) {
      fetchPOS();
    }
  }, [selectedProduct, categoryName]);

  return (
    <div className={styles.container}>
      <TotalOptimization
        isWhatIf={isWhatIf}
        croInfo={croInfo}
        totalPOS={totalPOS}
        brand={categoryName}
      />
      <ListingOptimisation isWhatIf={isWhatIf} croInfo={croInfo} />
      <AspectOptimization isWhatIf={isWhatIf} />
      <PriceOptimisation />
    </div>
  );
};

export default Optimization;
