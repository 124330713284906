import React from "react";
import styles from "./DemographicTypeShare.module.scss";
import { data } from "./utils";

const DemographicTypeShare: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Demographic Type Share</h2>
      {data.map((item, index) => {
        const numericValues = item.values.map((v) => parseFloat(v.percentage));
        const total = numericValues.reduce((acc, val) => acc + val, 0);

        return (
          <div key={index} className={styles.row}>
            <div className={styles.label}>{item.title}</div>
            <div className={styles.bar}>
              {item.values.map((value, i) => {
                const numericValue = parseFloat(value.percentage);
                const recalculatedWidth =
                  ((numericValue / total) * 100).toFixed(2) + "%";
                return (
                  <div
                    key={i}
                    className={styles.barSegment}
                    style={{
                      backgroundColor: value.color,
                      width: recalculatedWidth,
                    }}
                  >
                    <span className={styles.percentage}>
                      {value.percentage}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DemographicTypeShare;
