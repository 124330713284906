export const titleData = [
  {
    title: "Total",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      backgroundColor: "#FE5858",
    },
  },
  {
    title: "Total Forecast",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      border: "2px dashed #FE5858",
      backgroundColor: "#FE58583D",
    },
  },
  {
    title: "ASIN",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      backgroundColor: "#5295E0",
    },
  },
  {
    title: "ASIN Forecast",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      border: "2px dashed #5295E0",
      backgroundColor: "#5295E03D",
    },
  },
];

export const AvgData = [
  {
    title: "AVG Market Price",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      backgroundColor: "#FE5858",
    },
  },
  {
    title: "AVG ASIN Price",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      backgroundColor: "#5295E0",
    },
  },
];

export const AvgPriceData = [
  {
    title: "Market Share",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      backgroundColor: "#FE5858",
    },
  },
  {
    title: "Market Share Forecast",
    squareStyle: {
      width: "32px",
      height: "12px",
      borderRadius: "2px 0 0 0",
      border: "2px dashed #FE5858",
      backgroundColor: "#FE58583D",
    },
  },
];

export const metricsColors = {
  sales: "#FF5733",
  totalForecast: "#FF5733",
  ASIN: "#5295E0",
  ASINForecast: "#5295E0",
  adUnitsSold: "#5295E0",
  marketShareSolid: "#FF5733",
  marketShareDashed: "#FF5733",
  salesSolid: "#FF5733",
  salesDashed: "#FF5733",
  ASINSolid: "#5295E0",
  ASINDashed: "#5295E0",
  AVGMarketPrice: "#FF5733",
  AVGASINPrice: "#5295E0",
};

export const mockedData = [
  { date: "1 Jun", sales: 1700, ASIN: 300 },
  { date: "2 Jun", sales: 1400, ASIN: 200 },
  { date: "3 Jun", sales: 1500, ASIN: 300 },
  { date: "4 Jun", sales: 1500, ASIN: 400 },
  { date: "5 Jun", sales: 2200, ASIN: 300 },
  { date: "6 Jun", totalForecast: 1600, ASIN: 400 },
  { date: "7 Jun", totalForecast: 1900, ASIN: 500 },
  { date: "8 Jun", totalForecast: 1400, ASIN: 900 },
  { date: "9 Jun", totalForecast: 1900, ASIN: 900 },
];

export const AVGDataCHART = [
  { date: "1 Jun", AVGMarketPrice: 400, AVGASINPrice: 100 },
  { date: "2 Jun", AVGMarketPrice: 700, AVGASINPrice: 280 },
  { date: "3 Jun", AVGMarketPrice: 800, ASAVGASINPriceIN: 250 },
  { date: "4 Jun", AVGMarketPrice: 700, AVGASINPrice: 150 },
  { date: "5 Jun", AVGMarketPrice: 750, AVGASINPrice: 190 },
  { date: "6 Jun", AVGMarketPrice: 850, AVGASINPrice: 240 },
  { date: "7 Jun", AVGMarketPrice: 700, AVGASINPrice: 380 },
  { date: "8 Jun", AVGMarketPrice: 600, AVGASINPrice: 150 },
  { date: "9 Jun", AVGMarketPrice: 700, AVGASINPrice: 490 },
];

export const AVGDataPriceCHART = [
  { date: "1 Jun", MarketShare: 5 },
  { date: "2 Jun", MarketShare: 3 },
  { date: "3 Jun", MarketShare: 4.1 },
  { date: "4 Jun", MarketShare: 5.8 },
  { date: "5 Jun", MarketShare: 3.9 },
  { date: "6 Jun", MarketShareForecast: 4.8 },
  { date: "7 Jun", MarketShareForecast: 4.7 },
  { date: "8 Jun", MarketShareForecast: 4.9 },
  { date: "9 Jun", MarketShareForecast: 7 },
];

const displayNameMap: Record<string, string> = {
  salesSolid: "Total",
  salesDashed: "Total Forecast",
  ASINSolid: "ASIN",
  ASINDashed: "ASIN Forecast",
  AVGMarketPrice: "AVG Market Price",
  AVGASINPrice: "AVG ASIN Price",
  marketShareSolid: "Market Share",
  marketShareDashed: "Market Share Forecast",
};

export function customMapKeyToMetric(key: string, disableMapping = false) {
  if (disableMapping) return key;
  if (displayNameMap[key]) {
    return displayNameMap[key];
  }
  return key;
}
