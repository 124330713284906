import React from "react";
import styles from "./styles.module.scss";

interface CloseIconProps {
  onClick: () => void;
}

const CloseIcon: React.FC<CloseIconProps> = ({ onClick }) => {
  return (
    <button className={styles.closeIcon} onClick={() => onClick()}>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
    </button>
  );
};

export default CloseIcon;
