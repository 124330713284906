import { ChevronLeft } from "lucide-react";
import styles from "./styles.module.scss";

const BackButton = ({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) => {
  return (
    <button className={styles.backButton} onClick={onClick}>
      <ChevronLeft size={24} />
      {label}
    </button>
  );
};

export default BackButton;
