import { CustomBar } from "@components/BarChart";

interface RenderProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: any;
  dataKey: string;
  activeLabel: string | null;
  showForecast: boolean;
  colorMapping: { [key: string]: { [key: string]: string } };
}

export const data = [
  { date: "1 Jan", Available: 1600, ReorderPoint: 650, NoUnitsSold: 500 },
  { date: "2 Jan", Available: 1800, ReorderPoint: 420, NoUnitsSold: 500 },
  { date: "3 Jan", Available: 1650, ReorderPoint: 250, NoUnitsSold: 900 },
  {
    date: "4 Jan",
    Available: 1200,
    ReorderPoint: 500,
    InTransit: 300,
    NoUnitsSold: 700,
  },
  {
    date: "5 Jan",
    Available: 900,
    ReorderPoint: 360,
    InTransit: 440,
    NoUnitsSold: 200,
  },
  {
    date: "6 Jan",
    Available: 1400,
    ReorderPoint: 450,
    InTransit: 500,
    NoUnitsSold: 600,
  },
  {
    date: "7 Jan",
    Available: 1300,
    ReorderPoint: 400,
    InTransit: 400,
    NoUnitsSold: 800,
  },
];

export const colorMapping: Record<string, Record<string, string>> = {
  "1 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#255FA0",
    InTransit: "#5C5C5A",
    NoUnitsSold: "#5C5C5A",
  },
  "2 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#F19867",
    InTransit: "#5C5C5A",
    NoUnitsSold: "#5C5C5A",
  },
  "3 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#F19867",
    InTransit: "#5C5C5A",
    NoUnitsSold: "#5C5C5A",
  },
  "4 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#255FA0",
    InTransit: "#F19867",
    NoUnitsSold: "#5C5C5A",
  },
  "5 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#F19867",
    InTransit: "#6B4DBA",
    NoUnitsSold: "#5C5C5A",
  },
  "6 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#255FA0",
    InTransit: "#F19867",
    NoUnitsSold: "#5C5C5A",
  },
  "7 Jan": {
    Available: "#5295E0",
    ReorderPoint: "#F19867",
    InTransit: "#6B4DBA",
    NoUnitsSold: "#5C5C5A",
  },
};

export const nameMapping: Record<string, string> = {
  Available: "Available",
  ReorderPoint: "ReorderPoint",
  InTransit: "InTransit",
  Receiving: "Receiving",
  SafetyStock: "SafetyStock",
  NoUnitsSold: "NoUnitsSold",
};

export const legendColorMapping: Record<string, string> = {
  Available: "#5295E0",
  ReorderPoint: "#255FA0",
  InTransit: "#F19867",
  Receiving: "#6B4DBA",
  SafetyStock: "#7AC57B",
  NoUnitsSold: "#5C5C5A",
};

const renderCustomizedBar = ({
  x,
  y,
  width,
  height,
  payload,
  dataKey,
  activeLabel,
  showForecast,
  colorMapping,
}: RenderProps) => {
  const isActive = payload.date === activeLabel;
  const date = payload.date;
  const color = colorMapping[date]?.[dataKey] || "#5C5C5A";

  return (
    <CustomBar
      x={x}
      y={y}
      width={width}
      height={height}
      fill={color}
      showForecast={showForecast}
      activeLabel={activeLabel}
      payload={payload}
      dataKey={dataKey}
    />
  );
};

export default renderCustomizedBar;
