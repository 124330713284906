import styles from "./styles.module.scss";
import InputFile from "../InputFile";
import { MouseEventHandler } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import { Search } from "lucide-react";
import { Spin } from "antd";
import classNames from "classnames";
import { Platform } from "../../types";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./swiper.scss";
import { assetsListImages } from "../../mock";
import UserGuidePopover from "../UserGuidePopover";
import AccentLight from "../AccentLight";

SwiperCore.use([Navigation]);

const AssetsTab = ({
  items,
  onChange,
  onUploadImage,
  isLoading = false,
  userGuide,
  setUserGuide,
  currStep,
  setCurrUserStepGuide,
}: {
  items: Platform[];
  onChange: (target: HTMLElement) => void;
  onUploadImage: (file: File | null) => void;
  isLoading?: boolean;
  userGuide?: boolean;
  setUserGuide: (value: boolean) => void;
  currStep: number;
  setCurrUserStepGuide: (value: number) => void;
}) => {
  const addDraggableImage: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLElement | null;

    if (target === null) {
      return;
    }

    const newButton = target.closest(
      '[data-type="new-draggable"]',
    ) as HTMLElement | null;

    if (newButton === null) {
      return;
    }

    onChange(newButton);
  };

  return (
    <>
      <InputFile onChange={onUploadImage} />
      <label className="input">
        <div className="input__icon-wrapper">
          <Search size={15} stroke="#A6A7A8" className="input__icon" />
          <input
            className="input__input"
            type="text"
            placeholder="Search assets..."
          />
        </div>
      </label>
      <div
        className={classNames(styles.sliders, isLoading && styles.loading)}
        onClick={addDraggableImage}
      >
        {isLoading && <Spin size="large" className={styles.slidersLoading} />}
        {userGuide ? (
          assetsListImages.map((item, index) => (
            <div
              className={classNames(styles.slider, {
                [styles.darkened]: userGuide && index !== 1,
              })}
              key={index}
            >
              <div className={styles.slider__header}>
                <h4 className={styles.slider__label}>{item.label}</h4>
                <button className={styles.slider__link}>Show more</button>
              </div>
              <Swiper
                watchSlidesProgress={true}
                loop
                navigation
                spaceBetween={8}
                slidesPerView={3}
              >
                {item.list.map((image, i) => (
                  <SwiperSlide key={i}>
                    {index === 1 && i === 1 && currStep === 6 ? (
                      <UserGuidePopover
                        placement="right"
                        step={2}
                        text={0}
                        nextStep={7}
                        setCurrUserStepGuide={setCurrUserStepGuide}
                        setUserGuide={setUserGuide}
                      >
                        <AccentLight customCSS={{ borderRadius: "16px" }}>
                          <div
                            className={styles.image}
                            data-type="new-draggable"
                            style={{
                              backgroundImage: `url(${image})`,
                            }}
                            data-image={image}
                          ></div>
                        </AccentLight>
                      </UserGuidePopover>
                    ) : (
                      <div
                        className={classNames(styles.image)}
                        data-type="new-draggable"
                        style={{
                          backgroundImage: `url(${image})`,
                          pointerEvents: "none",
                        }}
                        data-image={image}
                      ></div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ))
        ) : (
          <div className={styles.slider}>
            <div className={styles.slider__header}>
              <h4 className={styles.slider__label}>Platforms</h4>
              <button className={styles.slider__link}>Show more</button>
            </div>
            <Swiper
              watchSlidesProgress={true}
              loop
              navigation
              spaceBetween={8}
              slidesPerView={3}
            >
              {items.map((image, i) => (
                <SwiperSlide key={i}>
                  <div
                    className={styles.image}
                    data-type="new-draggable"
                    style={{
                      backgroundImage: `url(data:image/png;base64,${image.original})`,
                    }}
                    data-image={image.original}
                    data-mask={image.mask}
                  ></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
};

export default AssetsTab;
