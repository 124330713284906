export default () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8856 10.1462C18.2668 9.78409 18.8649 9.78409 19.2461 10.1462C19.6561 10.5357 19.6561 11.1891 19.2461 11.5785L14.9105 15.6971C14.4836 16.1026 14.4836 16.7831 14.9105 17.1886L19.2461 21.3072C19.6561 21.6966 19.6561 22.3501 19.2461 22.7395C18.8649 23.1016 18.2668 23.1016 17.8856 22.7395L12.0422 17.1886C11.6153 16.7831 11.6153 16.1026 12.0422 15.6971L17.8856 10.1462Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};
