import { useEffect, useState } from "react";
import { Widgets } from "./components/Widgets";
import styles from "./styles.module.scss";
import { CampaignsChart } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";
import classNames from "classnames";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { getWidgetData, WidgetData } from "./utils";
import { generateWidgets } from "./utils";
import { CampaignsTable } from "./components/CampaignTable";
import { SliderKey } from "./components/Slider";
import { Spin } from "antd";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { AdSpend } from "./components/AdSpend";
import { WhatIfTable } from "./components/WhatIfTable";
import { WhatIfCharts } from "./components/WhatIfCharts";
import { Checks } from "./components/CampaignChart/utils";

interface CampaignsProps {
  canvas?: boolean;
}

export const Campaigns: React.FC<CampaignsProps> = ({ canvas = false }) => {
  const { dateRange, compareType } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    compareType: state.compareType,
  }));
  const { isWhatIfEnabled, compareWith } = useAdsAnaliticsStore((state) => ({
    isWhatIfEnabled: state.isWhatIfEnabled,
    compareWith: state.compareWith,
  }));

  const [data, setData] = useState<WidgetData>();
  const [checks, setChecks] = useState<Checks>({
    sales: true,
    spend: true,
    impressions: true,
    ctr: true,
    cvr: true,
    cpc: true,
    acos: true,
    roas: true,
    orders: true,
    unitSolds: true,
    clicks: false,

    tacos: false,
    organicRank: false,
    searchTermAdImpressionRank: false,
    timeInBudget: false,
    tosAdImpressionShare: false,
  });
  const [isChecksFixed, setIsChecksFixed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activeChecks = Object.keys(checks).filter((key) => checks[key]).length;

  const handleFixChecks = () => {
    if (isChecksFixed) {
      setIsChecksFixed(false);
      return;
    }

    if (activeChecks > 6) {
      setChecks({
        sales: true,
        spend: true,
        impressions: false,
        ctr: true,
        cvr: true,
        cpc: true,
        acos: false,
        roas: false,
        orders: false,
        unitSolds: true,
        clicks: false,
        tacos: false,
        organicRank: false,
        searchTermAdImpressionRank: false,
        timeInBudget: false,
        tosAdImpressionShare: false,
      });
      setIsChecksFixed(true);
    }
  };

  const handleCheck = (key: string) => {
    let formattedKey: string;

    if (key === "Units Sold") {
      formattedKey = "unitSolds";
    } else if (key === "TACOS") {
      formattedKey = "tacos";
    } else if (key === "Organic Rank") {
      formattedKey = "organicRank";
    } else if (key === "Search Term Ad Impression Rank") {
      formattedKey = "searchTermAdImpressionRank";
    } else if (key === "Time in Budget") {
      formattedKey = "timeInBudget";
    } else if (key === "TOS Ad Impression Share") {
      formattedKey = "tosAdImpressionShare";
    } else {
      formattedKey = key.toLowerCase().replace(" ", "");
    }

    // enforce your max-of-6 or any other constraints
    if (activeChecks > 6 && !checks[formattedKey]) {
      return;
    }

    setChecks((prev) => ({
      ...prev,
      [formattedKey]: !prev[formattedKey],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getWidgetData(dateRange, compareWith);
      setData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [dateRange, checks, compareWith]);

  return (
    <div className={styles.container}>
      <div className={classNames({ [styles.hidden]: canvas })}>
        {!isWhatIfEnabled && <SliderKey />}
      </div>
      <div className={styles.metrics}>
        {!isLoading ? (
          <Widgets
            checks={checks}
            setChecks={handleCheck}
            widgetsData={generateWidgets(data, compareType)}
          />
        ) : (
          <div className={styles.loader}>
            <Spin size="large" />
          </div>
        )}
        <div className={classNames(styles.chart, styles.box)}>
          <CampaignsChart checks={checks} handleFixChecks={handleFixChecks} />
        </div>
      </div>
      <div className={classNames({ [styles.hidden]: canvas })}>
        {!isWhatIfEnabled ? (
          <div className={styles.products}>
            <CampaignsTable />
          </div>
        ) : (
          <div className={styles.what_if}>
            <SliderKey />
            <AdSpend />
            <WhatIfTable />
            <WhatIfCharts />
          </div>
        )}
      </div>
    </div>
  );
};
