// MetricsDashboard.tsx
import React, { useState } from "react";
import { Card } from "antd";
import styles from "./Metrics.module.scss";
import { baseMetrics, whatIfMetrics } from "./utils";
import { MetricCards } from "../MetricsCard";
import ModalCanva from "@components/ModalCanva";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { formatDate } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend/utils";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";

interface MetricType {
  id: string;
  metricKey: string;
  name: string;
  originalValue: number;
  goalValue?: number;
}

export type Goals = Record<string, number>;

const MetricsDashboard: React.FC = () => {
  const [openedMetric, setOpenedMetric] = useState<MetricType | undefined>(
    undefined,
  );
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const { goals, setGoals } = useAdsAnaliticsStore((state) => ({
    goals: state.goals,
    setGoals: state.setGoals,
  }));
  const [baseMetricsState, setBaseMetricsState] =
    useState<MetricType[]>(baseMetrics);
  const [whatIfMetricsState, setWhatIfMetricsState] =
    useState<MetricType[]>(whatIfMetrics);
  const [goalsInputs, setGoalsInputs] = useState<Goals>({
    ContributionMarginBase: 0,
    ContributionProfitBase: 0,
    WhatIfMetric1: 0,
    WhatIfMetric2: 0,
  });

  const handleChangeGoal = (metricKey: string, newValue: number) => {
    setGoalsInputs((prev) => ({
      ...prev,
      [metricKey]: newValue,
    }));
  };

  const handleSaveGoals = () => {
    if (openedMetric) {
      const metricKey = openedMetric.metricKey;
      const newGoalValue = goalsInputs[metricKey];

      if (newGoalValue !== undefined && newGoalValue !== 0) {
        const isBaseMetric = baseMetricsState.some(
          (metric) => metric.metricKey === metricKey,
        );
        if (isBaseMetric) {
          setBaseMetricsState((prevMetrics) => {
            const updatedMetrics = prevMetrics.map((metric) =>
              metric.metricKey === metricKey
                ? { ...metric, goalValue: newGoalValue }
                : metric,
            );

            return updatedMetrics;
          });
        } else {
          setWhatIfMetricsState((prevMetrics) => {
            const updatedMetrics = prevMetrics.map((metric) =>
              metric.metricKey === metricKey
                ? { ...metric, goalValue: newGoalValue }
                : metric,
            );

            return updatedMetrics;
          });
        }

        setGoals({
          ...goals,
          [metricKey]: newGoalValue,
        });

        setGoalsInputs((prev) => ({
          ...prev,
          [metricKey]: 0,
        }));
      } else {
        console.log("Invalid goalValue:", newGoalValue);
      }
    }

    setOpenedMetric(undefined);
  };

  const getInputLabel = (metric?: MetricType) => {
    return `Set Goal for ${metric?.name}`;
  };

  return (
    <div className={styles.container}>
      <Card className={styles.groupCard} bordered={false}>
        <div className={styles.groupWrapper}>
          {baseMetricsState.map((metric) => (
            <MetricCards
              key={metric.id}
              id={metric.id}
              name={metric.name}
              title={metric.name}
              value={metric.originalValue}
              originalValue={metric.originalValue}
              goal={metric.goalValue}
              showGoals={metric.goalValue !== undefined}
              showSetGoalsButton={metric.goalValue === undefined}
              metricKey={metric.metricKey}
              onSetGoalsClick={(m) => setOpenedMetric(m)}
            />
          ))}
        </div>
      </Card>

      <Card className={styles.groupCard} bordered={false}>
        <div className={styles.groupWrapper}>
          {whatIfMetricsState.map((metric) => (
            <MetricCards
              key={metric.id}
              id={metric.id}
              name={metric.name}
              title={metric.name}
              value={metric.originalValue}
              originalValue={metric.originalValue}
              isGoalSet={false}
              goal={metric.goalValue}
              showGoals={
                metric.goalValue == undefined || metric.goalValue !== undefined
              }
              showSetGoalsButton={false}
              metricKey={metric.metricKey}
              onSetGoalsClick={(m) => setOpenedMetric(m)}
            />
          ))}
        </div>
      </Card>

      {openedMetric && (
        <ModalCanva
          isOpen={!!openedMetric}
          onClose={() => setOpenedMetric(undefined)}
          title={openedMetric?.name}
          className={styles.modal}
        >
          <div className={styles.input__container}>
            <span>{getInputLabel(openedMetric)}</span>
            <input
              type="number"
              value={goalsInputs[openedMetric.metricKey] || ""}
              onChange={(event) =>
                handleChangeGoal(
                  openedMetric.metricKey,
                  Number(event.target.value),
                )
              }
              className={styles.input}
            />
          </div>
          <div className={styles.date}>
            <div className={styles.date__item}>
              <span>Start date</span>
              <span>{formatDate(dateRange.startDate)}</span>
            </div>
            <div className={styles.date__item}>
              <span>End date</span>
              <span>{formatDate(dateRange.endDate)}</span>
            </div>
          </div>
          <button className={styles.save} onClick={handleSaveGoals}>
            Save Changes
          </button>
        </ModalCanva>
      )}
    </div>
  );
};

export default MetricsDashboard;
