import * as React from "react";
import cn from "classnames";
import styles from "./badge.module.scss";

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "secondary" | "destructive" | "outline";
}

function Badge({ className, variant = "default", ...props }: BadgeProps) {
  return (
    <div
      className={cn(styles.badge, styles[`badge--${variant}`], className)}
      {...props}
    />
  );
}

export { Badge };
