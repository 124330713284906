export const data = [
  {
    title: "Age Group",
    values: [
      { color: "#C8A857", percentage: "15%" },
      { color: "#255FA0", percentage: "14%" },
      { color: "#F19867", percentage: "14%" },
      { color: "#FE5858", percentage: "13%" },
      { color: "#80C67A", percentage: "10%" },
      { color: "#B5B5B5", percentage: "30%" },
    ],
  },
  {
    title: "Education",
    values: [
      { color: "#1BB08D", percentage: "23%" },
      { color: "#255FA0", percentage: "18%" },
      { color: "#FE5858", percentage: "14%" },
      { color: "#F19867", percentage: "11%" },
      { color: "#6B4DBA", percentage: "4%" },
      { color: "#B5B5B5", percentage: "30%" },
    ],
  },
  {
    title: "Gender",
    values: [
      { color: "#E667F1", percentage: "44%" },
      { color: "#5295E0", percentage: "22%" },
      { color: "#B5B5B5", percentage: "31%" },
    ],
  },
  {
    title: "Household income",
    values: [
      { color: "#6B4DBA", percentage: "16%" },
      { color: "#F19867", percentage: "9%" },
      { color: "#C8A857", percentage: "8%" },
      { color: "#FE5858", percentage: "7%" },
      { color: "#80C67A", percentage: "6%" },
      { color: "#255FA0", percentage: "3%" },
      { color: "#B5B5B5", percentage: "39%" },
    ],
  },
  {
    title: "Marial Status",
    values: [
      { color: "#80C67A", percentage: "41%" },
      { color: "#1BB08D", percentage: "6%" },
      { color: "#B5B5B5", percentage: "53%" },
    ],
  },
];
