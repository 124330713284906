import { SaveIcon } from "lucide-react";
import styles from "./saveBtn.module.scss";

export const SaveBtn = () => {
  const handleSave = () => {
    // TODO: Implement save functionality
  };
  return (
    <button className={styles.button} onClick={handleSave}>
      <SaveIcon className={styles.icon} size={16} />
      Save
    </button>
  );
};
