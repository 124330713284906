import { NodeProps } from "@xyflow/react";
import { memo } from "react";
import { AppNodeData } from "src/types/workflow.types";
import { TaskRegistry } from "src/constants/workflow.constants";
import { NodeCard } from "../NodeCard";
import { NodeHeader } from "../NodeHeader";
import { NodeInputs, NodeInput } from "../NodeInputs";
import { NodeOutput, NodeOutputs } from "../NodeOutput";

const NodeComponent = memo((props: NodeProps) => {
  const nodeData = props.data as AppNodeData;
  const task = TaskRegistry[nodeData.type];
  console.log("🚀 ~ file: NodeComponent.tsx:29 ~ NodeComponent ~ task:", task);
  return (
    <NodeCard nodeId={props.id} isSelected={!!props.selected}>
      <NodeHeader taskType={nodeData.type} nodeId={props.id} />
      <NodeInputs>
        {task.inputs.map((input, idx) => (
          <NodeInput key={idx} input={input} nodeId={props.id} />
        ))}
      </NodeInputs>
      <NodeOutputs>
        {task.outputs.map((output, idx) => (
          <NodeOutput key={idx} output={output} />
        ))}
      </NodeOutputs>
    </NodeCard>
  );
});

export default NodeComponent;
NodeComponent.displayName = "NodeComponent";
