import DownArrow from "@assets/icons/downArrow";
import UpArrow from "@assets/icons/upArrow";

export const dataSource = [
  {
    key: "1",
    demographics: "Male",
    customers: "4,519",
    customerShare: (
      <>
        <span>22%</span>{" "}
        <span className="red">
          <DownArrow /> 2%
        </span>
      </>
    ),
    unitsSold: "4,651",
    unitsSoldShare: (
      <>
        <span>17.6%</span>{" "}
        <span className="red">
          <DownArrow /> 1.2%
        </span>
      </>
    ),
  },
  {
    key: "2",
    demographics: "Female",
    customers: "9,038",
    customerShare: (
      <>
        <span>44%</span>{" "}
        <span className="green">
          <UpArrow /> 5%
        </span>
      </>
    ),
    unitsSold: "15,391",
    unitsSoldShare: (
      <>
        <span>49.3%</span>{" "}
        <span className="green">
          <UpArrow /> 8.3%
        </span>
      </>
    ),
  },
  {
    key: "3",
    demographics: "No Information Available",
    customers: "6,368",
    customerShare: (
      <>
        <span>31%</span>{" "}
        <span className="red">
          <DownArrow /> 3%
        </span>
      </>
    ),
    unitsSold: "10,256",
    unitsSoldShare: (
      <>
        <span>33.1%</span>{" "}
        <span className="green">
          <UpArrow /> 5.2%
        </span>
      </>
    ),
  },
  {
    key: "4",
    demographics: "Grand Total",
    customers: "20,542",
    customerShare: "100%",
    unitsSold: "30,298",
    unitsSoldShare: "100%",
    total: true,
  },
];

export const columns = [
  {
    title: "Demographics",
    dataIndex: "demographics",
    key: "demographics",
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: "Customers",
    dataIndex: "customers",
    key: "customers",
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: "Customer Share",
    dataIndex: "customerShare",
    key: "customerShare",
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: "Units Sold",
    dataIndex: "unitsSold",
    key: "unitsSold",
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: "Units Sold Share",
    dataIndex: "unitsSoldShare",
    key: "unitsSoldShare",
    render: (text: string) => <span>{text}</span>,
  },
];

export const demographicData: { [key: string]: string[] } = {
  "Age Group": [
    "18-24",
    "25-34",
    "35-44",
    "45-54",
    "55-64",
    "65+",
    "Information Not Available",
  ],
  Education: [
    "Bachelors Degree",
    "Graduate Degree",
    "High School Graduate",
    "Information Not Available",
    "Less than High School",
    "Some College",
  ],
  Gender: ["Female", "Information Not Available", "Male"],
  "Household Income": [
    "100,000 to 124,999",
    "125,000 to 149,999",
    "150,000 to 174,999",
    "175,000 to 199,999",
    "200,000 to 249,999",
    "250,000 or more",
    "50,000 to 74,999",
    "75,000 to 99,999",
    "Information Not Available",
  ],
  "Marital Status": ["Information Not Available", "Married", "Single"],
};
