import { WaveProps } from "src/types/customBar.type";

export const Wave = ({
  width,
  height,
  numberOfBars,
  shiftPx,
  cumulativeStart,
  yDomain,
}: WaveProps) => {
  const amplitude = 19;
  const frequency = 1;

  const mapToY = (value: number) => {
    const [minDomain, maxDomain] = yDomain;
    return height - ((value - minDomain) / (maxDomain - minDomain)) * height;
  };

  const y0 = mapToY(cumulativeStart);

  const numberOfPoints = 200;
  const step = width / numberOfPoints;
  const wavePoints = [];

  for (let i = 0; i <= numberOfPoints; i++) {
    const x = i * step;
    const y =
      y0 + amplitude * Math.sin((i / numberOfPoints) * frequency * 2 * Math.PI);
    wavePoints.push({ x, y });
  }

  const pathData =
    `M ${wavePoints[0].x},${wavePoints[0].y} ` +
    wavePoints
      .slice(1)
      .map((p) => `L ${p.x},${p.y}`)
      .join(" ");

  const dots = [];
  for (let i = 0; i < numberOfBars; i++) {
    const x = (width / numberOfBars) * (i + 0.6) - 40;
    const y =
      y0 +
      amplitude *
        Math.sin(((i + 0.5) / numberOfBars) * frequency * 2 * Math.PI);
    dots.push({ x, y });
  }

  return (
    <g transform={`translate(${shiftPx}, 0)`}>
      <path d={pathData} stroke="#00BFFF" strokeWidth={4} fill="none" />
      {dots.map((point, index) => (
        <g key={index}>
          <circle
            cx={point.x}
            cy={point.y}
            r={6}
            fill="none"
            stroke="#FFFFFF"
            strokeWidth={2}
          />
          <circle cx={point.x} cy={point.y} r={4} fill="var(--Blue, #5295E0)" />
        </g>
      ))}
    </g>
  );
};
