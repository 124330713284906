import { useState } from "react";
import styles from "./styles.module.scss";
import image from "../../images/image.jpg";
import Tabs from "../../../Tabs";
import CustomInput from "@components/СustomInput";
import AdjuctIcon from "../../images/adjuct.svg?react";
import PresetsIcon from "../../images/presets.svg?react";
import CheckCircleIcon from "../../images/check-circle.svg?react";

const PhotoEditor = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <Tabs
        list={[
          <>
            <AdjuctIcon />
            Adjust
          </>,
          <>
            <PresetsIcon />
            Presets
          </>,
        ]}
        onChange={setActiveIndex}
        size="sm"
      />
      <div className={styles.workspace}>
        <div
          className={styles.workspaceImage}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        {activeIndex === 0 && (
          <>
            <div className={styles.inputs}>
              {[
                "Exposure",
                "Exposure",
                "Contrast",
                "Brightness",
                "Saturation",
                "Shadows",
                "Highlights",
              ].map((el, i) => {
                return (
                  <label key={i} className={styles.inputRange}>
                    <div className={styles.inputRangeLabel}>{el}</div>
                    <ControlledCustomItem onChange={() => {}} />
                  </label>
                );
              })}
            </div>
          </>
        )}
        {activeIndex === 1 && (
          <div className={styles.checkboxs}>
            {[
              { label: "None", bg: "none" },
              { label: "Bliss", bg: "#EDEDED" },
              { label: "City Light", bg: "#6681C5" },
              { label: "Dark Moody", bg: "#000000" },
              { label: "Dreams", bg: "#9F9362" },
              { label: "Natural Sunset", bg: "#CC9337" },
              { label: "Seascape", bg: "#192E71" },
              { label: "Warm Sunset", bg: "#FFE5C8" },
            ].map((el, i) => {
              const isNone = el.bg === "none";

              return (
                <label className={styles.checkbox} key={i}>
                  <input
                    type="radio"
                    name="presets"
                    className={styles.checkboxInput}
                    defaultChecked={i === 0}
                  />
                  <div className={styles.checkboxChecked}></div>
                  {isNone ? (
                    <div className={styles.checkboxImage}>
                      <CheckCircleIcon />
                    </div>
                  ) : (
                    <div
                      className={styles.checkboxImage}
                      style={{ backgroundImage: `url(${image})` }}
                    >
                      <div
                        className={styles.checkboxImageBg}
                        style={{ backgroundColor: el.bg }}
                      ></div>
                    </div>
                  )}
                  <div className={styles.checkboxLabel}>{el.label}</div>
                </label>
              );
            })}
          </div>
        )}
      </div>
      <button className={"button button--sm button--m-0"}>Apply</button>
    </>
  );
};

export default PhotoEditor;

const ControlledCustomItem = ({
  onChange,
}: {
  onChange: (value: number) => void;
}) => {
  const [value, setValue] = useState(50);

  const handleChange = (value: number) => {
    onChange(value);
    setValue(value);
  };

  return (
    <CustomInput
      value={value}
      onChange={(e) => handleChange(Number(e.target.value))}
      label={""}
      hideResult
      blue
    />
  );
};
