import React from "react";
import { Table } from "antd";
import "./DemographicsTable.css";
import { columns, dataSource } from "./utils";

const DemographicsTable: React.FC = () => {
  return (
    <div className="demographic-table">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered={false}
      />
    </div>
  );
};

export default DemographicsTable;
