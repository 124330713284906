export const toDataURL = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      }
      reject("Expected string but received a " + typeof reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// TODO: join with toDataURL
export const toDataURLByFile = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      }
      reject("Expected string but received a " + typeof reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
