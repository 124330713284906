import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import CalendarImage from "@assets/icons/calendar.svg";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { formatDateRange } from "src/utils/formatDate";
import { t } from "i18next";
import { CalendarModal } from "@components/calendar-modal";
import CustomSelect from "@pages/AdsAnalitics/components/Header/ui/CustomSelect";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { Navigation } from "@pages/AdsAnalitics/components/Header/ui/navigation";
import SupplyBlocks from "@pages/SupplyChain/components/SupplyChain";
import FunnelBlocks from "./TotalAsinBlock";
import { Chart } from "@pages/SupplyChain/components/SalesPerformanceLineChart";
import { initialChecks } from "@pages/Dashboard/features/SalesPerformance/types";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { generateDateRangeArray } from "@pages/SupplyChain/components/SelesPerfomance/utils";
import { ExecutiveSummary } from "@components/ExecutiveSummary";

export const MarketTitles = [
  "Market Total Sales",
  " Brand Market Share",
  "Market Average Price ",
  "Market Units Sold",
  "Market ASIN count ",
  "Market Promotion Value",
  "Market Promotion Count",
  "Market Review Score ",
  "Market POS",
  "Market Ad Spend",
];
export const COLORSCHART = ["#fc6762", "#5295E0"];
export function MarketIntelligenceBlock() {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataSet, setDataSet] = useState<any[]>([]);
  const { dateRange, setDateRange, compareType, setCompareType } =
    useDashboardStore((state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      compareType: state.compareType,
      setCompareType: state.setCompareType,
    }));
  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  const { compareWith, setCompareWith } = useAdsAnaliticsStore();
  const [checks, setChecks] = useState(initialChecks);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [metricsColors, setMetricsColors] = useState<{ [key: string]: string }>(
    {},
  );
  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
  };
  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat("en", {
      day: "numeric",
      month: "short",
    });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await getData(dateRange, checks);

        const transformedData = transformDataToObject(fetchedData);
        setData(transformedData);
        const salesData: number[] = fetchedData.sales || [];

        const allDates = generateDateRangeArray(
          dateRange.startDate,
          dateRange.endDate,
        );
        const mappedData = allDates.map((dateObj, index) => {
          const dateStr = dateFormatter.format(dateObj);

          return {
            date: dateStr,
            AdsSales: salesData[index] || 0,
            OrganicSales: Array.isArray(fetchedData.OrganicSales)
              ? fetchedData.OrganicSales[index] || 0
              : 0,
          };
        });
        setDataSet(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      Object.values(checks).some((value) => value)
    ) {
      fetchData();
    }
  }, [dateRange, checks, dateFormatter]);

  useEffect(() => {
    const colorMapping: { [key: string]: string } = {};
    Object.keys(checks).forEach((check, index) => {
      colorMapping[check] = COLORSCHART[index % COLORSCHART.length];
    });
    setMetricsColors(colorMapping);
  }, [checks]);
  const label = ["Market Share", "Metrics Rate", "ASIN Query Rate"];
  return (
    <div>
      <div className={styles.dashboardWidgetsHeader}>
        <div className={styles.navBetween}>
          <Navigation customTitles={label} setIsOpenMenu={setIsMenuOpen} />
          <h1 className={styles.headerTitle}>{t("Market Intelligence")}</h1>
        </div>
        <div className={styles.buttons}>
          <div className={styles.calendar}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsCalendarOpen(!isCalendarOpen);
              }}
              className={classNames(
                styles.button,
                isCalendarOpen && styles.active,
              )}
            >
              <img
                src={CalendarImage}
                alt="Calendar"
                className={styles.imgCalendar}
              />
              <p className={styles.btn}>{selectedDateRange}</p>
            </button>
            {isCalendarOpen && (
              <div className={styles.modal}>
                <CalendarModal
                  buttonWidth={148}
                  closeModal={() => setIsCalendarOpen(false)}
                  onDateChange={handleDateChange}
                  initialRange={dateRange}
                />
              </div>
            )}
          </div>
          <CustomSelect
            dateRange={dateRange}
            compareWith={compareWith}
            setCompareWith={setCompareWith}
            compareType={compareType}
            setCompareType={setCompareType}
            hideSwitch={true}
          />
        </div>
      </div>
      <div className={styles.executiveSummary}>
        <ExecutiveSummary hideSwitch={true} marketTitles={MarketTitles} />
      </div>
      {/* <SupplyBlocks showMarketBlock={true} /> */}
      <div className={styles.marketBlock}>
        <div className={styles.marketSizeBlock}>
          <h1 className={styles.marketTitle}>Market Size</h1>
          <Chart
            checks={checks}
            metricsColors={metricsColors}
            data={data}
            setChecks={setChecks}
            titles={true}
          />
        </div>
        <div className={styles.marketSizeBlock}>
          <h1 className={styles.marketTitle}>Average Price</h1>
          <Chart
            checks={checks}
            metricsColors={metricsColors}
            data={data}
            setChecks={setChecks}
            titles={true}
            avg={true}
          />
        </div>
      </div>
      <div className={styles.marketBlock}>
        <div className={styles.marketSizeBlock}>
          <h1 className={styles.marketTitle}>Average Price</h1>
          <Chart
            checks={checks}
            metricsColors={metricsColors}
            data={data}
            setChecks={setChecks}
            titles={true}
            avgPrice={true}
          />
        </div>
        <div>
          <FunnelBlocks />
        </div>
      </div>
    </div>
  );
}
