import { useState } from "react";
import styles from "./styles.module.scss";

const InputCheckbox = ({
  label,
  onChange,
  defaultValue = false,
}: {
  label: string;
  onChange: (checked: boolean) => void;
  defaultValue?: boolean;
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChange = (checked: boolean) => {
    onChange(checked);
    setChecked(checked);
  };

  return (
    <label className={styles.checkbox}>
      <input
        type="checkbox"
        name="template"
        value={"label"}
        className={styles.checkbox__input}
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
      />
      <span className={styles.checkbox__label}>{label}</span>
    </label>
  );
};

export default InputCheckbox;
