import styles from "./Pln.module.scss";
import AdditionalMetrics from "../AdditionalMetrics";
import MetricsDashboard from "../MetricGoals";
import ScenarioTable from "../Scenarion";

const PNLBlock: React.FC = () => {
  return (
    <div className={styles.combinedContainer}>
      <AdditionalMetrics />
      <MetricsDashboard />
      <ScenarioTable />
    </div>
  );
};

export default PNLBlock;
