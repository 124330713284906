export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.698 12.2673C14.1005 12.6636 14.1005 13.3063 13.698 13.7027C13.2956 14.0991 12.6431 14.0991 12.2407 13.7027L7.72858 9.25828C7.32615 8.86189 6.67369 8.86189 6.27126 9.25828L1.75913 13.7027C1.35671 14.0991 0.704246 14.0991 0.30182 13.7027C-0.100607 13.3063 -0.100606 12.6636 0.30182 12.2673L6.27126 6.38739C6.67369 5.991 7.32615 5.991 7.72858 6.38739L13.698 12.2673Z"
        fill="#7C7C78"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.301975 1.73274C-0.100451 1.33635 -0.100451 0.693679 0.301975 0.297291C0.704402 -0.099097 1.35686 -0.0990967 1.75929 0.297291L6.27142 4.74172C6.67385 5.13811 7.32631 5.13811 7.72874 4.74172L12.2409 0.297291C12.6433 -0.0990971 13.2958 -0.099097 13.6982 0.297291C14.1006 0.693679 14.1006 1.33635 13.6982 1.73274L7.72874 7.61261C7.32631 8.009 6.67385 8.009 6.27142 7.61261L0.301975 1.73274Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
