import React, { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import ChevronDown from "@assets/icons/chevron-down";
import { ELEMENT_ID } from "src/store/canva/canva.state";

export type Element = {
  id: string;
  elementId: ELEMENT_ID;
  name: string;
  active: boolean;
  icon: string;
};

interface ElementsAccordionProps {
  title?: string;
  elements: Element[];
  onElementClick: (elementId: ELEMENT_ID, category: string) => void;
  search: string;
  category: string;
  variant?: "big" | "small";
}

export const ElementsAccordion: React.FC<ElementsAccordionProps> = ({
  title,
  elements,
  onElementClick,
  search,
  category,
  variant,
}) => {
  const filteredElements = (elements || []).filter((element) =>
    element.name.toLowerCase().includes(search.toLowerCase()),
  );

  const activeElementCount = Number(
    filteredElements.filter((el) => el.active).length,
  );

  const [isOpen, setIsOpen] = useState<boolean>(!title);

  if (filteredElements.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, styles[variant], {
        [styles.open]: isOpen,
      })}
    >
      {title && (
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className={styles.title}>
            {title}
            {activeElementCount > 0 && <div>{activeElementCount}</div>}
          </div>
          <ChevronDown />
        </button>
      )}
      <ul className={classNames(styles.elementsList, variant)}>
        {filteredElements.map((element) => {
          return (
            <li key={element.id}>
              <button
                className={styles.element}
                onClick={() => onElementClick(element.elementId, category)}
              >
                <img src={element.icon} alt={element.name} />
                {element.name}
                <span
                  className={classNames(styles.status, {
                    [styles.active]: element.active,
                  })}
                ></span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
