import "./index.css";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import ProductCode from "@components/ProductCode";
import DataBlock from "@components/DataBlock";
import { mockDataBlock } from "./mock";
import { useNavigate } from "react-router-dom";
import { useProductStore } from "src/store/overviewProduct.state";

export const Description = ({ prod }) => {
  const { setSelectedProduct } = useProductStore();
  const navigate = useNavigate();

  const handleTitleClick = async () => {
    setSelectedProduct(prod);
    navigate("/my-product");
  };

  const handleGenerateImage = (asin: string) => {
    setSelectedProduct(prod);
    navigate(`/CRO-module/cro-photo-update/${asin}`);
  };

  return (
    <div className="product-description">
      <picture className="product-description__picture">
        <img src={prod.image_link} alt={prod.item_name} />
      </picture>
      <div className="product-description__description">
        <div className="prod">
          <h2
            className="product-description__title heading-2"
            onClick={handleTitleClick}
            style={{ cursor: "pointer" }}
          >
            {prod.item_name}
          </h2>
          <div className="product-additional">
            <div className="product-score">
              <Rating rating={4.5} />
              <ProductCode code={prod.asin} />
            </div>
            <button
              type="button"
              className="btn primary"
              onClick={() => handleGenerateImage(prod.asin)}
            >
              Generate an image with AI
            </button>
          </div>
        </div>
        <DataBlock data={mockDataBlock} />
      </div>
    </div>
  );
};
