import { useEffect, useState } from "react";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import { useMathStore } from "@pages/Dashboard/store/math.state";
import { createWidgets } from "src/utils/createWidgets";
import { fetchWidgets } from "src/utils/fetchWidgets";
import { SubWidget } from "@pages/SubscriptionPerformance/components/WidgetsSubscription";

export interface IUpdatedWidget {
  selected?: boolean;
  id: string;
  name: string;
  value: string;
  originalValue: number;
  difference?: string;
  key?: string;
  items?: SubWidget[];
}

export const useWidgets = () => {
  const [updatedWidgets, setUpdatedWidgets] = useState<IUpdatedWidget[]>([]);
  const { dateRange, setDateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
  }));

  useEffect(() => {
    const fetchInitialWidgets = async () => {
      await fetchWidgets(
        dateRange.startDate.toISOString().split("T")[0],
        dateRange.endDate.toISOString().split("T")[0],
      );
      const newWidgets = await createWidgets(
        useMathStore.getState().metrics,
        dateRange,
      );

      setUpdatedWidgets(newWidgets);
    };
    fetchInitialWidgets();
  }, [dateRange]);

  const mathStoreData = useMathStore((state) => ({
    aovData: state.metrics.aovData,
    salesTotalData: state.metrics.salesTotalData,
    adsImpressionsData: state.metrics.adsImpressionsData,
    adsClicksData: state.metrics.adsClicksData,
    adsOrdersData: state.metrics.adsOrdersData,
    adsUnitsSoldData: state.metrics.adsUnitsSoldData,
    adsSalesData: state.metrics.adsSalesData,
    adsSpendData: state.metrics.adsSpendData,
    adsAcosData: state.metrics.adsAcosData,
    adsRoasData: state.metrics.adsRoasData,
    adsCpcData: state.metrics.adsCpcData,
    adsCvrData: state.metrics.adsCvrData,
    adsCtrData: state.metrics.adsCtrData,
    organicImpressionsData: state.metrics.organicImpressionsData,
    organicClicksData: state.metrics.organicClicksData,
    organicCartAddsData: state.metrics.organicCartAddsData,
    organicUnitsSoldData: state.metrics.organicUnitsSoldData,
    organicCvrData: state.metrics.organicCvrData,
    organicCtrData: state.metrics.organicCtrData,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const newWidgets = await createWidgets(mathStoreData, dateRange);

      if (updatedWidgets.length === 0 || updatedWidgets) {
        setUpdatedWidgets(newWidgets);
      }
    };
    fetchData();
  }, [
    mathStoreData.aovData,
    mathStoreData.salesTotalData,
    mathStoreData.adsImpressionsData,
    mathStoreData.adsClicksData,
    mathStoreData.adsOrdersData,
    mathStoreData.adsUnitsSoldData,
    mathStoreData.adsSalesData,
    mathStoreData.adsSpendData,
    mathStoreData.adsAcosData,
    mathStoreData.adsRoasData,
    mathStoreData.adsCpcData,
    mathStoreData.adsCvrData,
    mathStoreData.adsCtrData,
    mathStoreData.organicImpressionsData,
    mathStoreData.organicClicksData,
    mathStoreData.organicCartAddsData,
    mathStoreData.organicUnitsSoldData,
    mathStoreData.organicCvrData,
    mathStoreData.organicCtrData,
  ]);

  const handleApply = (
    newWidgets: IUpdatedWidget[],
    newDateRange?: { startDate: Date; endDate: Date },
  ) => {
    setUpdatedWidgets(newWidgets);
    newDateRange && setDateRange(newDateRange);
  };

  return {
    updatedWidgets,
    handleApply,
  };
};
