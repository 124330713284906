import classNames from "classnames";
import styles from "./styles.module.scss";

const AccentLight = ({
  customCSS,
  children,
  onclick,
}: {
  customCSS: React.CSSProperties;
  children: React.ReactNode;
  onclick?: () => void;
}) => {
  return (
    <div
      className={classNames(styles.accentLight)}
      style={customCSS}
      onClick={onclick}
    >
      {children}
    </div>
  );
};

export default AccentLight;
