import { Edge } from "@xyflow/react";
import { TaskRegistry } from "src/constants/workflow.constants";
import {
  WorkflowExecutionPlan,
  AppNodeMissingInputs,
  AppNode,
  WorkflowExecutionPlanPhase,
} from "src/types/workflow.types";

export enum FlowToExecutionPlanValidationError {
  "NO_ENTRY_POINT",
  "INVALID_INPUTS",
}

type FlowToExecutionPlanType = {
  executionPlan?: WorkflowExecutionPlan;
  error?: {
    type: FlowToExecutionPlanValidationError;
    invalidElements?: AppNodeMissingInputs[];
  };
};

export function FlowToExecutionPlan(
  nodes: AppNode[],
  edges: Edge[],
): FlowToExecutionPlanType {
  // Input validation
  if (!nodes?.length) {
    throw new Error("No nodes provided");
  }

  const entryPoint = nodes.find(
    (node) => TaskRegistry[node.data.type].isEntryPoint,
  );
  if (!entryPoint) {
    return {
      error: {
        type: FlowToExecutionPlanValidationError.NO_ENTRY_POINT,
      },
    };
  }
  const inputswithErrors: AppNodeMissingInputs[] = [];
  const planned = new Set<string>();
  const invalidInputs = getInvalidInputs(entryPoint, edges, planned);
  if (invalidInputs.length > 0) {
    inputswithErrors.push({
      nodeId: entryPoint.id,
      inputs: invalidInputs,
    });
  }
  const executionPlan = [
    {
      phase: 1,
      nodes: [entryPoint],
    },
  ];
  planned.add(entryPoint.id);
  for (
    let phase = 2;
    phase <= nodes.length && planned.size < nodes.length;
    phase++
  ) {
    const nextPhase: WorkflowExecutionPlanPhase = { phase, nodes: [] };
    for (const currentNode of nodes) {
      if (planned.has(currentNode.id)) {
        // Node already put in the execution plan.
        continue;
      }

      const invalidInputs = getInvalidInputs(currentNode, edges, planned);
      if (invalidInputs.length > 0) {
        const incomers = getIncomers(currentNode, nodes, edges);
        if (incomers.every((incomer) => planned.has(incomer.id))) {
          // If all incoming incommers/edges are planned and there are still invalid inputs
          // this means that this particular node has an invalid input
          // which means that the workflow is invalid
          console.error(
            `Node ${currentNode.id} has invalid inputs: ${invalidInputs.join(
              ", ",
            )}`,
          );
          inputswithErrors.push({
            nodeId: currentNode.id,
            inputs: invalidInputs,
          });
        } else {
          continue;
        }
      }

      nextPhase.nodes.push(currentNode);
    }
    for (const node of nextPhase.nodes) {
      planned.add(node.id);
    }
    executionPlan.push(nextPhase);
  }
  if (inputswithErrors.length > 0) {
    return {
      error: {
        type: FlowToExecutionPlanValidationError.INVALID_INPUTS,
        invalidElements: inputswithErrors,
      },
    };
  }
  return { executionPlan };
}

/**
 * Determines which inputs of a given node are invalid based on the current edges and planned nodes.
 *
 * @param node - The node whose inputs are to be validated.
 * @param edges - An array of edges representing connections between nodes.
 * @param planned - A set of node IDs that are planned or already processed.
 * @returns An array of input names that are considered invalid.
 */
function getInvalidInputs(
  node: AppNode,
  edges: Edge[],
  planned: Set<string>,
): string[] {
  const invalidInputs: string[] = [];
  const inputs = TaskRegistry[node.data.type].inputs;

  for (const input of inputs) {
    const inputValue = node.data.inputs[input.name];
    const inputValueProvided = inputValue?.length > 0;

    if (input.required) {
      if (!inputValueProvided) {
        // Check if input is connected via an edge
        const incomingEdge = edges.find(
          (edge) => edge.target === node.id && edge.targetHandle === input.name,
        );
        if (!incomingEdge || !planned.has(incomingEdge.source)) {
          invalidInputs.push(input.name);
        }
      }
    } else {
      if (inputValueProvided) {
        // If input is provided, ensure it's valid
        const incomingEdge = edges.find(
          (edge) => edge.target === node.id && edge.targetHandle === input.name,
        );
        if (incomingEdge && !planned.has(incomingEdge.source)) {
          invalidInputs.push(input.name);
        }
      }
    }
  }
  return invalidInputs;
}

function getIncomers(
  node: AppNode,
  nodes: AppNode[],
  edges: Edge[],
): AppNode[] {
  if (!node.id) {
    return [];
  }
  const incomersIds = new Set();
  edges.forEach((edge) => {
    if (edge.target === node.id) {
      incomersIds.add(edge.source);
    }
  });

  return nodes.filter((node) => incomersIds.has(node.id));
}
