import React, { useState } from "react";
import { Button, Card, Switch } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";
import renderCustomizedBar, {
  colorMapping,
  data,
  legendColorMapping,
  nameMapping,
} from "./utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import PoSalesTable from "../PoSalesTable";

const InventoryPerformance = () => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [showForecast, setShowForecast] = useState<boolean>(false);
  const [isPoModal, setIsPoModal] = useState<boolean>(false);

  return (
    <Card className={styles.card}>
      <div className={styles.headerBetween}>
        <h1 className={styles.title}>Inventory Performance</h1>
      </div>

      <div className={styles.rowLegend}>
        {!isPoModal && (
          <div className={styles.legendContainer}>
            {Object.keys(nameMapping).map((key) => (
              <div className={styles.legendItem} key={key}>
                <span
                  className={styles.colorDot}
                  style={{
                    backgroundColor:
                      legendColorMapping[nameMapping[key]] || "#5C5C5A",
                  }}
                ></span>
                <span className={styles.legendText}>{nameMapping[key]}</span>
              </div>
            ))}
          </div>
        )}

        <div className={styles.switchRow}>
          <div className={styles.switchContainer}>
            <Switch
              checked={showForecast}
              onChange={(checked) => setShowForecast(checked)}
            />
            <span className={styles.switchLabel}>Show Forecast</span>
          </div>
          <div className={styles.switchContainer}>
            <Switch
              checked={isPoModal}
              onChange={() => setIsPoModal(!isPoModal)}
            />
            <span className={styles.switchLabel}>Show Table</span>
          </div>
          <div className={styles.switchContainer}>
            <Switch />
            <span className={styles.switchLabel}>Show Past Data</span>
          </div>
          <Button
            type="link"
            className={styles.infoButton}
            icon={
              <img
                src={InfoIcon}
                alt="Info"
                className={styles.infoIcon}
                width={14}
                height={14}
              />
            }
          />
        </div>
      </div>

      {!isPoModal ? (
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
              barGap={2}
              barSize={39}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setActiveLabel(state.activeLabel as string);
                } else {
                  setActiveLabel(null);
                }
              }}
              onMouseLeave={() => setActiveLabel(null)}
            >
              <CartesianGrid stroke="#1F1F20" vertical={false} />
              <XAxis
                dataKey="date"
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
              />
              <YAxis
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
                ticks={[2500, 2000, 1500, 1000, 500, 0]}
                domain={[0, 2500]}
              />
              <Tooltip
                content={({ active, payload, label }) => (
                  <CustomTooltip
                    active={active}
                    payload={payload}
                    colorMetric={legendColorMapping}
                    label={label}
                    disableMapping={true}
                  />
                )}
                cursor={{
                  fill: "transparent",
                }}
              />

              <Bar
                dataKey="Available"
                stackId="a"
                name={nameMapping["Available"]}
                shape={(props) =>
                  renderCustomizedBar({
                    ...props,
                    activeLabel,
                    showForecast,
                    colorMapping,
                  })
                }
              />
              <Bar
                dataKey="ReorderPoint"
                stackId="a"
                name={nameMapping["ReorderPoint"]}
                shape={(props) =>
                  renderCustomizedBar({
                    ...props,
                    activeLabel,
                    showForecast,
                    colorMapping,
                  })
                }
              />
              <Bar
                dataKey="InTransit"
                stackId="a"
                name={nameMapping["InTransit"]}
                shape={(props) =>
                  renderCustomizedBar({
                    ...props,
                    activeLabel,
                    showForecast,
                    colorMapping,
                  })
                }
              />

              <Bar
                dataKey="NoUnitsSold"
                stackId="b"
                name={nameMapping["NoUnitsSold"]}
                shape={(props) =>
                  renderCustomizedBar({
                    ...props,
                    activeLabel,
                    showForecast,
                    colorMapping,
                  })
                }
              />
            </BarChart>
          </ResponsiveContainer>
          <div className={styles.borderOverlay}></div>
        </div>
      ) : (
        <PoSalesTable />
      )}
    </Card>
  );
};

export default InventoryPerformance;
