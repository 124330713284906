import { act } from "@testing-library/react";

export const stepConfig = {
  0: {
    id: 0,
    component: "AsinTab",
  },
  1: {
    id: 1,
    component: "Modal",
    action: (setShowModal: (value: boolean) => void) => {
      setShowModal(true);
    },
  },
  2: {
    id: 2,
    component: "Canva",
  },
  3: {
    id: 3,
    component: "GenerateTab",
    action: (setActiveNavIndex: (value: number) => void) => {
      setActiveNavIndex(1);
    },
  },
  4: {
    id: 4,
    component: "Generate",
  },
  5: {
    id: 5,
    component: "GeneratedIMG",
  },
  6: {
    id: 6,
    component: "Assets",
    action: (setActiveNavIndex: (value: number) => void) => {
      setActiveNavIndex(2);
    },
  },
  7: {
    id: 7,
    component: "AssetsAdded",
  },
  8: {
    id: 8,
    component: "Finish",
    action: (setUserGuide: (value: boolean) => void) => {
      setUserGuide(false);
    },
  },
  // 9: {
  //   id: 9,
  //   component: "Close",
  //   action: (setUserGuide: (value: boolean) => void) => {
  //     setUserGuide(false);
  //   }
  // }
};
