export const AMAZON_FEES_OPTIONS = [
  { value: "Total Amazon Fee", label: "Total Amazon Fee" },
  { value: "Aged Inventory Surcharge", label: "Aged Inventory Surcharge" },
  { value: "Base Fulfillment Fee", label: "Base Fulfillment Fee" },
  { value: "Base Monthly Storage Fee", label: "Base Monthly Storage Fee" },
  { value: "FBA Fulfillment Fees", label: "FBA Fulfillment Fees" },
  {
    value: "FBA Inbound Placement Service Fee",
    label: "FBA Inbound Placement Service Fee",
  },
  {
    value: "Monthly Inventory Storage Fee",
    label: "Monthly Inventory Storage Fee",
  },
  { value: "Referral Fee", label: "Referral Fee" },
  { value: "Refund Administration Fee", label: "Refund Administration Fee" },
  {
    value: "Storage Utilization Surcharge",
    label: "Storage Utilization Surcharge",
  },
];

export const ADDITIONAL_METRICS_OPTIONS = [
  { value: "Net Sales", label: "Net Sales" },
  { value: "Net Proceeds", label: "Net Proceeds" },
  { value: "Contribution Profit", label: "Contribution Profit" },
  { value: "Contribution Margin", label: "Contribution Margin" },
];
