import React, { useState } from "react";
import { Card, Slider, InputNumber } from "antd";
import styles from "./styles.module.scss";
import { blockDefinitions } from "./utils";

interface CustomSelectProps {
  showMarketBlock?: boolean;
}

const SupplyBlocks = ({ showMarketBlock = false }: CustomSelectProps) => {
  const [values, setValues] = useState({
    leadTime: 4,
    safetyStock: 1,
    salesRate: 15,
    orderFrequency: 3,
  });

  const [activeMetric, setActiveMetric] = useState("Units");

  const handleMetricChange = (metric: string) => {
    setActiveMetric(metric);
  };

  const handleValueChange = (key: string, value: number | null) => {
    if (value !== null) {
      setValues((prev) => ({ ...prev, [key]: value }));
    }
  };

  return (
    <div className={styles.blocksContainer}>
      {blockDefinitions.map((block, index) => (
        <Card
          className={styles.customCard}
          bordered={false}
          key={index}
          bodyStyle={{ padding: 0 }}
        >
          <div className={styles.cardContent}>
            <h3 className={styles.cardTitle}>{block.title}</h3>
            {block.type === "segmented" ? (
              <div className={styles.segmentedControl}>
                <span
                  className={`${styles.metricOption} ${
                    activeMetric === "$" ? styles.active : ""
                  }`}
                  onClick={() => handleMetricChange("$")}
                >
                  $
                </span>
                <span
                  className={`${styles.metricOption} ${
                    activeMetric === "Units" ? styles.active : ""
                  }`}
                  onClick={() => handleMetricChange("Units")}
                >
                  Units
                </span>
              </div>
            ) : (
              <div className={styles.sliderContainer}>
                <InputNumber
                  min={0}
                  value={values[block.key]}
                  onChange={(value) => handleValueChange(block.key, value)}
                  className={styles.inputField}
                  controls={false}
                />
                <Slider
                  min={0}
                  value={values[block.key]}
                  onChange={(value) => handleValueChange(block.key, value)}
                  className={styles.customSlider}
                  railStyle={{
                    backgroundColor: "#2e2e2e",
                    height: "14px",
                    borderRadius: "32px",
                  }}
                  trackStyle={{
                    backgroundColor: "#5295e0",
                    height: "14px",
                    borderRadius: "32px",
                  }}
                  handleStyle={{
                    backgroundColor: "#ffffff",
                    marginTop: "0px",
                    borderRadius: "32px",
                    border: "2px solid #5295E0",
                  }}
                />
              </div>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default SupplyBlocks;
