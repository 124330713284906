// SalesPerformance.tsx
import React, { useEffect, useMemo, useState } from "react";
import { Card, Switch, Button } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styles from "./styles.module.scss";
import Setting from "@assets/icons/setting";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import {
  COLORS,
  legendColorMapping,
  nameMapping,
  renderCustomizedBar,
} from "./utils";
import { initialChecks } from "@pages/Dashboard/features/SalesPerformance/types";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Chart } from "../SalesPerformanceLineChart/index";
import { generateDateRangeArray } from "./utils";

export const COLORSCHART = [
  "#80C67A",
  "#1BB08D",
  "#fc6762",
  "#3a3b7c",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
];

export interface IAdsSales {
  id: string;
  name: string;
  value: string;
  originalValue: number;
  difference: string;
}

interface SupplyBlocksProps {
  adsSales?: IAdsSales;
}

const SalesPerformance: React.FC<SupplyBlocksProps> = () => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [checks, setChecks] = useState(initialChecks);
  const [dataSet, setDataSet] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [adsSales, setAdsSales] = useState<number[]>([]);
  const [metricsColors, setMetricsColors] = useState<{ [key: string]: string }>(
    {},
  );
  const { dateRange, setDateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
  }));

  const handleToggleView = (checked: boolean) => {
    setIsAdvancedView(checked);
  };

  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat("en", {
      day: "numeric",
      month: "short",
    });
  }, []);

  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) {
      const today = new Date();
      const endDate = today;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - 7);
      setDateRange({
        startDate,
        endDate,
      });
    }
  }, [dateRange, setDateRange]);

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const fetchedData = await getData(dateRange, checks);

        const transformedData = transformDataToObject(fetchedData);
        setData(transformedData);
        const salesData: number[] = fetchedData.sales || [];

        const allDates = generateDateRangeArray(
          dateRange.startDate,
          dateRange.endDate,
        );
        const mappedData = allDates.map((dateObj, index) => {
          const dateStr = dateFormatter.format(dateObj);

          return {
            date: dateStr,
            AdsSales: salesData[index] || 0,
            OrganicSales: Array.isArray(fetchedData.OrganicSales)
              ? fetchedData.OrganicSales[index] || 0
              : 0,
          };
        });
        setDataSet(mappedData);
        setAdsSales(salesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsDataLoading(false);
      }
    };

    if (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      Object.values(checks).some((value) => value)
    ) {
      fetchData();
    }
  }, [dateRange, checks, dateFormatter]);

  useEffect(() => {
    const colorMapping: { [key: string]: string } = {};
    Object.keys(checks).forEach((check, index) => {
      colorMapping[check] = COLORSCHART[index % COLORSCHART.length];
    });
    setMetricsColors(colorMapping);
  }, [checks]);

  return (
    <Card
      className={styles.cardPerfomance}
      bodyStyle={{
        padding: "5px",
      }}
    >
      <div className={styles.headerBetween}>
        <h1 className={styles.titleSales}>Sales Performance</h1>
        <div className={styles.switch__container}>
          <span className={styles.spanBar}>Show bars</span>
          <Switch checked={isAdvancedView} onChange={handleToggleView} />
          <span className={styles.spanBar}>Show lines</span>
        </div>
        <div className={styles.switch__container}>
          <Switch />
          <span className={styles.spanBar}>Show Forecast</span>
          <button
            className={styles.setting__button}
            onClick={() => setIsOpenModal(true)}
          >
            <Setting />
          </button>
        </div>
      </div>

      <div className={styles.legendContainer}>
        {!isAdvancedView ? (
          <>
            <div className={styles.legendItem}>
              <span
                className={styles.colorDot}
                style={{ backgroundColor: COLORS[0] }}
              ></span>
              <span className={styles.legendText}>Ad Sales</span>
            </div>
            <div className={styles.legendItem}>
              <span
                className={styles.colorDot}
                style={{ backgroundColor: COLORS[1] }}
              ></span>
              <span className={styles.legendText}>Organic Sales</span>
            </div>
          </>
        ) : null}
      </div>

      <ResponsiveContainer width="100%" height={350}>
        {!isAdvancedView ? (
          <BarChart
            data={dataSet}
            margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
            barCategoryGap="20%"
            barSize={32}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setActiveLabel(state.activeLabel as string);
              } else {
                setActiveLabel(null);
              }
            }}
            onMouseLeave={() => setActiveLabel(null)}
          >
            <CartesianGrid stroke="#1F1F20" vertical={false} />
            <XAxis
              dataKey="date"
              tick={{ fill: "white" }}
              axisLine={{ stroke: "#1F1F20" }}
            />
            <YAxis
              tick={{ fill: "white" }}
              axisLine={{ stroke: "#1F1F20" }}
              domain={[0, "auto"]}
            />
            <Tooltip
              content={({ active, payload, label }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  colorMetric={legendColorMapping}
                  label={label}
                  disableMapping={true}
                />
              )}
              cursor={false}
            />
            <Bar
              dataKey="AdsSales"
              name={nameMapping["AdsSales"]}
              shape={(props) =>
                renderCustomizedBar(
                  { ...props, dataKey: "AdsSales" },
                  activeLabel,
                )
              }
            />
            <Bar
              dataKey="OrganicSales"
              name={nameMapping["OrganicSales"]}
              shape={(props) =>
                renderCustomizedBar(
                  { ...props, dataKey: "OrganicSales" },
                  activeLabel,
                )
              }
            />
          </BarChart>
        ) : (
          <Chart
            checks={checks}
            metricsColors={metricsColors}
            data={data}
            setChecks={setChecks}
          />
        )}
      </ResponsiveContainer>
    </Card>
  );
};

export default SalesPerformance;
