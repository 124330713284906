import React from "react";
import { Rectangle } from "recharts";
import { CustomBarProps } from "src/types/customBar.type";
import { hexToRGBA } from "./utils";

export const CustomBar: React.FC<CustomBarProps> = ({
  x,
  y,
  width,
  height,
  fill,
  payload,
  activeLabel,
  showForecast,
}) => {
  const isActive = activeLabel ? payload.date === activeLabel : true;
  const opacity = isActive ? 1 : 0.3;

  const numericX = typeof x === "number" ? x : parseFloat(x || "0");
  const numericY = typeof y === "number" ? y : parseFloat(y || "0");

  if (showForecast) {
    const blockFill = hexToRGBA(fill, 0.5);
    const blockStroke = hexToRGBA(fill, 0.7);

    return (
      <Rectangle
        x={numericX}
        y={numericY}
        width={width}
        height={height}
        fill={blockFill}
        stroke={blockStroke}
        strokeWidth={2}
        strokeDasharray="4 4"
        opacity={opacity}
      />
    );
  }

  return (
    <Rectangle
      x={numericX}
      y={numericY}
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
    />
  );
};
