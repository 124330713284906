import styles from "./styles.module.scss";

export const SummaryAudit = () => {
  return (
    <div className={styles.container}>
      <h3>Summary of Audit</h3>
      <div className={styles.audit}>
        <ul>
          <li>
            Ad spend of <strong>$14 411</strong> representing{" "}
            <strong>81%</strong> of your Total Ad Spend, has resulted in zero
            sales.
          </li>
          <li>
            Ad Spend of <strong>$10 990</strong> representing{" "}
            <strong>6%</strong> of your Total Ad Spend, has generated
            unprofitable Ad Sales of{" "}
            <span className={styles.negative}>$1 751</span> with an average ACOS
            of <strong>57%</strong>.
          </li>
          <li>
            In contrast, only Ad Spend of <strong>$2 460</strong> or{" "}
            <strong>14%</strong> of your Total As Spend, has generated
            profitable Ad Sales <span className={styles.positive}>$48 844</span>{" "}
            with an ACOS of <strong>5%</strong>.
          </li>
          <li>
            Around <strong>25%</strong> of your Sponsored Product Ad Campaigns
            are experiencing ineffective budget management, resulting in
            depletion of funds by the middle of the day. Average Time in Budget
            <strong>76%</strong>. Estimated Monthly Lost Ad Sales between{" "}
            <span className={styles.negative}>$7 844</span> to{" "}
            <span className={styles.negative}>$19 609</span>.
          </li>
          <li>
            Moreover, there are over{" "}
            <span className={styles.negative}>2,500</span> Missing Keywords
            which we researched as being relevant, but not used in your Ad
            campaigns, which have a total monthly search volume of 2,282,000.
            The estimated monthly Lost Revenue from these Missing Keywords is $.
          </li>
        </ul>
        <h4>Audit Details:</h4>
        <div className={styles.audit__details}>
          Based on the last <strong>60 days (23 Sep - 23 Nov)</strong> of ad
          reports there are following observations:
          <ul>
            <li>
              By implementing our optimizations and AI automations, we can
              achieve
              <span className={styles.positive}> ~$0.6M+</span> of additional
              annual Ad Sales. As a result of Ad optimizations, we can achieve
              ~$0.3M of additional annual Organic Sales.
            </li>
            <li>
              I can reduce the current ACOS value from <strong>35%</strong> to
              <span className={styles.positive}> 14%.</span>
            </li>
            <li>
              Monthly Ad Spend savings are projected to exceed{" "}
              <strong>$5 334</strong> or <strong>$64 010</strong> annually -
              which I would allocate on TOF marketing campaigns to generate even
              more sales.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
