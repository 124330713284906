export const data = [
  {
    key: "1",
    sellerKPU: "01-UNHS-GFAD",
    month1: 121,
    month2: 68,
    month3: 90,
    month4: 13,
    month5: 31,
    month6: 120,
    month7: 68,
    month8: 120,
    month9: 120,
    month10: 120,
    month11: 120,
    month12: 68,
  },
  {
    key: "2",
    sellerKPU: "0A-WHFK-IQOP",
    month1: 20,
    month2: 18,
    month3: 13,
    month4: 120,
    month5: 120,
    month6: 68,
    month7: 13,
    month8: 13,
    month9: 31,
    month10: 31,
    month11: 31,
    month12: 18,
  },
  {
    key: "3",
    sellerKPU: "0J-JDLS-K12O",
    month1: 90,
    month2: 13,
    month3: 231,
    month4: 231,
    month5: 289,
    month6: 231,
    month7: 120,
    month8: 120,
    month9: 231,
    month10: 31,
    month11: 68,
    month12: 120,
  },
  {
    key: "4",
    sellerKPU: "0A-WHFK-IQOP",
    month1: 231,
    month2: 120,
    month3: 68,
    month4: 68,
    month5: 68,
    month6: 13,
    month7: 305,
    month8: 120,
    month9: 68,
    month10: 90,
    month11: 289,
    month12: 305,
  },
  {
    key: "5",
    sellerKPU: "01-UNHS-GFAD",
    month1: 13,
    month2: 231,
    month3: 305,
    month4: 31,
    month5: 13,
    month6: 31,
    month7: 231,
    month8: 289,
    month9: 305,
    month10: 13,
    month11: 31,
    month12: 31,
  },
  {
    key: "6",
    sellerKPU: "0A-WHFK-IQOP",
    month1: 68,
    month2: 13,
    month3: 31,
    month4: 90,
    month5: 68,
    month6: 90,
    month7: 68,
    month8: 18,
    month9: 31,
    month10: 90,
    month11: 68,
    month12: 68,
  },
  {
    key: "7",
    sellerKPU: "0J-JDLS-K12O",
    month1: 305,
    month2: 305,
    month3: 68,
    month4: 18,
    month5: 31,
    month6: 305,
    month7: 231,
    month8: 305,
    month9: 120,
    month10: 289,
    month11: 231,
    month12: 231,
  },
  {
    key: "8",
    sellerKPU: "0A-WHFK-IQOP",
    month1: 18,
    month2: 120,
    month3: 31,
    month4: 305,
    month5: 231,
    month6: 120,
    month7: 31,
    month8: 120,
    month9: 68,
    month10: 18,
    month11: 13,
    month12: 13,
  },
  {
    key: "9",
    sellerKPU: "0J-JDLS-K12O",
    month1: 121,
    month2: 68,
    month3: 68,
    month4: 31,
    month5: 13,
    month6: 90,
    month7: 68,
    month8: 120,
    month9: 120,
    month10: 68,
    month11: 68,
    month12: 120,
  },
  {
    key: "10",
    sellerKPU: "0A-WHFK-IQOP",
    month1: 120,
    month2: 31,
    month3: 68,
    month4: 68,
    month5: 68,
    month6: 13,
    month7: 31,
    month8: 31,
    month9: 68,
    month10: 13,
    month11: 13,
    month12: 31,
  },
  {
    key: "11",
    sellerKPU: "0J-JDLS-K12O",
    month1: 31,
    month2: 289,
    month3: 120,
    month4: 120,
    month5: 120,
    month6: 120,
    month7: 120,
    month8: 289,
    month9: 120,
    month10: 120,
    month11: 120,
    month12: 68,
  },
  {
    key: "12",
    sellerKPU: "01-UNHS-GFAD",
    month1: 120,
    month2: 68,
    month3: 120,
    month4: 31,
    month5: 289,
    month6: 68,
    month7: 120,
    month8: 305,
    month9: 120,
    month10: 305,
    month11: 120,
    month12: 120,
  },
  {
    key: "13",
    sellerKPU: "0A-WHFK-IQOP",
    month1: 289,
    month2: 90,
    month3: 90,
    month4: 289,
    month5: 305,
    month6: 120,
    month7: 68,
    month8: 68,
    month9: 120,
    month10: 68,
    month11: 68,
    month12: 120,
  },
  {
    key: "14",
    sellerKPU: "01-UNHS-GFAD",
    month1: 68,
    month2: 120,
    month3: 31,
    month4: 68,
    month5: 68,
    month6: 68,
    month7: 68,
    month8: 68,
    month9: 68,
    month10: 90,
    month11: 68,
    month12: 18,
  },
];
