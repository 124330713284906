export const columns = [
  {
    title: "Fulfillment Centers List",
    dataIndex: "center",
    key: "center",
    align: "left",
  },
  {
    title: "Shipment Status",
    dataIndex: "status",
    key: "status",
    align: "left",
  },
  {
    title: "Current Stock",
    dataIndex: "stock",
    key: "stock",
    align: "left",
    render: (text) => <div className="table-stock">{text}</div>,
  },
  {
    title: "Quantity Awaiting",
    dataIndex: "awaiting",
    key: "awaiting",
    align: "left",
    render: (text) => <div className="table-awaiting">{text}</div>,
  },
  {
    title: "Sold Quantity",
    dataIndex: "sold",
    key: "sold",
    align: "left",
    render: (text) => <div className="table-sold">{text}</div>,
  },
];

export const dataSource = [
  {
    key: "1",
    center: "SMF1",
    status: "CLOSED",
    stock: 140,
    awaiting: "-",
    sold: 10,
  },
  {
    key: "2",
    center: "SBD1",
    status: "CLOSED",
    stock: 20,
    awaiting: "-",
    sold: 10,
  },
  {
    key: "3",
    center: "SCK4",
    status: "CLOSED",
    stock: 20,
    awaiting: "-",
    sold: 10,
  },
  {
    key: "4",
    center: "ONT8",
    status: "IN_TRANSIT",
    stock: "-",
    awaiting: 60,
    sold: 10,
  },
  {
    key: "5",
    center: "ONT8",
    status: "RECEIVING",
    stock: "-",
    awaiting: 60,
    sold: 10,
  },
];
