import classNames from "classnames";
import ControlledSelect from "../../../ControlledSelect";
import InputCheckbox from "../../../InputCheckbox";
import styles from "./styles.module.scss";

const UpscalePhoto = () => {
  return (
    <>
      <label className="input input--sm">
        <div className="input__label">Upscale to</div>
        <div className="input__row">
          <ControlledSelect placeholder={"Upscale to"} options={["2X", "3X"]} />
        </div>
      </label>
      <InputCheckbox
        label="Enhance quality"
        onChange={() => {}}
        defaultValue={true}
      />
      <button
        className={classNames(
          "button--sm button--m-0",
          styles.btnCro,
          styles.primary,
        )}
      >
        Apply
      </button>
    </>
  );
};

export default UpscalePhoto;
