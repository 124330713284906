import classNames from "classnames";
import styles from "./styles.module.scss";

export type WhatIfTab =
  | "Campaigns"
  | "Ad Type"
  | "Funnel"
  | "Target Type"
  | "Targeting"
  | "Match type"
  | "Performance";

const typeTabs: WhatIfTab[] = [
  "Campaigns",
  "Performance",
  "Ad Type",
  "Target Type",
  "Targeting",
  "Match type",
  "Funnel",
];

export const TabButton = ({
  label,
  isSelected,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: React.ElementType;
}) => (
  <button
    className={classNames(styles.tab, isSelected && styles.active)}
    onClick={onClick}
  >
    {label}
  </button>
);

export const TabsTypeList = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: WhatIfTab;
  setSelectedTab: (tab: WhatIfTab) => void;
}) => {
  return (
    <div className={styles.tabs}>
      {typeTabs.map((tab) => (
        <TabButton
          key={tab}
          label={tab}
          isSelected={selectedTab === tab}
          onClick={() => setSelectedTab(tab)}
        />
      ))}
    </div>
  );
};
