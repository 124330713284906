import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../Accordion";
import { TaskType } from "src/types/workflow.types";
import { TaskRegistry } from "src/constants/workflow.constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";
import styles from "./taskMenu.module.scss";

export function TaskMenu() {
  return (
    <div className={styles.container}>
      <Accordion type="multiple" defaultValue={["extraction"]}>
        <AccordionItem value="extraction">
          <AccordionTrigger className={styles.title}>
            Data extraction
          </AccordionTrigger>
          <AccordionContent>
            <div className={styles.content}>
              <TaskMenuBtn taskType={TaskType.PAGE_TO_HTML} />
              <TaskMenuBtn taskType={TaskType.EXTRACT_TEXT_FROM_ELEMENT} />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

function TaskMenuBtn({ taskType }: { taskType: TaskType }) {
  const task = TaskRegistry[taskType];
  if (!task) return null;

  const onDragStart = (event: React.DragEvent, type: TaskType) => {
    event.dataTransfer.setData("application/reactflow", type);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={styles.button}
          draggable
          aria-label={`Drag ${task.label} task`}
          role="button"
          onDragStart={(e) => onDragStart(e, taskType)}
        >
          <div className={styles.buttonContent}>
            <task.icon size={20} aria-hidden="true" />
            <span className={styles.buttonLabel}>{task.label}</span>
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <p>Drag to add {task.label}</p>
      </TooltipContent>
    </Tooltip>
  );
}
