import { Card } from "antd";
import styles from "./styles.module.scss";
import StraightLine from "@assets/icons/straightLine";
import Ladder from "@assets/icons/ladder";
import { data } from "./utils";
import classNames from "classnames";

const FunnelBlocks = () => {
  return (
    <div className={styles.funnelContainer}>
      {data.map((block, blockIndex) => (
        <Card
          key={blockIndex}
          bordered={false}
          className={styles.customCard}
          bodyStyle={{ padding: "0px" }}
        >
          <h3 className={styles.cardTitle}>{block.title}</h3>
          <div className={styles.metricContainer}>
            <div className={styles.straightLineRight}>
              <span>100%</span>
              <StraightLine />
            </div>
            {block.metrics.map((metric, idx) => (
              <div
                key={idx}
                className={classNames(styles.metricRow, {
                  [styles.firstMetricRow]: idx === 0,
                })}
                style={{
                  justifyContent: metric.label ? "space-between" : "center",
                }}
              >
                {metric.label && (
                  <span className={styles.metricLabel}>{metric.label}</span>
                )}
                {metric.label === "Impressions" ? (
                  <div
                    className={styles.metricValueBoxBig}
                    style={{
                      backgroundColor: block.color,
                    }}
                  >
                    <span className={styles.metricValueRed}>
                      {metric.value}
                    </span>
                  </div>
                ) : metric.label === "Clicks" ||
                  metric.label === "Add to cart" ? (
                  <div
                    className={classNames(styles.value, {
                      [styles.valueFirst]: metric.label === "Clicks",
                      [styles.valueSecond]: metric.label === "Add to cart",
                    })}
                  >
                    <span className={styles.metricValue}>
                      {metric.leftValue ? metric.leftValue : metric.value}
                    </span>
                    <div
                      className={styles.metricValueBox}
                      style={{
                        backgroundColor: metric.blockColor || block.color,
                        width: metric.label === "Clicks" ? "44px" : "6px",
                      }}
                    />
                    <span className={styles.metricValue}>{metric.value}</span>
                  </div>
                ) : metric.label === "Units Sold" ? (
                  <div
                    className={classNames({
                      [styles.metricValueEnd]: block.title === "Total",
                      [styles.metricValueEnd2]: block.title === "ASIN",
                    })}
                  >
                    <Ladder />
                    {metric.valueEnd}
                  </div>
                ) : (
                  <div>{metric.value}</div>
                )}
              </div>
            ))}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default FunnelBlocks;
