import type { TableColumnsType } from "antd";
import amazonIcon from "@assets/icons/AmazonIcon.png";
import USIcon from "@assets/icons/US.png";
import CanadaIcon from "@assets/icons/Canada.png";
import BrasilIcon from "@assets/icons/Brasil.png";
import ShopifyIcon from "@assets/icons/Shopify.png";
import tiktokIcon from "@assets/img/TikTok.png";
import { DataType } from ".";
// Імпортуємо іконку налаштувань (Settings)
import Settings from "@assets/img/settings";

export function getColumns(
  openConfigModal: (e: React.MouseEvent<HTMLButtonElement>) => void,
): TableColumnsType<DataType> {
  return [
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      className: "header-marketplace",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
          borderTopLeftRadius: 12,
          paddingLeft: "50px",
        },
      }),
      onCell: () => ({
        style: {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      key: "marketplace",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.icon && (
            <img src={record.icon} alt={text} className="imgMarket" />
          )}
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Total Sales",
      dataIndex: "grossRevenue",
      className: "header-gross-revenue",
      key: "grossRevenue",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      render: (value) => <span className="currency-cell">${value}</span>,
    },
    {
      title: "Total Spend",
      dataIndex: "expenses",
      className: "header-expenses",
      key: "expenses",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      render: (value) => <span className="currency-cell">-${value}</span>,
    },
    {
      title: "ROAS",
      dataIndex: "cogs",
      className: "header-cogs",
      key: "cogs",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      render: (value) => <span className="currency-cell">${value}</span>,
    },
    {
      title: "Contribution Profit",
      dataIndex: "netProfit",
      className: "header-net-profit",
      key: "netProfit",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      render: (value) => <span className="currency-cell">${value}</span>,
    },
    {
      title: "Total Margin",
      dataIndex: "margin",
      className: "header-margin",
      key: "margin",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      render: (value) => <span>{value}%</span>,
    },
    {
      title: "ROI",
      dataIndex: "roi",
      className: "header-roi",
      key: "roi",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
      render: (value) => <span>{value}</span>,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Refunds</span>
          <button
            className="headerCell"
            onClick={(e) => {
              e.stopPropagation();
              openConfigModal(e);
            }}
          >
            <Settings />
          </button>
        </div>
      ),
      dataIndex: "refunds",
      className: "header-refunds",
      key: "refunds",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#2E2E2E",
          color: "#ffffff99",
          borderTopRightRadius: 12,
        },
      }),
      onCell: () => ({
        style: {
          backgroundColor: "#1F1F20",
          padding: "16px",
        },
      }),
    },
  ];
}

export const data: DataType[] = [
  {
    key: "1",
    marketplace: "Amazon",
    icon: amazonIcon,
    grossRevenue: "915,396.61",
    expenses: "388,110.72",
    cogs: "00.00",
    netProfit: "527,285.90",
    margin: "57",
    roi: "0",
    refunds: "59,638",
    children: [
      {
        key: "1-1",
        marketplace: "amazon.com",
        icon: USIcon,
        grossRevenue: "915,396.61",
        expenses: "388,110.72",
        cogs: "00.00",
        netProfit: "527,285.90",
        margin: "57",
        roi: "0",
        refunds: "59,638",
      },
      {
        key: "1-2",
        marketplace: "amazon.ca",
        icon: CanadaIcon,
        grossRevenue: "915,396.61",
        expenses: "388,110.72",
        cogs: "00.00",
        netProfit: "527,285.90",
        margin: "57",
        roi: "0",
        refunds: "59,638",
      },
      {
        key: "1-3",
        marketplace: "amazon.com.br",
        icon: BrasilIcon,
        grossRevenue: "915,396.61",
        expenses: "388,110.72",
        cogs: "00.00",
        netProfit: "527,285.90",
        margin: "57",
        roi: "0",
        refunds: "59,638",
      },
    ],
  },
  {
    key: "2",
    marketplace: "Shopify",
    icon: ShopifyIcon,
    grossRevenue: "915,396.61",
    expenses: "388,110.72",
    cogs: "00.00",
    netProfit: "527,285.90",
    margin: "57",
    roi: "0",
    refunds: "59,638",
  },
  {
    key: "3",
    marketplace: "TikTok",
    icon: tiktokIcon,
    grossRevenue: "915,396.61",
    expenses: "388,110.72",
    cogs: "00.00",
    netProfit: "527,285.90",
    margin: "57",
    roi: "0",
    refunds: "59,638",
  },
];
