import React, { useState } from "react";
import { Table } from "antd";
import "./MarketplaceTable.css";
import BottomArrow from "@assets/icons/bottomArrow";
import { getColumns, data } from "./utils";
import { useModalStore } from "src/store/modal.state";

export interface DataType {
  key: string;
  marketplace: string;
  grossRevenue: string;
  expenses: string;
  cogs: string;
  netProfit: string;
  margin: string;
  roi: string;
  refunds: string;
  icon?: string;
  children?: DataType[];
}

const MarketplaceTable: React.FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });

  const openConfigModal = (event: React.MouseEvent) => {
    const { right, bottom, width } = (
      event.currentTarget as HTMLButtonElement
    ).getBoundingClientRect();
    setModalPosition({ x: right, y: bottom, width });
    setIsConfigModalOpen(true);
    openModal();
  };

  const columns = getColumns(openConfigModal);

  return (
    <div className="custom-table-wrapper">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          defaultExpandedRowKeys: ["1"],
          expandRowByClick: false,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record.key !== "1") {
              return null;
            }
            return (
              <button
                onClick={(evt) => onExpand(record, evt)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 0.2s",
                  marginRight: 8,
                }}
              >
                <BottomArrow />
              </button>
            );
          },
        }}
        rowClassName={(record, index) => {
          let classNames = `row-class-${index} `;
          if (record.key.indexOf("-") > -1) {
            classNames += "child-row ";
            if (record.marketplace.toLowerCase().includes("amazon")) {
              classNames += "amazon-child";
            }
          } else {
            classNames += "parent-row ";
            classNames +=
              record.marketplace.toLowerCase().replace(/\s/g, "-") + "-parent";
          }
          return classNames;
        }}
        className="marketplace-table"
      />
    </div>
  );
};

export default MarketplaceTable;
