export default () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.875 9.375H10.625V4.375H11.25C11.913 4.375 12.5489 4.63839 13.0178 5.10723C13.4866 5.57607 13.75 6.21196 13.75 6.875C13.75 7.04076 13.8158 7.19973 13.9331 7.31694C14.0503 7.43415 14.2092 7.5 14.375 7.5C14.5408 7.5 14.6997 7.43415 14.8169 7.31694C14.9342 7.19973 15 7.04076 15 6.875C14.999 5.88076 14.6035 4.92753 13.9005 4.22449C13.1975 3.52145 12.2442 3.12603 11.25 3.125H10.625V1.875C10.625 1.70924 10.5592 1.55027 10.4419 1.43306C10.3247 1.31585 10.1658 1.25 10 1.25C9.83424 1.25 9.67527 1.31585 9.55806 1.43306C9.44085 1.55027 9.375 1.70924 9.375 1.875V3.125H8.75C7.75544 3.125 6.80161 3.52009 6.09835 4.22335C5.39509 4.92661 5 5.88044 5 6.875C5 7.86956 5.39509 8.82339 6.09835 9.52665C6.80161 10.2299 7.75544 10.625 8.75 10.625H9.375V15.625H8.125C7.46196 15.625 6.82607 15.3616 6.35723 14.8928C5.88839 14.4239 5.625 13.788 5.625 13.125C5.625 12.9592 5.55915 12.8003 5.44194 12.6831C5.32473 12.5658 5.16576 12.5 5 12.5C4.83424 12.5 4.67527 12.5658 4.55806 12.6831C4.44085 12.8003 4.375 12.9592 4.375 13.125C4.37603 14.1192 4.77145 15.0725 5.47449 15.7755C6.17753 16.4785 7.13076 16.874 8.125 16.875H9.375V18.125C9.375 18.2908 9.44085 18.4497 9.55806 18.5669C9.67527 18.6842 9.83424 18.75 10 18.75C10.1658 18.75 10.3247 18.6842 10.4419 18.5669C10.5592 18.4497 10.625 18.2908 10.625 18.125V16.875H11.875C12.8696 16.875 13.8234 16.4799 14.5267 15.7767C15.2299 15.0734 15.625 14.1196 15.625 13.125C15.625 12.1304 15.2299 11.1766 14.5267 10.4733C13.8234 9.77009 12.8696 9.375 11.875 9.375ZM8.75 9.375C8.08696 9.375 7.45107 9.11161 6.98223 8.64277C6.51339 8.17393 6.25 7.53804 6.25 6.875C6.25 6.21196 6.51339 5.57607 6.98223 5.10723C7.45107 4.63839 8.08696 4.375 8.75 4.375H9.375V9.375H8.75ZM11.875 15.625H10.625V10.625H11.875C12.538 10.625 13.1739 10.8884 13.6428 11.3572C14.1116 11.8261 14.375 12.462 14.375 13.125C14.375 13.788 14.1116 14.4239 13.6428 14.8928C13.1739 15.3616 12.538 15.625 11.875 15.625Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
