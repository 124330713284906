import React from "react";
import styles from "./TargetingProfiles.module.scss";
import { profiles } from "./utils";

const TargetingProfiles: React.FC = () => {
  return (
    <div className={styles.container}>
      {profiles.map((profile, index) => (
        <div key={index} className={styles.profileBlock}>
          <div className={styles.card}>
            <h1 className={styles.title}>{profile.title}</h1>
            {profile.data.map((item, i) => (
              <div key={i} className={styles.row}>
                <div className={styles.labelColumn}>
                  <p className={styles.label}>{item.label}</p>
                </div>
                <div className={styles.valueColumn}>
                  <p className={styles.value}>{item.value}</p>
                </div>
                <div className={styles.numberColumn}>
                  <p className={styles.number}>{item.number}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TargetingProfiles;
