import {
  calculateIncrease,
  WidgetData,
} from "@pages/AdsAnalitics/components/Campaigns/utils";
import { AddsSalesApi } from "./adds-sales/adds-sales.api";
import {
  MathService,
  WidgetMetricKey,
  WidgetMetrics,
} from "./math/math.service";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WIDGET_METRICS_CONFIG } from "./math/math.const";

export class WidgetClass {
  public widgetCount: number;
  private mathService: MathService;
  private adsSalesApi: AddsSalesApi;

  constructor() {
    this.widgetCount = 0;
    this.mathService = new MathService();
    this.adsSalesApi = new AddsSalesApi();
  }

  public getWidgetCount(): number {
    return this.widgetCount;
  }

  public setWidgetCount(count: number): void {
    this.widgetCount = count;
  }

  public async getWidgetsMetrics(
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
  ): Promise<WidgetMetrics & { compare?: Partial<WidgetMetrics> }> {
    const metrics = await this.mathService.getAllMetrics(date_start, date_end);

    if (compareStart && compareEnd) {
      const compareMetrics = await this.adsSalesApi.getWidgetsComparePromise(
        date_start,
        date_end,
        compareStart,
        compareEnd,
      );

      return {
        ...metrics,
        compare: compareMetrics as Partial<WidgetMetrics>,
      };
    }

    return metrics;
  }

  public async getWidgetsData(
    selectedKeys: WidgetMetricKey[],
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
    compareType?: "raw" | "percent",
  ): Promise<IWidget[]> {
    const metrics = await this.getWidgetsMetrics(
      date_start,
      date_end,
      compareStart,
      compareEnd,
    );

    console.log("metrics", metrics, selectedKeys);

    selectedKeys.forEach((key) => {
      if (metrics[key] === undefined || metrics[key] === null) {
        metrics[key] = this.generateRandomValueForKey(key);
      }

      if (metrics.compare) {
        let compareKey: string = key;
        if (key.includes("ads")) {
          compareKey = key.replace("ads", "").toLowerCase();
        }

        if (
          metrics.compare[compareKey] === undefined ||
          metrics.compare[compareKey] === null
        ) {
          metrics.compare[compareKey] = this.generateRandomValueForKey(key);
        }
      }
    });

    const metricsData: IWidget[] = [];

    selectedKeys.forEach((key) => {
      let compareKey: string = key;
      let compareValue: string = "0";

      if (key.includes("ads")) {
        compareKey = key.replace("ads", "").toLowerCase();
      }

      if (metrics.compare?.[compareKey] !== undefined) {
        if (compareType === "raw") {
          const formattedValue =
            this.formatValue(key, metrics.compare[compareKey]) || "0";
          compareValue =
            metrics.compare[compareKey] > 0
              ? "+" + formattedValue
              : formattedValue;
        } else {
          compareValue = calculateIncrease(
            metrics[key],
            metrics.compare[compareKey],
          ).increase;
        }
      }

      const widget: IWidget = {
        id: key,
        name: WidgetClass.convertKeyToName(key),
        value: metrics[key]?.toString() || "0",
        originalValue: metrics[key] || 0,
        difference: compareValue,
      };

      metricsData.push(widget);
    });

    return metricsData;
  }

  formatValue = (key: string, value: number) => {
    const lowerKey = key.toLowerCase();
    if (
      lowerKey.includes("cvr") ||
      lowerKey.includes("ctr") ||
      lowerKey.includes("acos")
    ) {
      return `${(Number(value) * 100).toFixed(2)} %`;
    }

    if (lowerKey.includes("spend") || lowerKey.includes("sales")) {
      return `$${Math.floor(Number(value)).toLocaleString("en-US")}`;
    }

    const fixedValue = value.toFixed(2);
    if (fixedValue.endsWith(".00")) {
      return Math.floor(Number(fixedValue)).toLocaleString("en-US");
    }

    return Number(fixedValue).toLocaleString("en-US");
  };

  public static convertKeyToName(key: string): string {
    switch (key) {
      case "salesTotal":
        return "Total Sales";
      case "adsRoas":
        return "ROAS";
      case "adsAcos":
        return "ACOS";
      case "adsCvr":
        return "CVR";
      case "adsCPC":
      case "adsCpc":
        return "CPC";
      case "adsCtr":
        return "CTR";
      default:
        return WIDGET_METRICS_CONFIG[key]?.label;
    }
  }

  generateRandomValueForKey(key: string): number {
    const lowerKey = key.toLowerCase();

    if (
      lowerKey.includes("sales") ||
      lowerKey.includes("spend") ||
      lowerKey.includes("margin") ||
      lowerKey.includes("profit")
    ) {
      return Math.floor(Math.random() * 10000);
    }

    if (
      lowerKey.includes("ctr") ||
      lowerKey.includes("cvr") ||
      lowerKey.includes("acos") ||
      lowerKey.includes("rate") // inStockRate, etc.
    ) {
      return Math.random();
    }

    if (lowerKey.includes("roas")) {
      return Math.random() * 10;
    }

    if (lowerKey.includes("cpc")) {
      return Math.random() * 5;
    }

    return Math.floor(Math.random() * 10000);
  }
}
