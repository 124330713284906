import { Handle, Position, useEdges } from "@xyflow/react";
import { ReactNode } from "react";
import cn from "classnames";
import { TaskParam } from "src/types/workflow.types";
import { NodeParamField } from "../NodeParamField";
import { ColorForHandle } from "../common";
import useFlowValidation from "@hooks/useFlowValidation";
import styles from "./nodeInputs.module.scss";

export function NodeInputs({ children }: { children: ReactNode }) {
  return <div className={styles.nodeInputs}>{children}</div>;
}

export function NodeInput({
  input,
  nodeId,
}: {
  input: TaskParam;
  nodeId: string;
}) {
  const { invalidInputs } = useFlowValidation();
  const edges = useEdges();
  const isConnected = edges.some(
    (edge) => edge.target === nodeId && edge.targetHandle === input.name,
  );
  const hasErrors = invalidInputs
    .find((node) => node.nodeId === nodeId)
    ?.inputs.find((invalidInput) => invalidInput === input.name);

  return (
    <div
      className={cn(styles.nodeInput, {
        [styles["nodeInput--hasErrors"]]: hasErrors,
      })}
    >
      <NodeParamField param={input} nodeId={nodeId} disabled={isConnected} />
      {!input.hideHandle && (
        <Handle
          id={input.name}
          isConnectable={!isConnected}
          type="target"
          position={Position.Left}
          className={cn(
            styles.nodeInput__handle,
            styles[`nodeInput__handle--${input.type}`],
          )}
          style={{ borderColor: ColorForHandle[input.type] }}
        />
      )}
    </div>
  );
}
