export default () => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 42.5V47.5C10.5 48.8261 11.0268 50.0979 11.9645 51.0355C12.9021 51.9732 14.1739 52.5 15.5 52.5H45.5C46.8261 52.5 48.0979 51.9732 49.0355 51.0355C49.9732 50.0979 50.5 48.8261 50.5 47.5V42.5M18 22.5L30.5 10M30.5 10L43 22.5M30.5 10V40"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
