export default () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2005 5.00028V9.00028C14.2005 9.13289 14.1478 9.26006 14.054 9.35383C13.9603 9.4476 13.8331 9.50028 13.7005 9.50028H9.70047C9.56787 9.50028 9.44069 9.4476 9.34692 9.35383C9.25315 9.26006 9.20047 9.13289 9.20047 9.00028C9.20047 8.86767 9.25315 8.74049 9.34692 8.64672C9.44069 8.55296 9.56787 8.50028 9.70047 8.50028H12.4936L7.70047 3.70715L5.55422 5.85403C5.50779 5.90052 5.45264 5.9374 5.39194 5.96256C5.33124 5.98772 5.26618 6.00067 5.20047 6.00067C5.13477 6.00067 5.0697 5.98772 5.009 5.96256C4.9483 5.9374 4.89316 5.90052 4.84672 5.85403L0.346723 1.35403C0.252903 1.26021 0.200195 1.13296 0.200195 1.00028C0.200195 0.867596 0.252903 0.740348 0.346723 0.646528C0.440544 0.552708 0.567791 0.5 0.700473 0.5C0.833155 0.5 0.960403 0.552708 1.05422 0.646528L5.20047 4.7934L7.34672 2.64653C7.39316 2.60004 7.4483 2.56316 7.509 2.538C7.5697 2.51284 7.63477 2.49988 7.70047 2.49988C7.76618 2.49988 7.83124 2.51284 7.89194 2.538C7.95264 2.56316 8.00779 2.60004 8.05422 2.64653L13.2005 7.7934V5.00028C13.2005 4.86767 13.2532 4.74049 13.3469 4.64672C13.4407 4.55296 13.5679 4.50028 13.7005 4.50028C13.8331 4.50028 13.9603 4.55296 14.054 4.64672C14.1478 4.74049 14.2005 4.86767 14.2005 5.00028Z"
        fill="#FE5858"
      />
    </svg>
  );
};
