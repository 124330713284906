// import { useReactFlow } from '@xyflow/react'
import { ExecuteBtn } from "../ExecuteBtn/index.tsx";
import { SaveBtn } from "../SaveBtn/index.tsx";
import styles from "./topbar.module.scss";

export function Topbar() {
  //   const { toObject } = useReactFlow()
  //   const data = JSON.stringify(toObject())
  const workflowId = "1";
  return (
    <div className={styles.topbar}>
      <div className={styles.title}>Workflow</div>
      <div className={styles.actions}>
        <ExecuteBtn workflowId={workflowId} />
        <SaveBtn />
      </div>
    </div>
  );
}
