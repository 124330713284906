export const newTitles = [
  "FBA In Stock Rate",
  "Shipping Costs",
  "Safety Stock",
  "Available Capital",
  "Frozen Capital",
  "Lead Time",
  "Forecasting Accuracy",
  "Lost Sales (CFO)",
  "Contribution Margin",
  "DOI",
  "Storage Costs",
  "Inventory Turnover",
  "Lost Sales (CFO)",
  "FBT In Stock Rate",
  "3PL In Stock Rate",
];

export const blockDefinitions = [
  { title: "Lead Time, days", key: "leadTime" },
  { title: "Safety Stock, days", key: "safetyStock" },
  { title: "Sales Rate, %", key: "salesRate" },
  { title: "Orders Frequency, days", key: "orderFrequency" },
  { title: "Metric", key: "metric", type: "segmented" },
];
