export const dataSource = [
  { key: "1", asin: "B007Q46COG", orders: 104 },
  { key: "2", asin: "B008RRX8I4", orders: 96 },
  { key: "3", asin: "B01940DKKS", orders: 17 },
  { key: "4", asin: "B019B1X2EO", orders: 35 },
  { key: "5", asin: "B019B1X2EY", orders: 4 },
  { key: "6", asin: "B019B1X2HG", orders: 2 },
  { key: "7", asin: "B0719TWJ1T", orders: 11 },
];

export const totalOrders = dataSource.reduce(
  (sum, item) => sum + item.orders,
  0,
);

export const columns = [
  {
    title: "ASIN",
    dataIndex: "asin",
    key: "asin",
    align: "left",
    className: "asin-column",
    onHeaderCell: () => ({
      style: {
        backgroundColor: "#2E2E2E",
        color: "#ffffff99",
      },
    }),
    onCell: () => ({
      style: {
        padding: "14px",
      },
    }),
  },
  {
    title: "Orders",
    dataIndex: "orders",
    key: "orders",
    align: "right",
    className: "orders-column",
    onHeaderCell: () => ({
      style: {
        backgroundColor: "#2E2E2E",
        color: "#ffffff99",
      },
    }),
    onCell: () => ({
      style: {
        padding: "12px",
      },
    }),
  },
];
