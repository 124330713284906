import "./styles.module.scss";
import { Tooltip } from "../../../../components/ExpandebleTable/Tooltip";
import TooltipIcon from "@assets/icons/tooltip";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Minus from "@assets/icons/minus";
import Star from "@assets/icons/StarKeyword";
import { KeyTooltip } from "@components/ExpandebleTable/KeyTooltip";
import { Keyword } from "src/store/keyword.state";

export const formatData = ({
  data,
  openTooltip,
  openedTooltip,
  keyTooltip,
  setKeyTooltip,
  keyword,
  setKeywords,
  withKeywords,
  parentAsin,
}: {
  data: any[];
  openTooltip?: (title: string) => void;
  openedTooltip?: string;
  keyTooltip?: string;
  setKeyTooltip?: (title: string) => void;
  keyword?: Keyword;
  setKeywords?: (keywords: Keyword) => void;
  withKeywords?: boolean;
  parentAsin?: string;
}) => {
  if (!data || !data.length) return [];
  return data.map((item) => {
    const newItem = { ...item };
    let color: string;

    if (newItem.performance) {
      switch (newItem.performance) {
        case "Good":
          color = "green";
          break;
        case "Mid":
          color = "yellow";
          break;
        case "Poor":
          color = "darkRed";
          break;
        case "No Sales":
          color = "red";
          break;
      }
    }

    // Process percentage and trend formatting as before
    Object.keys(newItem).forEach((key) => {
      if (typeof newItem[key] === "number") {
        switch (key) {
          case "ad_spend":
          case "ad_spend_what_if":
          case "ad_sales":
          case "ad_sales_what_if":
          case "shareSpend":
          case "cpc":
            newItem[key] =
              `$${(Math.floor(newItem[key] * 100) / 100).toLocaleString("en-US")}`;
            break;
          case "acos":
          case "acos_what_if":
          case "roi":
          case "tacos":
          case "ad_ctr":
          case "ad_ctr_what_if":
          case "ad_cvr":
          case "roas":
          case "roas_what_if":
            newItem[key] = `${(newItem[key] * 100).toFixed(2)}%`;
            break;
          default:
            newItem[key] = newItem[key].toLocaleString("en-US");
            break;
        }
      }
      if (
        color &&
        key !== "performance" &&
        key !== "key" &&
        key !== "campaign" &&
        key !== "children" &&
        key !== "level"
      ) {
        newItem[key] = (
          <div className={classNames(styles.cell, color)}>{newItem[key]}</div>
        );
      }

      if (
        typeof newItem[key] === "object" &&
        newItem[key].value &&
        newItem[key].percentage
      ) {
        const { value, percentage } = newItem[key];

        let trend;
        if (percentage.startsWith("+")) {
          trend = "up";
        } else if (percentage.startsWith("-")) {
          trend = "down";
        } else {
          trend = "neutral";
        }

        newItem[key] = (
          <div className={classNames(styles.cell, color)}>
            <span>{value}</span>
            <span className={classNames(styles.percentage, styles[trend])}>
              {trend === "neutral" && (
                <>
                  <Minus />
                  {percentage.replace(/[+-]/g, "")}
                </>
              )}
            </span>
          </div>
        );
      }
    });

    // Determine the current ASIN and conditions
    const isAsin = newItem.typeAd?.includes("asin");
    const isNyl = newItem.campaign?.includes("Nyl");
    const isCampaign = newItem?.campaign?.includes("Campaign Name");

    if (isAsin) {
      newItem.typeAd = withTooltip(
        openTooltip,
        openedTooltip,
        newItem.typeAd,
        true,
      );
    }

    if (isNyl && withKeywords) {
      newItem.action = withCustomKey(
        setKeyTooltip,
        keyTooltip,
        newItem.campaign,
        keyword,
        setKeywords,
      );
    }

    if (isNyl || isCampaign) {
      newItem.campaign = withTooltip(
        openTooltip,
        openedTooltip,
        newItem.campaign,
        false,
        isCampaign && parentAsin,
      );
    }

    // Recursively process children, passing down the current ASIN
    if (newItem.children) {
      const asin = newItem.campaign;
      newItem.children = formatData({
        data: newItem.children,
        openTooltip,
        openedTooltip,
        keyTooltip,
        setKeyTooltip,
        keyword,
        setKeywords,
        withKeywords,
        parentAsin: asin,
      });
    }

    return newItem;
  });
};

const withCustomKey = (
  openTooltip: (title: string) => void,
  openedTooltip: string,
  title: string,
  keyword: Keyword,
  setKeywords: (keywords: Keyword) => void,
) => {
  const closeTooltip = () => {
    openTooltip("");
  };

  return (
    <>
      <button
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (openedTooltip === title) {
            openTooltip("");
          } else {
            openTooltip(title);
          }
        }}
        className={styles.star}
      >
        <Star
          fill={keyword && keyword.name.includes(title) ? "#fff" : "#7C7C78"}
        />
      </button>
      {openedTooltip === title && (
        <KeyTooltip
          close={closeTooltip}
          keywordName={title}
          keyword={keyword}
          setKeywords={setKeywords}
        />
      )}
    </>
  );
};

const withTooltip = (
  openTooltip: (title: string) => void,
  openedTooltip: string,
  title: string,
  withKeys?: boolean,
  value?: string,
) => {
  const closeTooltip = () => {
    openTooltip("");
  };
  return (
    <div className={styles.cell__tooltip}>
      <span>{title}</span>
      <button
        onMouseEnter={() => {
          openTooltip(title);
        }}
        onMouseLeave={closeTooltip}
      >
        <TooltipIcon />
      </button>
      {openedTooltip === title && (
        <Tooltip title={value ? value : title} withKeys={withKeys} />
      )}
    </div>
  );
};

export const performanceColumns = [
  {
    title: "Type Ad",
    dataIndex: "performance",
    key: "performance",
    width: 500,
    fixed: "left" as const,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

export const targetingColumns = [
  {
    title: "Targeting",
    dataIndex: "target",
    key: "target",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

export const matchColumns = [
  {
    title: "Match Type",
    dataIndex: "match_type",
    key: "match_type",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

export const targetTypeColumns = [
  {
    title: "Target Type",
    dataIndex: "target",
    key: "target",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

export const campaignsColumns = [
  {
    title: "Campaign",
    dataIndex: "campaign_name",
    key: "campaign_name",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

export const adTypeColumns = [
  {
    title: "Ad Type",
    dataIndex: "ad_type",
    key: "ad_type",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

export const funnelColumns = [
  {
    title: "Funnel",
    dataIndex: "funnel",
    key: "funnel",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  // {
  //   title: "Ad Spend Share",
  //   dataIndex: "spend_share",
  //   key: "spend_share",
  //   width: 140,
  // },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  // {
  //   title: "Ad Sales Share",
  //   dataIndex: "ad_sales_share",
  //   key: "ad_sales_share",
  //   width: 140,
  // },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

const removeWithNyleCol = (col: { title: string }) =>
  !col.title.match(/what if/i);

export const performanceColumnsWithoutNyle =
  performanceColumns.filter(removeWithNyleCol);

export const targetingColumnsWithoutNyle =
  targetingColumns.filter(removeWithNyleCol);

export const matchColumnsWithoutNyle = matchColumns.filter(removeWithNyleCol);

export const targetTypeColumnsWithoutNyle =
  targetTypeColumns.filter(removeWithNyleCol);

export const campaignsColumnsWithoutNyle =
  campaignsColumns.filter(removeWithNyleCol);

export const adTypeColumnsWithoutNyle = adTypeColumns.filter(removeWithNyleCol);

export const funnelColumnsWithoutNyle = funnelColumns.filter(removeWithNyleCol);
