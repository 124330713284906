export default () => {
  return (
    <svg
      width="8"
      height="29"
      viewBox="0 0 8 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1" y1="0.375" x2="0.999999" y2="28.375" stroke="#5C5C5A" />
      <line x1="0.5" y1="13.875" x2="6.5" y2="13.875" stroke="#5C5C5A" />
      <line x1="7" y1="0.375" x2="7" y2="28.375" stroke="#5C5C5A" />
    </svg>
  );
};
