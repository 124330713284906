import React, { useState } from "react";
import styles from "./CombinedElement.module.scss";
import SegmentedSelect from "../SegmentedSelect";
import DropdownSelect from "../DropdownSelect";
import Demographic from "../Demographic";
import { demographicData } from "../DemographicsTable/utils";

const DemographicsBlock: React.FC = () => {
  const [selectedDemographic, setSelectedDemographic] = useState("Age Group");

  return (
    <div className={styles.combinedContainer}>
      <DropdownSelect onSelect={setSelectedDemographic} />
      <Demographic data={demographicData[selectedDemographic]} />
      <SegmentedSelect />
    </div>
  );
};

export default DemographicsBlock;
