import { useExecutionPlan } from "@hooks/useExecutionPlan";
import { PlayIcon } from "lucide-react";
import styles from "./executeBtn.module.scss";

export const ExecuteBtn = ({ workflowId }: { workflowId: string }) => {
  const generate = useExecutionPlan();
  // TODO: ADD VARIANTS
  return (
    <button
      className={styles.button}
      onClick={() => {
        const plan = generate();
        console.log("---plan---");
        console.table(plan);
      }}
    >
      <PlayIcon className={styles.icon} size={16} />
      Execute {workflowId}
    </button>
  );
};
