import { Checkbox, Modal } from "antd";
import styles from "./styles.module.scss";
import "../modal.scss";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";

interface SettingsModalProps {
  open: boolean;
  title: string;
  availableKeys: string[];
  selectedKeys: string[];
  onClose: () => void;
  onChange: (keys: string[]) => void;
  resetToDefault: () => void;
  onApply: () => void;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({
  open,
  title,
  availableKeys,
  selectedKeys,
  onClose,
  onChange,
  resetToDefault,
  onApply,
}) => {
  const toggleKey = (key: string) => {
    onChange(
      selectedKeys.includes(key)
        ? selectedKeys.filter((k) => k !== key)
        : [...selectedKeys, key],
    );
  };

  return (
    <Modal open={open} onCancel={onClose} className="modal modal-small">
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Choose Metrics</h2>
          <span>Choose metrics for «{title}»</span>
        </div>
        <div className={styles.keys}>
          {availableKeys.map((key) => (
            <div key={key} className={styles.key}>
              <Checkbox
                onChange={() => toggleKey(key)}
                checked={selectedKeys.includes(key)}
              />
              <span>{WIDGET_METRICS_CONFIG[key].label}</span>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <button onClick={resetToDefault}>reset to default</button>
          <button onClick={onApply}>Apply</button>
        </div>
      </div>
    </Modal>
  );
};
