import { WidgetMetricKey } from "@services/math/math.service";
import { IWidget } from "../types/dashboard.types";
import { initializeCalendarData } from "src/utils/initialCalendarData";
import { create } from "zustand";
import { WIDGET_METRIC_KEYS } from "@services/math/math.const";

export interface IDashboardState {
  widgets: WidgetMetricKey[];
  availableWidgets: WidgetMetricKey[];
  modalWidgets: IWidget[];
  customSelectedWidgets: string[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  compareType: "raw" | "percent";
  setCompareType: (type: "raw" | "percent") => void;
  setWidgets: (widgets: WidgetMetricKey[]) => void;
  setAvailableWidgets: (availableWidgets: WidgetMetricKey[]) => void;
  setModalWidgets: (modalWidgets: IWidget[]) => void;
  setCustomSelectedWidgets: (customWidgets: string[]) => void;
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => void;
  reset: () => void;
}

const { today, monday } = initializeCalendarData();

export const useFinanceStore = create<IDashboardState>((set) => ({
  widgets: [],
  availableWidgets: [...WIDGET_METRIC_KEYS],
  modalWidgets: [],
  customSelectedWidgets: [
    "availableCapital",
    "borrowedCapital",
    "lostSales",
    "grossMargin",
    "opex",
    "contribution",
    "profitEbitda",
  ],
  dateRange: {
    endDate: today,
    startDate: monday,
  },
  compareType: "raw",
  setCompareType: (type: "raw" | "percent") => set({ compareType: type }),
  setWidgets: (widgets: WidgetMetricKey[]) => set({ widgets }),
  setAvailableWidgets: (availableWidgets: WidgetMetricKey[]) => {
    if (!Array.isArray(availableWidgets)) {
      return;
    }
    set({ availableWidgets });
  },
  setModalWidgets: (modalWidgets: IWidget[]) => set({ modalWidgets }),
  setCustomSelectedWidgets: (customWidgets: string[]) => {
    if (!Array.isArray(customWidgets)) {
      return;
    }
    set({ customSelectedWidgets: customWidgets });
  },
  setDateRange: (range: { startDate: Date; endDate: Date }) =>
    set({ dateRange: range }),
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => {
    set({ widgets, availableWidgets });
  },
  reset: () =>
    set({
      widgets: [],
      availableWidgets: [],
      modalWidgets: [],
      dateRange: { startDate: new Date(), endDate: new Date() },
      customSelectedWidgets: [],
    }),
}));
