import React from "react";
import "./styles.css";
import { metrics } from "./constant";

const AdditionalMetrics: React.FC = () => {
  return (
    <div className="metrics-container">
      {metrics.map((metric, index) => (
        <div key={index} className={`metric-card`}>
          <div className="metric-title">{metric.title}</div>
          <div className="metric-value">{metric.value}</div>
        </div>
      ))}
    </div>
  );
};

export default AdditionalMetrics;
