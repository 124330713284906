import styles from "./DemographicDataOverview.module.scss";
import DemographicsBlock from "../DemographicsBlock";
import DemographicsTable from "../DemographicsTable";
import TargetingProfiles from "../TargetingProfiles";
import DemographicTypeShare from "../DemographicTypeShare";

const DemographicDataOverviewBlock: React.FC = () => {
  return (
    <div className={styles.combinedContainer}>
      <DemographicsBlock />
      <DemographicsTable />
      <TargetingProfiles />
      <DemographicTypeShare />
    </div>
  );
};

export default DemographicDataOverviewBlock;
