// MetricCards.tsx
import React, { useState } from "react";
import PencilIcon from "@assets/icons/PencilIcon";
import Clock from "@assets/icons/clock";

import styles from "./Metrics.module.scss";
import { HitsoryInteractionModal } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend/HistoryInteraction";
import { MetricCard } from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder/MetricCard";

interface MetricCardProps {
  title: string;
  value: number;
  goal?: number;
  isGoalSet?: boolean;
  id: string;
  metricKey?: string;
  name: string;
  originalValue: number;
  showGoals?: boolean;
  showSetGoalsButton?: boolean;
  goalValues?: number[];
  onSetGoalsClick?: (metric: {
    id: string;
    metricKey?: string;
    name: string;
    originalValue: number;
  }) => void;
}

export const MetricCards: React.FC<MetricCardProps> = ({
  title,
  value,
  goal,
  goalValues,
  id,
  metricKey,
  name,
  originalValue,
  showGoals,
  showSetGoalsButton,
  isGoalSet,
  onSetGoalsClick,
}) => {
  const [historyIteration, setHistoryIteration] = useState(false);
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  return (
    <div className={styles.metric}>
      <div className={styles.metrics}>
        <MetricCard
          metric={{
            id: id,
            key: metricKey || "",
            name: title,
            value: String(value),
            originalValue: value,
            goalValues: goalValues || [],
          }}
          metricColor="#5295E0"
          goalValue={goal ?? 0}
        />

        {isGoalSet && (
          <div className={styles.goalSetIndicator}>
            <span>Goal Set</span>
          </div>
        )}

        {showSetGoalsButton && (goal === undefined || goal <= 0) && (
          <button
            className={styles.setGoalButton}
            onClick={() => {
              onSetGoalsClick?.({
                id,
                metricKey,
                name,
                originalValue,
              });
            }}
          >
            Set up goals
          </button>
        )}

        {showGoals && (
          <div className={styles.goals__container}>
            <span>
              Goal:{" "}
              {goal !== undefined
                ? goal.toLocaleString("en-US")
                : originalValue.toLocaleString("en-US")}
            </span>
            <button
              onClick={() => {
                onSetGoalsClick?.({
                  id,
                  metricKey,
                  name,
                  originalValue,
                });
              }}
              className={styles.pencilButton}
            >
              <PencilIcon />
            </button>
          </div>
        )}
      </div>

      {historyIteration && (
        <button
          className={styles.view__iteration}
          onClick={() => {
            setIsOpenHistory(!isOpenHistory);
          }}
        >
          <Clock /> View 3 previous iterations
        </button>
      )}

      <HitsoryInteractionModal
        isOpen={isOpenHistory}
        setIsOpen={setIsOpenHistory}
      />
    </div>
  );
};
