export const renderToCanvas = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  resultBlockId: string,
) => {
  // TODO: GET BLOCK WITHOUT PARENT ELEMENT
  const resultBlock = document.getElementById(resultBlockId).parentElement;
  if (!resultBlock || !canvasRef.current) {
    console.error("Result block or canvas not found");
    return;
  }

  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    console.error("Canvas context not found");
    return;
  }

  const resultBlockRect = resultBlock.getBoundingClientRect();
  const scaleX = canvas.width / resultBlockRect.width;
  const scaleY = canvas.height / resultBlockRect.height;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const draggableElements = Array.from(
    resultBlock.querySelectorAll("[data-draggable='draggable']"),
  );

  const elementsWithZIndex = draggableElements.map((element, index) => {
    const zIndexParent = element.closest(".zIndexCopy");
    const zIndex = zIndexParent
      ? parseInt(window.getComputedStyle(zIndexParent).zIndex || "0", 10)
      : 0;

    return {
      element,
      zIndex: index === 0 ? 4 : zIndex || 4,
      isMain: index === 0,
    };
  });

  elementsWithZIndex.sort((a, b) => {
    if (a.zIndex !== b.zIndex) return a.zIndex - b.zIndex;
    return a.isMain ? 1 : -1;
  });

  return Promise.all(
    elementsWithZIndex.map(({ element, zIndex, isMain }) => {
      const elementRect = element.getBoundingClientRect();

      const left = (elementRect.left - resultBlockRect.left) * scaleX;
      const top = (elementRect.top - resultBlockRect.top) * scaleY;
      const width = elementRect.width * scaleX;
      const height = elementRect.height * scaleY;

      const image = element.querySelector("[data-draggable='image']");

      if (image === null) {
        return;
      }

      const computedStyle = window.getComputedStyle(image);
      const backgroundImage = computedStyle.backgroundImage;

      return new Promise<void>((res) => {
        if (backgroundImage !== "none") {
          const imageUrl = backgroundImage.slice(5, -2);
          const img = new Image();
          img.src = imageUrl;

          img.onload = () => {
            ctx.drawImage(img, left, top, width, height);
            res();
          };

          img.onerror = () => {
            console.error("Failed to load image:", imageUrl);
          };
        } else {
          console.warn("No backgroundImage found for element");
          res();
        }
      });
    }),
  );
};
