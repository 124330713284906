import { useState } from "react";
import "./index.css";
import { ProductTab } from "./product-tab";
import Slider from "./slider";
import Table from "./table";
import { useModalStore } from "src/store/modal.state";
import MetricModal from "../SalesPerformance/MetricModal";
import { initialChecks } from "../SalesPerformance/types";

export const Products = () => {
  const [selectedTab, setSelectedTab] = useState<string>("tiles");
  const isModalOpen = useModalStore((state) => state.isModalOpen);
  const closeModal = useModalStore((state) => state.closeModal);
  const [checks, setChecks] = useState(initialChecks);

  return (
    <div className="products-section">
      <ProductTab setSelectedTab={(el: string) => setSelectedTab(el)} />
      {selectedTab === "tiles" ? <Slider /> : <Table />}
      <MetricModal
        isOpen={isModalOpen}
        onClose={closeModal}
        checks={checks}
        setChecks={setChecks}
      />
    </div>
  );
};
