import { IWidget } from "@pages/Dashboard/types/dashboard.types";

export const baseMetrics: IWidget[] = [
  {
    id: "1",
    metricKey: "ContributionMarginBase",
    name: "Contribution Margin  Base",
    value: "18.9%",
    originalValue: 18.9,
  },
  {
    id: "2",
    metricKey: "ContributionProfitBase",
    name: "Contribution Profit Base",
    value: "$799,329",
    originalValue: 799329,
  },
];

export const whatIfMetrics = [
  {
    id: "3",
    metricKey: "WhatIfMetric1",
    name: "Contribution Margin What If",
    originalValue: 19.9,
  },
  {
    id: "4",
    metricKey: "WhatIfMetric2",
    name: "Contribution Profit What If",
    originalValue: 1127276,
  },
];
