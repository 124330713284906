import { Button } from "antd";
import { useState, useEffect, DragEvent, ChangeEvent } from "react";
import styles from "./styles.module.scss";
import CloseModal from "src/assets/icons/closeModal.svg";
import SearchIcon from "src/assets/icons/SearchIcon.svg";
import greySquare from "src/assets/icons/greySquare.svg";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import MetricsList from "./metricsList";
import CloseIcon from "@components/CloseIcon";
import { WidgetMetricKey } from "@services/math/math.service";
import { WidgetClass } from "@services/widget.service";

interface ConfigModalProps {
  buttonWidth: number;
  closeModal: () => void;
  setWidgets: (widgets: WidgetMetricKey[]) => void;
  maxSelectedWidgets: number;
  selectedWidgets?: WidgetMetricKey[];
  setSelectedWidgets?: (widgets: WidgetMetricKey[]) => void;
  isRestrictedRoute?: boolean;
}

interface IWidgetExtended extends IWidget {
  selected?: boolean;
}

export const ConfigModal = ({
  buttonWidth,
  closeModal,
  setWidgets,
  maxSelectedWidgets,
  selectedWidgets,
  setSelectedWidgets,
  isRestrictedRoute,
}: ConfigModalProps) => {
  const [widgetList, setWidgetList] =
    useState<WidgetMetricKey[]>(selectedWidgets);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  useEffect(() => {
    setWidgetList(selectedWidgets as WidgetMetricKey[]);
  }, [selectedWidgets]);

  const handleDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    setDraggingIndex(index);
    e.dataTransfer.setData("widgetIndex", index.toString());
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>, hoverIndex: number) => {
    e.preventDefault();
    if (draggingIndex === null || draggingIndex === hoverIndex) return;

    const reorderedWidgets = [...widgetList];
    const draggedWidget = reorderedWidgets[draggingIndex];
    reorderedWidgets.splice(draggingIndex, 1);
    reorderedWidgets.splice(hoverIndex, 0, draggedWidget);

    setWidgetList(reorderedWidgets);
    setDraggingIndex(hoverIndex);
  };

  const handleDragEnd = () => setDraggingIndex(null);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleToggleMetric = (metric: WidgetMetricKey) => {
    setWidgetList((prevList) => {
      const exists = prevList.some((item) => item === metric);
      if (exists) {
        return prevList.filter((item) => item !== metric);
      } else if (prevList.length < maxSelectedWidgets) {
        return [...prevList, metric];
      }
      return prevList;
    });
  };

  const handleApply = () => {
    setWidgets(widgetList);
    setSelectedWidgets(widgetList);
    closeModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.rumpBox}>
        <div
          className={styles.rump}
          style={{
            width: `calc(30% - ${Math.round(buttonWidth)}px)`,
            marginLeft: "auto",
            marginRight: "-20px",
          }}
        />
        <div
          className={styles.divider}
          style={{ width: `${Math.round(buttonWidth + 20)}px` }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Current Metrics</p>
          <button onClick={closeModal} className={styles.close}>
            <img
              width={8}
              height={8}
              src={CloseModal}
              alt="Close"
              className={styles.cancelIcon}
            />
            Cancel
          </button>
        </div>
        <div className={styles.gridBox}>
          {widgetList.map((widget, index) => (
            <div
              key={widget}
              className={styles.dashboardItem}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={handleDragEnd}
            >
              <div className={styles.itemContent}>
                <div className={styles.itemContentHeader}>
                  <div className={styles.itemDragHandle}>
                    <img alt="Square" src={greySquare} width={14} height={14} />
                  </div>
                  <CloseIcon onClick={() => handleToggleMetric(widget)} />
                </div>
                <div className={styles.itemContentName}>
                  {WidgetClass.convertKeyToName(widget)}
                </div>
              </div>
            </div>
          ))}
        </div>
        {!isRestrictedRoute && (
          <div className={styles.metrics}>
            <div className={styles.metricsHeader}>
              <p className={styles.title}>Add Metrics</p>
              <div className={styles.searchContainer}>
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search by name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img
                  src={SearchIcon}
                  alt="Search"
                  className={styles.searchIcon}
                />
              </div>
            </div>
            <MetricsList
              searchQuery={searchQuery}
              onToggleMetric={handleToggleMetric}
              currentMetrics={widgetList}
              maxSelectedWidgets={maxSelectedWidgets}
            />
          </div>
        )}

        {isRestrictedRoute && (
          <MetricsList
            searchQuery={searchQuery}
            onToggleMetric={handleToggleMetric}
            currentMetrics={widgetList}
            maxSelectedWidgets={maxSelectedWidgets}
          />
        )}
      </div>
      <Button
        type="primary"
        shape="round"
        className={styles.applyBtn}
        onClick={handleApply}
      >
        Apply
      </Button>
    </div>
  );
};
