export const data = [
  {
    title: "Base Scenario",
    rows: [
      {
        label: "1.Total Sales",
        value: "$4,231,116",
        children: [
          { label: "1.1 Ad Sales", value: "$1,407,527" },
          { label: "1.2 Organic Sales", value: "$496,434" },
          { label: "1.3 Other Sales", value: "$496,434" },
        ],
      },
      { label: "2.Cogs", value: "$1,311,646", red: true },
      {
        label: "3.Overhead Cost",
        value: "$2,120,141",
        red: true,
        children: [
          { label: "3.1 Amazon Fees", value: "$1,565,513", red: true },
          { label: "3.2 Spend", value: "$554,628", red: true },
        ],
      },
      { label: "4.Contribution Profit", value: "$799,329" },
      { label: "5.Contribution Margin", value: "18.9%" },
      { label: "6.OPEX", value: "$253,867", red: true },
      { label: "7.EBITDA", value: "$545,462" },
    ],
  },
  {
    title: "What If Scenario",
    rows: [
      {
        label: "1.Total Sales",
        value: "$5,672,010",
        children: [
          { label: "1.1 Ad Sales", value: "$2,578,986" },
          { label: "1.2 Organic Sales", value: "$765,870" },
          { label: "1.3 Other Sales", value: "$2,327,155" },
        ],
      },
      { label: "2.Cogs", value: "$1,758,323", red: true },
      {
        label: "3.Overhead Cost",
        value: "$2,786,411",
        red: true,
        children: [
          { label: "3.1 Amazon Fees", value: "$2,098,644", red: true },
          { label: "3.2 Spend", value: "$686,767", red: true },
        ],
      },
      { label: "4.Contribution Profit", value: "$1,127,276" },
      { label: "5.Contribution Margin", value: "19.9%" },
      { label: "6.OPEX", value: "$253,867", red: true },
      { label: "7.EBITDA", value: "$873,409" },
    ],
  },
];
