import { CheckWidget } from "@components/CheckWidget";
import { AdsWidget } from "../../utils";
import { Checks } from "../CampaignChart/utils";
import { useEffect, useState } from "react";

interface WidgetsProps {
  checks: Checks;
  setChecks: (checks: string) => void;
  widgetsData: AdsWidget[];
}

export const METRICS_OPTIONS = [
  "TACOS",
  "Organic Rank",
  "Search Term Ad Impression Rank",
  "Time in Budget",
  "TOS Ad Impression Share",
];

export const Widgets = ({ checks, setChecks, widgetsData }: WidgetsProps) => {
  const [showedWidgets, setShowedWidgets] = useState<AdsWidget[]>(widgetsData);
  const [options, setOptions] = useState(METRICS_OPTIONS);

  const deleteWidget = (title: string) => {
    setShowedWidgets((prevList) =>
      prevList.filter((widget) => {
        return (
          widget.title.split(" ").join("").toLowerCase() !==
          title.split(" ").join("").toLowerCase()
        );
      }),
    );
  };

  const deleteOption = (value: string) => {
    setOptions(
      options.filter(
        (option) =>
          option.split(" ").join("").toLowerCase() !==
          value.split(" ").join("").toLowerCase(),
      ),
    );
  };

  const addWidget = (title: string) => {
    const widget = widgetsData.find((widget) => widget.title === title);
    if (widget) {
      setShowedWidgets([...showedWidgets, widget]);
    }
  };

  useEffect(() => {
    [
      "tacos",
      "organicRank",
      "searchTermAdImpressionRank",
      "timeInBudget",
      "tosAdImpressionShare",
    ].forEach((title) => {
      deleteWidget(title);
    });
  }, []);

  const handleRaplaceWidget = (title: string, titleToDelete: string) => {
    deleteWidget(titleToDelete);
    deleteOption(title.toLowerCase());
    addWidget(title);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "10px",
      }}
    >
      {showedWidgets.map((card) => (
        <CheckWidget
          key={card.title}
          title={card.title}
          value={card.value.toLocaleString()}
          increase={card.increase.toLocaleString()}
          increaseDirection={card.increaseDirection as "up" | "down"}
          isChecked={
            checks[
              card.title === "Units Sold"
                ? "unitSolds"
                : card.title.toLowerCase().replace(" ", "")
            ]
          }
          onChange={setChecks}
          color={card.color}
          options={options}
          onReplace={handleRaplaceWidget}
        />
      ))}
    </div>
  );
};
