import create from "zustand";

export interface MetricData {
  difference: string;
  name: string;
  value: number | null;
}

export type Metrics = {
  aovData: MetricData;
  salesTotalData: MetricData;
  adsImpressionsData: MetricData;
  adsClicksData: MetricData;
  adsOrdersData: MetricData;
  adsUnitsSoldData: MetricData;
  adsSalesData: MetricData;
  adsSpendData: MetricData;
  adsAcosData: MetricData;
  adsRoasData: MetricData;
  adsCpcData: MetricData;
  adsCvrData: MetricData;
  adsCtrData: MetricData;
  organicImpressionsData: MetricData;
  organicClicksData: MetricData;
  organicCartAddsData: MetricData;
  organicUnitsSoldData: MetricData;
  organicCvrData: MetricData;
  organicCtrData: MetricData;
};

interface MathState {
  metrics: Metrics;
  setMetricData: (name: keyof Metrics, data: number) => void;
}

export const useMathStore = create<MathState>((set) => ({
  metrics: {
    aovData: {
      name: "AOV",
      value: null,
      difference: "+0.02",
    },
    salesTotalData: {
      name: "Total Sales",
      value: null,
      difference: "+0.02",
    },
    adsImpressionsData: {
      name: "Ad Impressions",
      value: null,
      difference: "+0.02",
    },
    adsClicksData: {
      name: "Ad Clicks",
      value: null,
      difference: "+0.02",
    },
    adsOrdersData: {
      name: "Ad Orders",
      value: null,
      difference: "-0.02",
    },
    adsUnitsSoldData: {
      name: "Ad Units Sold",
      value: null,
      difference: "+0.02",
    },
    adsSalesData: {
      name: "Ad Sales",
      value: null,
      difference: "-0.02",
    },
    adsSpendData: {
      name: "Ad Spend",
      value: null,
      difference: "+0.02",
    },
    adsAcosData: {
      name: "ACOS",
      value: null,
      difference: "+0.02",
    },
    adsRoasData: {
      name: "ROAS",
      value: null,
      difference: "+0.02",
    },
    adsCpcData: {
      name: "CPC",
      value: null,
      difference: "-0.02",
    },
    adsCvrData: {
      name: "Ad CVR",
      value: null,
      difference: "+0.02",
    },
    adsCtrData: {
      name: "Ad CTR",
      value: null,
      difference: "+0.02",
    },
    organicImpressionsData: {
      name: "Organic Impressions",
      value: null,
      difference: "+0.02",
    },
    organicClicksData: {
      name: "Organic Clicks",
      value: null,
      difference: "+0.02",
    },
    organicCartAddsData: {
      name: "Organic Cart Adds",
      value: null,
      difference: "+0.02",
    },
    organicUnitsSoldData: {
      name: "Organic Units Sold",
      value: null,
      difference: "+0.02",
    },
    organicCvrData: {
      name: "Organic CVR",
      value: null,
      difference: "+0.02",
    },
    organicCtrData: {
      name: "Organic CTR",
      value: null,
      difference: "+0.02",
    },
  },
  setMetricData: (name, data) =>
    set((state) => ({
      metrics: {
        ...state.metrics,
        [name]: { ...state.metrics[name], value: data },
      },
    })),
}));
