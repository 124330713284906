import React from "react";
import { Modal, Upload, Button } from "antd";
import "./CustomModalStyles.scss";
import Back from "@assets/icons/back";
import Download from "@assets/icons/download";

interface UploadModalProps {
  isModalOpen: boolean;
  onCancel: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ isModalOpen, onCancel }) => {
  return (
    <Modal
      open={isModalOpen}
      onCancel={onCancel}
      footer={null}
      width={600}
      className="custom-upload-modal"
      closeIcon={
        <span
          style={{
            width: "14px",
            height: "14px",
            fontSize: "20px",
            color: "#FFFFFF",
          }}
        >
          ×
        </span>
      }
    >
      <div className="upload-container">
        <div className="upload">
          <Back />
          <h2 className="upload-title">Upload</h2>
        </div>
        <div className="upload-dropzone">
          <Upload.Dragger
            name="file"
            multiple={false}
            accept=".csv"
            showUploadList={false}
          >
            <p className="upload-icon">
              <Download />
            </p>
            <p className="upload-text">
              Drag & drop files or <span className="browse-link">Browse</span>
            </p>
            <p className="upload-subtext">Supported format: CSV</p>
          </Upload.Dragger>
        </div>
        <div className="upload-footer">
          <Button type="default" onClick={onCancel} className="cancel-button">
            CANCEL
          </Button>
          <Button type="primary" disabled className="add-button">
            ADD DATA ENTRY
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
