import Arrow from "@assets/icons/Arrow";
import styles from "./styles.module.scss";
import { useState } from "react";
import classNames from "classnames";
import { RangeCard } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend/RangeCard";
import info from "@assets/icons/i.svg";
import { useSectionStore } from "src/store/croModule.state";

interface Discount {
  initial: number;
  [key: string]: number;
}

const SubAccordion: React.FC<{
  title: string;
  discount: Discount;
  open: boolean;
  onToggle: () => void;
  onChange: (value: [number, number], field?: string) => void;
  fields?: { label: string; key: string }[];
  rangeLabel?: string;
  withoutRange?: boolean;
  innerRangeLabel?: string;
}> = ({
  title,
  discount,
  open,
  onToggle,
  onChange,
  fields,
  rangeLabel,
  innerRangeLabel,
  withoutRange,
}) => {
  const { isWhatIf } = useSectionStore();

  return (
    <div
      className={classNames(
        styles.inner__accordion,
        open && styles.inner__accordion_open,
      )}
    >
      <button className={styles.accordion__button} onClick={onToggle}>
        <Arrow />
      </button>
      <RangeCard
        title={title}
        label={rangeLabel}
        value={discount.initial}
        onChange={(val) => onChange(val)}
        min={0}
        isWhatIf={isWhatIf}
        isBlack
        withoutRange={withoutRange}
      >
        {open &&
          fields?.map(({ label, key }) => (
            <RangeCard
              key={key}
              title={label}
              label={innerRangeLabel}
              value={discount[key]}
              onChange={(val) => onChange(val, key)}
              min={0}
              isWhatIf={isWhatIf}
              rowValue
            />
          ))}
      </RangeCard>
    </div>
  );
};

export const BrandTailored = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [acquisitionOpen, setAcquisitionOpen] = useState(false);
  const [retentionOpen, setRetentionOpen] = useState(false);
  const [reEngagementOpen, setReEngagementOpen] = useState(false);
  const [crossSellOpen, setCrossSellOpen] = useState(false);

  const [acquisitionDiscount, setAcquisitionDiscount] = useState<Discount>({
    initial: 0,
    abandoners: 0,
    highPotential: 0,
    highIntent: 0,
  });
  const [retentionDiscount, setRetentionDiscount] = useState<Discount>({
    initial: 0,
    topTear: 0,
    repeat: 0,
    recent: 0,
    highSpend: 0,
    promising: 0,
  });
  const [reEngagementDiscount, setReEngagementDiscount] = useState<Discount>({
    initial: 0,
    declining: 0,
    atRisk: 0,
    decliningTopTier: 0,
  });
  const [crossSell, setCrossSell] = useState<Discount>({
    initial: 0,
    complementary: 0,
  });

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<Discount>>) =>
    (value: [number, number], field?: string) => {
      const currentValue = value[0] === 0 ? value[1] : value[0];
      setter((prev) => ({
        ...prev,
        [field ?? "initial"]: currentValue,
      }));
    };

  return (
    <div className={styles.accordion}>
      <button
        className={styles.accordion__button}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className={styles.accordion__title}>
          Brand Tailored Promotions <img src={info} alt="" />
        </h2>
        <span className={styles.accordion__icon}>
          <Arrow />
        </span>
      </button>
      <div
        className={classNames(
          styles.accortion__content,
          isOpen && styles.accordion__content_open,
        )}
      >
        <SubAccordion
          title="New Customer Acquisition"
          discount={acquisitionDiscount}
          open={acquisitionOpen}
          onToggle={() => setAcquisitionOpen(!acquisitionOpen)}
          onChange={handleChange(setAcquisitionDiscount)}
          rangeLabel="Discount, %"
          innerRangeLabel="Discount, %"
          fields={[
            {
              label: "Brand cart abandoners (90 days)",
              key: "abandoners",
            },
            { label: "High Potential New Customers", key: "highPotential" },
            { label: "High Intent Customers", key: "highIntent" },
          ]}
        />
        <SubAccordion
          title="Customer Retention"
          discount={retentionDiscount}
          open={retentionOpen}
          onToggle={() => setRetentionOpen(!retentionOpen)}
          onChange={handleChange(setRetentionDiscount)}
          rangeLabel="Discount, %"
          innerRangeLabel="Discount, %"
          fields={[
            { label: "Top-tier", key: "topTear" },
            { label: "Repeat customers", key: "repeat" },
            { label: "Recent customers", key: "recent" },
            { label: "High spend customers", key: "highSpend" },
            { label: "Promising", key: "promising" },
          ]}
        />
        <SubAccordion
          title="Re-engagement"
          discount={reEngagementDiscount}
          open={reEngagementOpen}
          onToggle={() => setReEngagementOpen(!reEngagementOpen)}
          onChange={handleChange(setReEngagementDiscount)}
          rangeLabel="Discount, %"
          innerRangeLabel="Discount, %"
          fields={[
            { label: "Declining Promising", key: "declining" },
            { label: "At Risk", key: "atRisk" },
            { label: "Declining Top Tier", key: "decliningTopTier" },
          ]}
        />
        <SubAccordion
          title="Cross-sell"
          discount={crossSell}
          open={crossSellOpen}
          onToggle={() => setCrossSellOpen(!crossSellOpen)}
          onChange={handleChange(setCrossSell)}
          withoutRange
          innerRangeLabel="Discount, %"
          fields={[
            {
              label: "Complementary Products Remarketing ",
              key: "complementary",
            },
          ]}
        />
      </div>
    </div>
  );
};
