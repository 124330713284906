import React, { useRef, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Dropdown } from "antd";
import Settings from "@assets/icons/Settings";
import "./styles.scss";
import { createDropdownContent, getOption } from "./utils";

const mockData = [
  { name: "Contribution Profit", value: 795300, color: "#255FA0" },
  { name: "COGS", value: -446700, color: "#FE5858" },
  { name: "Amazon Fees", value: -533100, color: "#FE5858" },
  { name: "Spend", value: -133100, color: "#FE5858" },
  { name: "Ad Sales", value: 1171100, color: "#80C67A" },
  { name: "Organic Sales", value: 269400, color: "#80C67A" },
  { name: "What if Contribution Profit", value: -1127300, color: "#255FA0" },
];

export const WhatIfChart: React.FC = () => {
  const chartRef = useRef<ReactECharts>(null);
  const [isSummarize, setIsSummarize] = useState(false);
  const [columns, setColumns] = useState(
    mockData.map((item) => ({ name: item.name, checked: true })),
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleColumn = (name: string) => {
    setColumns((prev) =>
      prev.map((col) =>
        col.name === name ? { ...col, checked: !col.checked } : col,
      ),
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dropdownContent = createDropdownContent(columns, toggleColumn, () =>
    setIsDropdownVisible(false),
  );

  return (
    <div className="chart__container">
      <div className="chart-header">
        <h3>What-If Analysis</h3>
        <div className="header-actions">
          <div className="toggle">
            <label className={`toggle-switch ${isSummarize ? "active" : ""}`}>
              <input
                type="checkbox"
                checked={isSummarize}
                onChange={() => setIsSummarize(!isSummarize)}
              />
              <span className="slider" />
            </label>
          </div>
          <p className="summarize-toggle">Summarize columns</p>
          <Dropdown
            overlay={dropdownContent}
            trigger={["click"]}
            placement="bottomRight"
            open={isDropdownVisible}
            onOpenChange={(visible) => setIsDropdownVisible(visible)}
          >
            <button className="settings-button">
              <Settings />
            </button>
          </Dropdown>
        </div>
      </div>
      <ReactECharts
        ref={chartRef}
        option={getOption(mockData, columns, isSummarize)}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};
