import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { useEffect, useMemo, useState } from "react";
import { WidgetClass } from "@services/widget.service";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { Tab } from "@pages/Dashboard/features/Widgets/Tab";
import { useTranslation } from "react-i18next";
import { useSuplyStore } from "@pages/Dashboard/store/suplyChain.state";
import styles from "./styles.module.scss";
import { useCanvaStore } from "src/store/canva/canva.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { useSectionStore } from "src/store/croModule.state";
import SupplyBlocks from "./SupplyChain";
import { ExecutiveSummary } from "@components/ExecutiveSummary";
import SalesPerformance from "./SelesPerfomance";
import InventoryPerformance from "./InventoryPerformance";
import Scheduler from "./Scheduler";
import FinanceFlowChart from "./FinanceFlowChart";
import { newTitles } from "./SupplyChain/utils";

export function SupplyChainBlock() {
  const { t } = useTranslation();
  const { setIsWhatIf } = useCanvaStore();
  const { setIsWhatIfEnabled: setAnaliticsWhatIf } = useAdsAnaliticsStore();
  const isRestrictedRoute = location.pathname.includes("supply-chain");
  const { selectedWidgets, dateRange } = useDashboardStore((state) => ({
    selectedWidgets: state.selectedWidgets,
    dateRange: state.dateRange,
  }));
  const { startDate, endDate } = dateRange;
  const { customSuplyWidgets } = useSuplyStore((state) => ({
    customSuplyWidgets: state.customSuplyWidgets,
  }));
  const { changeWhatIf: setCroWhatIf } = useSectionStore();
  const { isWhatIfEnabled } = useAdsAnaliticsStore((state) => ({
    isWhatIfEnabled: state.isWhatIfEnabled,
    setIsWhatIfEnabled: state.setIsWhatIfEnabled,
    compareWith: state.compareWith,
    setCompareWith: state.setCompareWith,
  }));

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();

      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const compareStartDate = startOfWeek(subWeeks(startDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDate = endOfWeek(subWeeks(startDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgets,
        startDateString,
        endDateString,
        compareStartDate,
        compareEndDate,
      );
      setIsLoading(false);
    };

    fetchWidgets();
  }, [selectedWidgets, dateRange]);

  const handleWhatIf = (whatIf: boolean) => {
    [setCroWhatIf, setAnaliticsWhatIf, setIsWhatIf].forEach((fn) => fn(whatIf));
  };

  return (
    <div>
      <header className="dashboard-widgets__header">
        <h1 className={styles.headerTitle}>{t("Supply chain (COO)")}</h1>
        <div className={styles.containerTab}>
          <Tab
            customSelectedWidgets={customSuplyWidgets}
            isRestrictedRoute={isRestrictedRoute}
            isConfigRoute={true}
          />
          <button
            className={styles.whatIf}
            onClick={() => handleWhatIf(!isWhatIfEnabled)}
          >
            {!isWhatIfEnabled ? "Switch to What IF" : "Save Changes"}
          </button>
        </div>
      </header>

      {isWhatIfEnabled ? (
        <>
          <div className={styles.supplyBlock}>
            <h1 className={styles.titleChain}>Supply Chain</h1>
            <SupplyBlocks />
          </div>
          <div className={styles.executiveBlock}>
            <ExecutiveSummary hideSwitch={true} customTitles={newTitles} />
            <div className={styles.perfomanceBlock}>
              <SalesPerformance />
            </div>
            <div className={styles.perfomanceBlock}>
              <InventoryPerformance />
            </div>
            <div>
              <Scheduler />
            </div>
            <div className={styles.financeBlock}>
              <FinanceFlowChart />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.executiveBlock}>
            <ExecutiveSummary hideSwitch={true} customTitles={newTitles} />
          </div>
          <div className={styles.perfomanceBlock}>
            <SalesPerformance />
          </div>
          <div className={styles.perfomanceBlock}>
            <InventoryPerformance />
          </div>
          <div>
            <Scheduler />
          </div>
          <div className={styles.financeBlock}>
            <FinanceFlowChart />
          </div>

          {/* <WidgetsList widgets={widgets} isLoading={isLoading} /> */}
        </>
      )}
    </div>
  );
}
