import { v4 as uuid } from "uuid";
import { getWidgetSize } from "@pages/CanvasEditor/Elements/utils";
import { ELEMENT_ID } from "./element-id";
import { CanvaElement } from "./canva.state";

export const defaultElements: CanvaElement[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.SUMMARY_AUDIT,
    x: 0,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.SUMMARY_AUDIT),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
    x: 0,
    y: 50,
    size: getWidgetSize(ELEMENT_ID.EXECUTIVE_SUMMARY),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SALES,
    x: 8,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.ADS_SALES),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ORGANIC_SALES,
    x: 8,
    y: 50,
    size: getWidgetSize(ELEMENT_ID.ORGANIC_SALES),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SANKEY,
    x: 16,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.ADS_SANKEY),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.FUNNEL,
    x: 16,
    y: 50,
    size: getWidgetSize(ELEMENT_ID.FUNNEL),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS,
    x: 24,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRODUCT_DETAILS,
    x: 26,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.PRODUCT_DETAILS),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.CONTENT_OPTIMIZATION,
    x: 31,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.CONTENT_OPTIMIZATION),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRICE_OPTIMIZATION,
    x: 31,
    y: 8,
    size: getWidgetSize(ELEMENT_ID.PRICE_OPTIMIZATION),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ASPECT_OPTIMIZATION,
    x: 31,
    y: 16,
    size: getWidgetSize(ELEMENT_ID.ASPECT_OPTIMIZATION),
    active: true,
  },
];
