export default () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4007 0.5V4.5C14.4007 4.63261 14.348 4.75979 14.2542 4.85355C14.1605 4.94732 14.0333 5 13.9007 5C13.7681 5 13.6409 4.94732 13.5471 4.85355C13.4533 4.75979 13.4007 4.63261 13.4007 4.5V1.70687L8.25442 6.85375C8.20798 6.90024 8.15284 6.93712 8.09214 6.96228C8.03144 6.98744 7.96638 7.00039 7.90067 7.00039C7.83496 7.00039 7.7699 6.98744 7.7092 6.96228C7.6485 6.93712 7.59335 6.90024 7.54692 6.85375L5.40067 4.70687L1.25442 8.85375C1.1606 8.94757 1.03335 9.00028 0.900669 9.00028C0.767987 9.00028 0.640739 8.94757 0.546919 8.85375C0.453098 8.75993 0.400391 8.63268 0.400391 8.5C0.400391 8.36732 0.453098 8.24007 0.546919 8.14625L5.04692 3.64625C5.09335 3.59976 5.1485 3.56288 5.2092 3.53772C5.2699 3.51256 5.33496 3.49961 5.40067 3.49961C5.46638 3.49961 5.53144 3.51256 5.59214 3.53772C5.65284 3.56288 5.70798 3.59976 5.75442 3.64625L7.90067 5.79313L12.6938 1H9.90067C9.76806 1 9.64088 0.947321 9.54712 0.853553C9.45335 0.759785 9.40067 0.632608 9.40067 0.5C9.40067 0.367392 9.45335 0.240215 9.54712 0.146447C9.64088 0.0526785 9.76806 0 9.90067 0H13.9007C14.0333 0 14.1605 0.0526785 14.2542 0.146447C14.348 0.240215 14.4007 0.367392 14.4007 0.5Z"
        fill="#80C67A"
      />
    </svg>
  );
};
