import React from "react";
import "./index.css";
import { Checks } from "../types";
import { mapCheckKeyToMetricKey } from "@pages/Dashboard/mocks/mockMetricsData";

interface LegendProps {
  handleOpenModal: () => void;
  metricsColors: { [key: string]: string };
  checks: Checks;
  allowedMetrics?: string[];
}

export const Legend: React.FC<LegendProps> = ({
  handleOpenModal,
  checks,
  metricsColors,
  allowedMetrics,
}) => {
  return (
    <div className="sales-performance__legend">
      <div className="sales-performance__legendGroup">
        {Object.keys(checks).map((check, index) => {
          if (!checks[check]) return null;

          const metricLabel = mapCheckKeyToMetricKey(check);
          if (allowedMetrics && !allowedMetrics.includes(metricLabel)) {
            return null;
          }

          return (
            <div key={index + check} className="sales-performance__legendItem">
              <div
                className="sales-performance__legendCircle"
                style={{ backgroundColor: metricsColors[check] }}
              />
              <div className="sales-performance__legendLabel body-large bold">
                {metricLabel}
              </div>
            </div>
          );
        })}
      </div>
      <button className="sales-performance__button" onClick={handleOpenModal}>
        Metrics
      </button>
    </div>
  );
};
